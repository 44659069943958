import { NumberValue } from 'd3';
import { IOption } from './types';

export function formatDate(dateString: string): string {
  const date = new Date(dateString);
  const month = zeroPadding(date.getMonth() + 1, 2);
  const day = zeroPadding(date.getDate(), 2);
  const hour = zeroPadding(date.getHours(), 2);
  const minute = zeroPadding(date.getMinutes(), 2);
  const formattedDate = `${month}/${day} ${hour}:${minute}`;
  return formattedDate;
}

export function formatDateLong(dateString: string): string {
  const date = new Date(dateString);
  const formattedDate = date.toLocaleString();
  return formattedDate;
}

export function zeroPadding(input: number, numDigits: number): string {
  const stringWithPadding = input.toLocaleString('en', { minimumIntegerDigits: numDigits, useGrouping: false });
  return stringWithPadding;
}

export function formatNumberShort(input: number): string {
  if (input < 0.0001) {
    const formattedNumber = input.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 6 });
    return formattedNumber;
  } else if (input < 0.01) {
    const formattedNumber = input.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 5 });
    return formattedNumber;
  } else if (input < 1000) {
    const formattedNumber = input.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 });
    return formattedNumber;
  }

  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return input >= item.value;
    });
  return item !== undefined ? (input / item.value).toFixed(1).replace(rx, '$1') + item.symbol : '0';
}

export function formatNumberLong(input: number, precision?: number): string {
  if (input === null) {
    return '0.0';
  }
  const formattedNumber = input.toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: precision ?? (input < 1000 ? 2 : 0),
  });
  return formattedNumber;
}

export function truncateString(str: string, n: number): string {
  return str.length > n + 2 ? str.slice(0, n) + '...' : str;
}

export function stringArrayFromOptionArray(options: IOption[]): string[] {
  return options.map((option: IOption) => option.name);
}

export function formatTickLabelCost(cost: NumberValue): string {
  return `$${formatNumberShort(cost as number)}`;
}

export function formatTickLabelCredit(cost: NumberValue): string {
  return formatNumberShort(cost as number);
}

export function formatTickLabeStorage(cost: NumberValue): string {
  return `${formatNumberShort(cost as number)}TB`;
}

export function formatTickLabeStorageGB(cost: NumberValue): string {
  return `${formatNumberShort(cost as number)}GB`;
}

export function formatTickLabelByte(input: NumberValue): string {
  const inGB = (input as number) / 1000000000;
  const precision = inGB > 100 ? 0 : inGB < 1 ? 3 : 2;
  return `${formatNumberLong(inGB, precision)}GB`;
}

// export function flattenTimeseriesArray(data: ITimeseries[]) {
//   const flattenedData = [];
//   data.forEach((d) => {
//     d.timeseries.forEach((t) => {
//       flattenedData.push({ category: d.category, date: t.date, value: t.value });
//     });
//   });
//   return flattenedData;
// }
