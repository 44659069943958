import React, { useMemo, useRef } from 'react';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import Card from 'components/Card';
import CardTitle from 'components/CardTitle';
import StorageCostBarChart from './StorageCostBarChart';
import DetailsButton from './DetailsButton';
import NoRecentActivity from 'components/NoRecentActivity';
import LessThanXDays from 'components/LessThanXDays';
import { useAppSelector } from 'app/hooks';
import { selectSelectedAccounts, selectSelectedWarehouses } from '../reducers/finobsSlice';
import { selectStartDate, selectEndDate, selectTimeframeOption } from 'app/sharedSlice';
import { useGetFailsafeSpendQuery } from 'app/spendviewApis';
import { getNumberOfBinsHeatmap, adjustStorageDates, getNumberOfDays } from 'utils/dateUtils';
import colors from 'utils/colors';

interface IFailsafeCostCardProps {
  style: string;
  isDetailsView: boolean;
}

const FailsafeCostCard = (props: IFailsafeCostCardProps): JSX.Element => {
  const selectedAccounts = useAppSelector(selectSelectedAccounts);
  const selectedWarehouses = useAppSelector(selectSelectedWarehouses);
  const startDate = useAppSelector(selectStartDate);
  const endDate = useAppSelector(selectEndDate);
  const numberOfDays = getNumberOfDays(startDate, endDate);
  const timeframeOption = useAppSelector(selectTimeframeOption);
  const windowWidth = useRef(window.innerWidth);
  const bins = useMemo(
    () =>
      timeframeOption === 'Last day' || timeframeOption === 'Custom'
        ? props.isDetailsView
          ? 8
          : 4
        : getNumberOfBinsHeatmap(timeframeOption, windowWidth.current, props.isDetailsView),
    [timeframeOption],
  );
  const { adjustedStartDate, adjustedEndDate } = useMemo(
    () => adjustStorageDates(startDate, endDate),
    [startDate, endDate],
  );

  const { data, isFetching, isError } = useGetFailsafeSpendQuery(
    {
      startDate: adjustedStartDate,
      endDate: adjustedEndDate,
      warehouses: selectedWarehouses,
      bins,
      forecast_bins: 2,
      aggregate_accounts: true,
      aggregate_by_accounts: selectedAccounts,
      in_dollars: false,
    },
    { skip: numberOfDays <= 1 },
  );

  return (
    <Card style={props.style} isLoading={isFetching} isError={isError}>
      <div className="flex">
        <CardTitle
          title="Failsafe used"
          subtitleText="Terabytes used on failsafe and the predicted changes for the selected accounts"
          tooltipText="Failsafe provides a period during which historical data may be recoverable"
        />
      </div>
      <div className="w-full h-[75%]">
        {numberOfDays > 1 && data !== undefined && data.length !== 0 && (
          <ParentSize>
            {(parent) => (
              <StorageCostBarChart
                data={data}
                color={colors.dataopsDarkBlue}
                width={parent.width}
                height={parent.height}
              />
            )}
          </ParentSize>
        )}
        {numberOfDays > 1 && data !== undefined && data.length === 0 && !isFetching && !isError && <NoRecentActivity />}
        {numberOfDays <= 1 && !isFetching && !isError && <LessThanXDays days={1} />}
      </div>
      <DetailsButton data={data ?? []} isTimeseriesArray={true} componentName="Failsafe cost" />
    </Card>
  );
};

export default FailsafeCostCard;
