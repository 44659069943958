import React, { useEffect } from 'react';
import UsageErrorCard from 'features/finobs/components/UsageErrorCard';
import UsagePerUserCard from 'features/finobs/components/UsagePerUserCard';
import UsagePerDatabaseCard from 'features/finobs/components/UsagePerDatabaseCard';
import QueriesByTypeChart from 'features/finobs/components/QueriesByTypeCard';
import WarehouseSpillingCard from 'features/finobs/components/WarehouseSpillingCard';
import WarehouseQueueingCard from 'features/finobs/components/WarehouseQueueingCard';
import { useAppDispatch } from 'app/hooks';
import { setShowError } from 'features/finobs/reducers/finobsSlice';

export default function FinObsUsageCostsTab(): JSX.Element {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setShowError(false));
  });

  return (
    <div className="w-full mt-[3.2rem] mx-auto py-6 px-dashboard-side bg-dashboard-background grid grid-cols-6 auto-rows-[7rem] xl:auto-rows-[6rem] gap-4">
      <UsagePerUserCard isDetailsView={false} style="col-span-6 2xl:col-span-3 row-span-3" />
      <UsagePerDatabaseCard isDetailsView={false} style="col-span-6 2xl:col-span-3 row-span-3" />
      <QueriesByTypeChart style="col-span-3 row-span-3" />
      <UsageErrorCard style="col-span-2 row-span-3" />
      <WarehouseSpillingCard isDetailsView={false} style="col-span-3 row-span-3" />
      <WarehouseQueueingCard isDetailsView={false} style="col-span-3 row-span-3" />
    </div>
  );
}
