import React from 'react';
import { Switch } from '@headlessui/react';
import { classNames } from 'utils/styleUtils';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { selectShowInactiveWarehouses, setShowInactiveWarehouses } from '../reducers/finobsSlice';

const ShowInactiveWarehousesSwitch = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const showInactiveWarehouses = useAppSelector(selectShowInactiveWarehouses);

  return (
    <Switch.Group as="div" className="flex items-center justify-center ml-2 mr-4">
      <Switch
        checked={showInactiveWarehouses}
        onChange={() => dispatch(setShowInactiveWarehouses(!showInactiveWarehouses))}
        className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none"
      >
        <span className="sr-only">Use setting</span>
        <span aria-hidden="true" className="pointer-events-none absolute h-full w-full rounded-md bg-white" />
        <span
          aria-hidden="true"
          className={classNames(
            showInactiveWarehouses ? 'bg-dataops-primary-blue' : 'bg-gray-200',
            'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out',
          )}
        />
        <span
          aria-hidden="true"
          className={classNames(
            showInactiveWarehouses ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out',
          )}
        />
      </Switch>
      <Switch.Label as="span" className="ml-1">
        <span className="text-sm font-medium text-gray-700">Show inactive WHs</span>
      </Switch.Label>
    </Switch.Group>
  );
};

export default ShowInactiveWarehousesSwitch;
