import React from 'react';
import { classNames } from 'utils/styleUtils';
import { useAppSelector } from 'app/hooks';
import { selectStartDate, selectEndDate, selectSelectedDataProducts } from '../reducers/dataProductsSlice';
import { selectSelectedProjects, selectSelectedEnvironments } from 'features/layouts/reducers/appBarSlice';
import { useGetProductionCostQuery } from 'app/dataProductsApis';
import { formatNumberLong, stringArrayFromOptionArray } from 'utils/formatUtils';
import Card from 'components/Card';

interface ICost {
  date: string;
  value: number;
}
export interface IProductionCost {
  totalCost: number;
  change: number;
  creditConsumption: number;
  averageDailyConsumption: number;
  averageDailyConsumptionChange: number;
  costOverTime: ICost[];
}

const ProductionCostCard = (props: { style: string }): JSX.Element => {
  const selectedProjects = useAppSelector(selectSelectedProjects);
  const selectedEnvironments = useAppSelector(selectSelectedEnvironments);
  const selectedDataProducts = useAppSelector(selectSelectedDataProducts);
  const startDate = useAppSelector(selectStartDate);
  const endDate = useAppSelector(selectEndDate);

  const { data } = useGetProductionCostQuery({
    startDate,
    endDate,
    projectId: selectedProjects,
    env: stringArrayFromOptionArray(selectedEnvironments),
    data_product: selectedDataProducts,
    bins: 7,
  });

  // useEffect(() => {
  //   console.log(startDate, ' ', endDate);
  //   axios
  //     .get('/restapi/products/overview/production_cost', {
  //       params: {
  //         startDate,
  //         endDate,
  //         bins: 7,
  //         projectId: selectedProjects,
  //         env: stringArrayFromOptionArray(selectedEnvironments),
  //         data_product: selectedDataProducts,
  //       },
  //       paramsSerializer: {
  //         indexes: null,
  //       },
  //     })
  //     .then((response) => {
  //       console.log(response);
  //       setData(response.data);
  //     })
  //     .catch((err) => err);
  // }, [selectedProjects, selectedEnvironments, selectedDataProducts, startDate, endDate]);

  return (
    <Card style={`h-46 ${props.style}`}>
      <>
        {data !== undefined && (
          <div className="space-y-2">
            <div className="w-full text-base text-primary-text">Production Cost</div>
            <div id="production-cost" className="w-full text-3xl text-primary-text">
              ${formatNumberLong(data.totalCost)}
            </div>
            <div className="w-full text-xs text-secondary-text">
              <span
                id="production-cost-change"
                className={classNames(data.change < 0 ? 'text-red-highlight' : 'text-green-highlight')}
              >
                {data.change < 0 ? 'down' : 'up'} {formatNumberLong(Math.abs(data.change)) ?? 0}%
              </span>{' '}
              in the last 7 days
            </div>
            <div className="w-full flex">
              <div className="flex-1">
                <div className="text-xs text-secondary-text">Credit consumption</div>
                <div id="credit-consumption-change" className="text-md font-medium text-primary-text">
                  {formatNumberLong(data.creditConsumption)}%
                </div>
              </div>
              <div className="flex-1">
                <div className="text-xs text-secondary-text">Avg daily</div>
                <div>
                  <div className="text-md font-medium text-primary-text">
                    {formatNumberLong(data.averageDailyConsumption)}
                    <span
                      id="avg-daily-change"
                      className={classNames(data.change < 0 ? 'text-red-highlight' : 'text-green-highlight', 'text-xs')}
                    >
                      {' '}
                      {formatNumberLong(data.averageDailyConsumptionChange) ?? 0}%
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    </Card>
  );
};

export default ProductionCostCard;
