import React from 'react';

const NoRecentActivity = (props: {}): JSX.Element => {
  return (
    <div className="w-full h-full bg-gray-100 flex items-center justify-center rounded-md">
      <div className="text-gray-500">No recent activity</div>
    </div>
  );
};

export default NoRecentActivity;
