import React from 'react';
import SimpleOverviewCard from 'components/SimpleOverviewCard';
import Card from 'components/Card';
import { useAppSelector } from 'app/hooks';
import { selectStartDate, selectEndDate } from 'app/sharedSlice';
import { useGetJobCountQuery } from 'app/pipelineMonitoringApis';
import { classNames } from 'utils/styleUtils';

export interface IJobCount {
  job_count: number;
  job_count_delta: number;
}

const JobsOverviewCard = (props: { style: string }): JSX.Element => {
  const startDate = useAppSelector(selectStartDate);
  const endDate = useAppSelector(selectEndDate);

  const { data, isFetching } = useGetJobCountQuery({
    startDate,
    endDate,
  });

  return (
    <Card style={classNames(props.style, 'p-0 rounded-md shadow-sm bg-dashboard-background flex space-x-1')}>
      <SimpleOverviewCard
        label="Jobs"
        value={data?.job_count}
        change={data?.job_count_delta}
        isFetching={isFetching}
        tooltipText="Number of jobs over the chosen period"
      />
    </Card>
  );
};

export default JobsOverviewCard;
