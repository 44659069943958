import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import {
  RectangleGroupIcon,
  Square3Stack3DIcon,
  ChartBarIcon,
  TableCellsIcon,
  Cog6ToothIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import { useTooltip, useTooltipInPortal, defaultStyles } from '@visx/tooltip';
import { classNames } from 'utils/styleUtils';
import { useAppSelector } from 'app/hooks';
import { selectIsAdmin } from 'features/authentication/reducers/userSlice';

interface TooltipData {
  label: string;
}

const tooltipStyles = {
  ...defaultStyles,
  backgroundColor: '#4b5563',
  color: 'white',
  fontSize: 12,
  zIndex: 100,
};

const Sidebar = (): JSX.Element => {
  const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } = useTooltip<TooltipData>();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });
  const isAdmin = useAppSelector(selectIsAdmin);

  const sidebarNavigation = useMemo(
    () => [
      {
        name: 'Spend observability',
        href: '/finobs',
        icon: Square3Stack3DIcon,
        hidden: false,
        disabled: false,
        isExternal: false,
      },
      {
        name: 'Data products',
        href: '/data-products',
        icon: RectangleGroupIcon,
        hidden: false,
        disabled: false,
        isExternal: false,
      },
      {
        name: 'Pipeline monitoring',
        href: '/pipelines',
        icon: ChartBarIcon,
        hidden: false,
        disabled: false,
        isExternal: false,
      },
      {
        name: 'Impact assessment',
        href: '/lineage',
        icon: TableCellsIcon,
        hidden: false,
        disabled: false,
        isExternal: false,
      },
      {
        name: 'Snowflake accounts',
        href: '/snowflake-accounts',
        icon: Cog6ToothIcon,
        hidden: false,
        disabled: false,
        isExternal: false,
      },
      {
        name: 'User Management',
        href: process.env.REACT_APP_ENV === 'prod' ? 'https://manage.dataops.live' : 'https://manage.qa.dataops.live',
        icon: UserIcon,
        hidden: !isAdmin as boolean,
        disabled: false,
        isExternal: true,
      },
    ],
    [isAdmin],
  );

  return (
    <nav
      aria-label="Sidebar"
      className="fixed z-30 h-full hidden md:block md:flex-shrink-0 md:overflow-y-auto border-r-[1px] border-solid bg-white"
    >
      <div className="relative mt-4 flex w-16 flex-col space-y-4 p-2" ref={containerRef}>
        {sidebarNavigation
          .filter((item) => !item.hidden)
          .map((item) => (
            <div
              key={item.name}
              onMouseLeave={() => {
                hideTooltip();
              }}
              onMouseMove={(event) => {
                showTooltip({
                  tooltipData: { label: item.name },
                  tooltipLeft: event.clientX + 15,
                  tooltipTop: event.clientY - 80,
                });
              }}
            >
              {item.disabled ? (
                <div className="text-gray-500 bg-gray-200 flex-shrink-0 inline-flex items-center justify-center h-10 w-10 rounded-lg">
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-5 w-5" aria-hidden="true" />
                </div>
              ) : item.isExternal ? (
                <a
                  href={item.href}
                  target="_blank"
                  rel="noreferrer"
                  className="text-gray-500 bg-gray-200 hover:bg-gray-300 flex-shrink-0 inline-flex items-center justify-center h-10 w-10 rounded-lg"
                >
                  <item.icon className="h-5 w-5" aria-hidden="true" />
                </a>
              ) : (
                <NavLink
                  to={item.href}
                  className={({ isActive }) =>
                    classNames(
                      isActive ? 'bg-dataops-primary-blue text-white' : 'text-gray-500 bg-gray-200 hover:bg-gray-300',
                      'flex-shrink-0 inline-flex items-center justify-center h-10 w-10 rounded-lg',
                    )
                  }
                >
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-5 w-5" aria-hidden="true" />
                </NavLink>
              )}
            </div>
          ))}
      </div>
      {tooltipOpen && tooltipData !== undefined && (
        <TooltipInPortal top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
          <div className="text-sm">{tooltipData.label}</div>
        </TooltipInPortal>
      )}
    </nav>
  );
};

export default Sidebar;
