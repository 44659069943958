import React from 'react';
import LoginForm from 'features/authentication/components/LoginForm';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';

export default function LoginView(): JSX.Element {
  return (
    <>
      <div className="w-full h-screen bg-white flex">
        <div className="h-full w-1/2 overflow-y-auto">
          <LoginForm />
        </div>
        <div className="h-full w-1/2">
          <div className="w-full h-full">
            <Carousel showArrows={false} autoPlay infiniteLoop className="h-screen m-0">
              <div className="h-screen">
                <iframe
                  className="h-screen"
                  style={{ width: '100%', margin: 0 }}
                  src="https://www.dataops.live/finobs-page-1"
                />
              </div>
              <div className="h-screen">
                <iframe
                  className="h-screen"
                  style={{ width: '100%', margin: 0 }}
                  src="https://www.dataops.live/finobs-page-2"
                />
              </div>
              <div className="h-screen">
                <iframe
                  className="h-screen"
                  style={{ width: '100%', margin: 0 }}
                  src="https://www.dataops.live/finobs-page-3"
                />
              </div>
            </Carousel>
          </div>
        </div>
      </div>
    </>
  );
}
