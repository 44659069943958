import React, { useMemo } from 'react';
import { AxisLeft, AxisTop } from '@visx/axis';
import { Group } from '@visx/group';
import { HeatmapRect } from '@visx/heatmap';
import { localPoint } from '@visx/event';
import { scaleBand, scaleLinear } from '@visx/scale';
import { useTooltip, useTooltipInPortal, defaultStyles } from '@visx/tooltip';
import { ITimeseries } from 'utils/types';
import { formatNumberShort, truncateString } from 'utils/formatUtils';
import { useAppSelector } from 'app/hooks';
import { selectStartDate, selectEndDate } from 'app/sharedSlice';
import { getTickLabelPrecisionHeatmap, formatTickLabelDate } from 'utils/dateUtils';
import useShowDollars from '../hooks/useShowDollar';
import colors from 'utils/colors';

const margin = {
  left: 130,
  right: 0,
  bottom: 0,
  top: 40,
};

interface UsagePerWarehouseHeatmapProps {
  data: ITimeseries[];
  width: number;
  height: number;
  isDetailsView: boolean;
}

interface IWarehouse {
  category: string;
  value: number;
}

interface IWarehouseTransformed {
  date: string;
  categories: IWarehouse[];
}

interface TooltipData {
  category: string;
  value?: number;
  date?: string;
}

const tooltipStyles = {
  ...defaultStyles,
  backgroundColor: '#27262c',
  color: 'white',
  fontSize: 12,
};

const UsagePerWarehouseHeatmap = (props: UsagePerWarehouseHeatmapProps): JSX.Element => {
  const showDollars = useShowDollars();
  const startDate = useAppSelector(selectStartDate);
  const endDate = useAppSelector(selectEndDate);
  const start = new Date(startDate);
  const end = new Date(endDate);
  const differenceInHours = (end.getTime() - start.getTime()) / (1000 * 60 * 60);
  const tickLabelPrecision = useMemo(() => getTickLabelPrecisionHeatmap(differenceInHours), [props.data]);
  const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } = useTooltip<TooltipData>();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });

  const height = props.isDetailsView ? Math.max(props.data.length * 40, props.height) : props.height;

  const data: IWarehouseTransformed[] = useMemo(() => {
    const tmpData: IWarehouseTransformed[] = [];
    props.data.forEach((d, dIdx) => {
      d.timeseries.forEach((t, tIdx) => {
        if (dIdx === 0) {
          tmpData.push({ date: t.date, categories: [] });
        }
        if (tmpData[tIdx] !== undefined) {
          tmpData[tIdx].categories.push({ category: d.category, value: t.value });
        }
      });
    });
    return tmpData;
  }, [props.data]);

  const colorMax = Math.max(...data.map((date) => Math.max(...date.categories.map((category) => category.value))));
  const bucketSizeMax = Math.max(...data.map((date) => date.categories.length));

  const xScale = scaleLinear<number>({
    domain: [0, data.length],
  });

  const yScale = scaleLinear<number>({
    domain: [0, bucketSizeMax],
  });

  const xScaleBand = scaleBand<string>({
    domain: data.map((d) => d.date),
  });

  const yScaleBand = scaleBand<string>({
    domain: data[0].categories.map((d) => d.category),
  });

  const rectColorScale = scaleLinear<string>({
    range: [colors.dataopsLightGreen, colors.dataopsLightGreen],
    domain: [0, colorMax],
  });

  const opacityScale = scaleLinear<number>({
    range: [0.1, 1],
    domain: [0, colorMax],
  });

  const xMax = props.width - margin.left - margin.right;
  const yMax = Math.min(height - margin.top - margin.bottom, bucketSizeMax * 50);

  const binWidth = xMax / data.length;
  const binHeight = yMax / bucketSizeMax;

  xScale.range([0, xMax]);
  yScale.range([yMax, 0]);
  xScaleBand.rangeRound([0, xMax]);
  yScaleBand.rangeRound([yMax, 0]);

  return (
    <svg ref={containerRef} width={props.width} height={height}>
      <Group top={margin.top} left={margin.left}>
        <HeatmapRect
          data={data}
          xScale={(d) => xScale(d) ?? 0}
          yScale={(d) => yScale(d + 1) ?? 0}
          colorScale={rectColorScale}
          opacityScale={opacityScale}
          binWidth={binWidth}
          binHeight={binHeight}
          gap={5}
          bins={(d: any) => d?.categories}
          count={(d: any) => d?.value}
        >
          {(heatmap) =>
            heatmap.map((heatmapBins) =>
              heatmapBins.map((bin) => (
                <rect
                  key={`heatmap-rect-${bin.row}-${bin.column}`}
                  className="visx-heatmap-rect"
                  width={bin.width}
                  height={bin.height}
                  x={bin.x}
                  y={bin.y}
                  rx={4}
                  fill={bin.color}
                  fillOpacity={bin.opacity}
                  onMouseLeave={() => {
                    hideTooltip();
                  }}
                  onMouseMove={(event) => {
                    const eventSvgCoords = localPoint(event);
                    showTooltip({
                      tooltipData: { ...bin.bin, date: bin.datum.date },
                      tooltipTop: eventSvgCoords?.y,
                      tooltipLeft: eventSvgCoords?.x,
                    });
                  }}
                />
              )),
            )
          }
        </HeatmapRect>
        <AxisLeft
          scale={yScaleBand}
          numTicks={500}
          tickLabelProps={() => ({
            fontSize: 11,
            textAnchor: 'end',
            dy: '0.33em',
          })}
          tickComponent={({ formattedValue, ...tickProps }) => (
            <text
              {...tickProps}
              style={{ cursor: 'default' }}
              onMouseLeave={() => {
                hideTooltip();
              }}
              onMouseMove={(event) => {
                const eventSvgCoords = localPoint(event);
                showTooltip({
                  tooltipData: {
                    category: formattedValue as string,
                  },
                  tooltipTop: eventSvgCoords?.y,
                  tooltipLeft: eventSvgCoords?.x,
                });
              }}
            >
              {truncateString(formattedValue as string, 15)}
            </text>
          )}
          hideAxisLine
          hideTicks
        />
        <AxisTop
          scale={xScaleBand}
          tickFormat={(d) => formatTickLabelDate(d, tickLabelPrecision)}
          tickLabelProps={() => ({
            fontSize: 11,
            textAnchor: 'middle',
          })}
          hideAxisLine
          hideTicks
        />
        {tooltipOpen && tooltipData !== undefined && (
          <TooltipInPortal top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
            <div>{truncateString(tooltipData.category ?? '', 25)}</div>
            {tooltipData.value !== undefined && (
              <div className="flex">
                <div className="pr-2">{showDollars ? 'Cost: ' : 'Credits: '}</div>
                <div>
                  {showDollars ? '$' : ''}
                  {formatNumberShort(tooltipData.value)}
                </div>
              </div>
            )}
            {tooltipData.date !== undefined && (
              <div className="flex">
                <div className="pr-2">Date: </div>
                <div>
                  {tooltipData !== undefined
                    ? formatTickLabelDate(
                        tooltipData.date ?? '',
                        tickLabelPrecision === 'Monthly' || tickLabelPrecision === 'Daily' ? 'Daily' : 'Hourly',
                      )
                    : ''}
                </div>
              </div>
            )}
          </TooltipInPortal>
        )}
      </Group>
    </svg>
  );
};

export default UsagePerWarehouseHeatmap;
