import React from 'react';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { localPoint } from '@visx/event';
import { BarGroup } from '@visx/shape';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { useTooltip, useTooltipInPortal, defaultStyles } from '@visx/tooltip';
import { LegendOrdinal, LegendItem, LegendLabel } from '@visx/legend';
import { IWarehouseSpilling } from './WarehouseSpillingCard';
import { formatTickLabeStorageGB, truncateString } from 'utils/formatUtils';
import colors from 'utils/colors';

const margin = {
  left: 65,
  right: 0,
  bottom: 50,
  top: 20,
};

const tooltipStyles = {
  ...defaultStyles,
  minWidth: 120,
  backgroundColor: '#27262c',
  color: 'white',
  fontSize: 12,
};

interface TooltipData {
  warehouse: string;
  key?: string;
  value?: number;
}

const legendGlyphSize = 12;

type IKey = 'remote_spilled' | 'local_spilled';
const keys: IKey[] = ['remote_spilled', 'local_spilled'];

interface WarehouseSpillingBarChartProps {
  data: IWarehouseSpilling[];
  width: number;
  height: number;
  isDetailsView: boolean;
}

const WarehouseSpillingBarChart = (props: WarehouseSpillingBarChartProps): JSX.Element => {
  const width = props.isDetailsView ? Math.max(props.data.length * 200, props.width) : props.width;
  const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } = useTooltip<TooltipData>();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });

  // scales
  const warehouseScale = scaleBand<string>({
    domain: props.data.map((d) => d.wh_name),
    padding: 0.4,
  });
  const typeScale = scaleBand<string>({
    domain: keys,
    padding: 0.1,
  });
  const byteScale = scaleLinear<number>({
    domain: [0, Math.max(...props.data.map((d) => Math.max(d.local_spilled, d.remote_spilled)))],
    nice: true,
  });
  const colorScale = scaleOrdinal<string, string>({
    domain: keys,
    range: [colors.dataopsRed, colors.dataopsOrange],
  });

  const xMax = width - margin.left - margin.right;
  const yMax = props.height - margin.top - margin.bottom;

  warehouseScale.rangeRound([0, xMax]);
  typeScale.rangeRound([0, warehouseScale.bandwidth()]);
  byteScale.range([yMax, 0]);

  const truncateLength = Math.floor(xMax / props.data.length / 12);

  return (
    <>
      <div className="text-sm">
        <LegendOrdinal
          scale={colorScale}
          labelFormat={(label) => `${label === 'remote_spilled' ? 'Remote spilled' : 'Local spilled'}`}
        >
          {(labels) => (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {labels.map((label, i) => (
                <LegendItem key={`legend-quantile-${i}`} margin="0 5px">
                  <svg width={legendGlyphSize} height={legendGlyphSize}>
                    <rect fill={label.value} width={legendGlyphSize} height={legendGlyphSize} />
                  </svg>
                  <LegendLabel align="left" margin="0 0 0 4px">
                    {label.text}
                  </LegendLabel>
                </LegendItem>
              ))}
            </div>
          )}
        </LegendOrdinal>
      </div>
      <svg ref={containerRef} width={width} height={props.height}>
        <Group top={margin.top} left={margin.left}>
          <BarGroup
            data={props.data}
            keys={keys}
            height={yMax}
            x0={(d) => d.wh_name}
            x0Scale={warehouseScale}
            x1Scale={typeScale}
            yScale={byteScale}
            color={colorScale}
          >
            {(barGroups) =>
              barGroups.map((barGroup) => (
                <Group key={`bar-group-${barGroup.index}-${barGroup.x0}`} left={barGroup.x0}>
                  {barGroup.bars.map((bar) => (
                    <rect
                      key={`bar-group-bar-${barGroup.index}-${bar.index}-${bar.value}-${bar.key}`}
                      x={bar.x}
                      y={bar.y}
                      width={bar.width}
                      height={bar.height}
                      fill={bar.color}
                      rx={2}
                      onMouseLeave={() => {
                        hideTooltip();
                      }}
                      onMouseMove={(event) => {
                        const eventSvgCoords = localPoint(event);
                        showTooltip({
                          tooltipData: {
                            warehouse: props.data[barGroup.index].wh_name,
                            key: bar.key,
                            value: bar.value,
                          },
                          tooltipTop: eventSvgCoords?.y,
                          tooltipLeft: eventSvgCoords?.x,
                        });
                      }}
                    />
                  ))}
                </Group>
              ))
            }
          </BarGroup>
          <AxisLeft
            scale={byteScale}
            stroke={colors.axisColor}
            numTicks={3}
            tickFormat={formatTickLabeStorageGB}
            tickStroke={colors.axisColor}
            tickLength={4}
            tickLabelProps={() => ({
              fill: colors.tickLabelColor,
              fontSize: 11,
              textAnchor: 'end',
              dy: '0.33em',
              dx: '-0.25em',
            })}
          />
          <AxisBottom
            top={yMax}
            scale={warehouseScale}
            stroke={colors.axisColor}
            numTicks={100}
            tickStroke={colors.axisColor}
            tickLabelProps={() => ({
              fill: colors.tickLabelColor,
              fontSize: 11,
              textAnchor: 'middle',
            })}
            tickComponent={({ formattedValue, ...tickProps }) => (
              <text
                {...tickProps}
                style={{ cursor: 'default' }}
                onMouseLeave={() => {
                  hideTooltip();
                }}
                onMouseMove={(event) => {
                  const eventSvgCoords = localPoint(event);
                  showTooltip({
                    tooltipData: {
                      warehouse: formattedValue as string,
                    },
                    tooltipTop: eventSvgCoords?.y,
                    tooltipLeft: eventSvgCoords?.x,
                  });
                }}
              >
                {truncateString(formattedValue as string, truncateLength)}
              </text>
            )}
            hideTicks
          />
          {tooltipOpen && tooltipData !== undefined && (
            <TooltipInPortal top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
              <div>{tooltipData.warehouse}</div>
              <div className="flex">
                {tooltipData.key !== undefined && (
                  <div className="pr-1">
                    {tooltipData.key === 'remote_spilled' ? 'Remote spilled:' : 'Local spilled:'}
                  </div>
                )}
                {tooltipData.value !== undefined && <div>{tooltipData.value}</div>}
              </div>
            </TooltipInPortal>
          )}
        </Group>
      </svg>
    </>
  );
};

export default WarehouseSpillingBarChart;
