import { useEffect, useState } from 'react';
import axios from 'axios';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { setHasSnowflakeCredentials, selectHasSnowflakeCredentials } from 'features/authentication/reducers/userSlice';
import { setShowError, setErrorMessage } from 'features/finobs/reducers/finobsSlice';
import { selectStartDate, selectEndDate } from 'app/sharedSlice';

export default function useSnowflakeCredentials(): { hasSnowflakeCredentials: boolean; loading: boolean } {
  const dispatch = useAppDispatch();
  const hasSnowflakeCredentials = useAppSelector(selectHasSnowflakeCredentials);
  const startDate = useAppSelector(selectStartDate);
  const endDate = useAppSelector(selectEndDate);
  const [loading, setLoading] = useState(true);

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        dispatch(setHasSnowflakeCredentials(false));
      }
      throw error;
    },
  );

  useEffect(() => {
    const checkSnowflakeAccounts = async (): Promise<void> => {
      if (!hasSnowflakeCredentials) {
        setLoading(true);
        axios
          .get('/restapi/cost_router/active_warehouses', {
            params: {
              startDate,
              endDate,
              aggregate_accounts: true,
            },
            paramsSerializer: {
              indexes: null,
            },
          })
          .then((response) => {
            dispatch(setHasSnowflakeCredentials(true));
          })
          .catch((err) => {
            if (err.response.data.detail !== 'Need to create snowflake connection first') {
              dispatch(setShowError(true));
              dispatch(setErrorMessage(err.response.data));
            }
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    };
    checkSnowflakeAccounts().catch((e) => e);
  }, []);

  return { hasSnowflakeCredentials, loading };
}
