import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../../../app/store';
import { IEnvironmentOption, IRequestStatus } from 'utils/types';

export interface DataProductsState {
  timeframeOption: string;
  startDate: string;
  endDate: string;
  dataProducts: string[];
  dataProductsStatus: IRequestStatus;
  selectedDataProducts: string[];
  selectedDataProductsStatus: IRequestStatus;
}

const initialStartDate = new Date('2022-08-30T00:00:00');
const initialEndDate = new Date('2022-08-30T00:00:00');
initialStartDate.setDate(initialEndDate.getDate() - 7);

const initialState: DataProductsState = {
  timeframeOption: 'Last 7 days',
  startDate: initialStartDate.toISOString(),
  endDate: initialEndDate.toISOString(),
  dataProducts: [],
  dataProductsStatus: 'idle',
  selectedDataProducts: [],
  selectedDataProductsStatus: 'idle',
};

export const fetchDataProducts = createAsyncThunk(
  'dataproducts',
  async (filter: { selectedProjects: string[]; selectedEnvironments: IEnvironmentOption[] }) => {
    const { selectedProjects, selectedEnvironments } = filter;
    const response = await axios.get('/restapi/products/all', {
      params: {
        projectId: selectedProjects,
        env: selectedEnvironments,
      },
      paramsSerializer: {
        indexes: null,
      },
    });
    console.log('data products:', response.data);
    return response.data;
  },
);

export const dataProductsSlice = createSlice({
  name: 'dataProducts',
  initialState,
  reducers: {
    setTimeframeOption: (state, action: PayloadAction<string>) => {
      const startDate = new Date('2022-08-30T00:00:00');
      const endDate = new Date('2022-08-30T00:00:00');
      switch (action.payload) {
        case 'Last day':
          startDate.setDate(startDate.getDate() - 1);
          break;
        case 'Last 7 days':
          startDate.setDate(startDate.getDate() - 7);
          break;
        case 'Last 30 days':
          startDate.setDate(startDate.getDate() - 30);
          break;
        default:
          console.log('Error');
      }
      state.timeframeOption = action.payload;
      state.startDate = startDate.toISOString();
      state.endDate = endDate.toISOString();
    },
    setSelectedDataProducts: (state, action: PayloadAction<string[]>) => {
      state.selectedDataProducts = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDataProducts.pending, (state) => {
        state.dataProductsStatus = 'loading';
      })
      .addCase(fetchDataProducts.fulfilled, (state, action) => {
        state.dataProductsStatus = 'idle';
        let processed = action.payload.filter(
          (value: { name: string }, index: number, self: any) =>
            index === self.findIndex((t: { name: string }) => t.name === value.name),
        );
        processed = processed.map((d: { name: string }) => {
          return d.name;
        });
        state.dataProducts = processed;
      })
      .addCase(fetchDataProducts.rejected, (state) => {
        state.dataProductsStatus = 'failed';
      });
  },
});

export const { setTimeframeOption, setSelectedDataProducts } = dataProductsSlice.actions;

export const selectTimeframeOption = (state: RootState): string => state.dataProducts.timeframeOption;
export const selectStartDate = (state: RootState): string => state.dataProducts.startDate;
export const selectEndDate = (state: RootState): string => state.dataProducts.endDate;
export const selectDataProducts = (state: RootState): string[] => state.dataProducts.dataProducts;
export const selectSelectedDataProducts = (state: RootState): string[] => state.dataProducts.selectedDataProducts;

export default dataProductsSlice.reducer;
