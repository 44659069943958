import React, { useMemo } from 'react';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { Bar } from '@visx/shape';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear } from '@visx/scale';
import { useTooltip, useTooltipInPortal, defaultStyles } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import { ITimeseries, ITimeseriesElement } from 'utils/types';
import { formatTickLabeStorage, formatNumberLong } from 'utils/formatUtils';
import { useAppSelector } from 'app/hooks';
import { selectStartDate, selectEndDate } from 'app/sharedSlice';
import { getTickLabelPrecisionHeatmap, formatTickLabelDate } from 'utils/dateUtils';
import colors from 'utils/colors';

const margin = {
  left: 70,
  right: 0,
  bottom: 25,
  top: 20,
};

interface UsagePerUserBarChartProps {
  data: ITimeseries[];
  color: string;
  width: number;
  height: number;
}

interface TooltipData {
  date: string;
  value: number;
}

const tooltipStyles = {
  ...defaultStyles,
  backgroundColor: '#27262c',
  color: 'white',
  fontSize: 12,
};

const StorageCostBarChart = (props: UsagePerUserBarChartProps): JSX.Element => {
  const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } = useTooltip<TooltipData>();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });

  const startDate = useAppSelector(selectStartDate);
  const endDate = useAppSelector(selectEndDate);
  const start = new Date(startDate);
  const end = new Date(endDate);
  const differenceInHours = (end.getTime() - start.getTime()) / (1000 * 60 * 60);
  const tickLabelPrecision = useMemo(() => getTickLabelPrecisionHeatmap(differenceInHours), [props.data]);

  const xMax = props.width - margin.left - margin.right;
  const yMax = props.height - margin.top - margin.bottom;

  const usageData = useMemo(() => {
    let processedData = props.data[0].category === 'usage' ? props.data[0] : props.data[1];
    processedData = {
      category: 'usage',
      timeseries: processedData.timeseries.map((d) => ({
        date: d.date,
        value: d.value >= 0.001 ? d.value : 0,
      })),
    };
    return processedData;
  }, [props.data]);
  const forecastData = useMemo(() => {
    let processedData = props.data[0].category === 'forecast' ? props.data[0] : props.data[1];
    processedData = {
      category: 'forecast',
      timeseries: processedData.timeseries.map((d) => ({
        date: d.date,
        value: d.value >= 0.001 ? d.value : 0,
      })),
    };
    return processedData;
  }, [props.data]);

  const xScale = useMemo(
    () =>
      scaleBand<string>({
        range: [0, xMax],
        round: true,
        domain: [...usageData.timeseries.concat(forecastData.timeseries).map((d) => d.date)],
        padding: 0.4,
      }),
    [xMax, usageData, forecastData],
  );
  const yScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [yMax, 0],
        round: true,
        domain: [
          0,
          Math.max(...usageData.timeseries.concat(forecastData.timeseries).map((d: ITimeseriesElement) => d.value)),
        ],
      }),
    [yMax, usageData, forecastData],
  );

  return (
    <svg ref={containerRef} width={props.width} height={props.height}>
      <Group top={margin.top} left={margin.left}>
        {usageData.timeseries.map((d) => {
          const barWidth = xScale.bandwidth();
          const barHeight = yMax - yScale(d.value) ?? 0;
          const barX = xScale(d.date);
          const barY = yMax - barHeight;
          return (
            <Bar
              key={`bar-${d.date}`}
              x={barX}
              y={barY}
              width={barWidth}
              height={barHeight}
              fill={props.color}
              opacity={0.75}
              rx={3}
              onMouseLeave={() => {
                hideTooltip();
              }}
              onMouseMove={(event) => {
                const eventSvgCoords = localPoint(event);
                showTooltip({
                  tooltipData: d,
                  tooltipTop: eventSvgCoords?.y,
                  tooltipLeft: eventSvgCoords?.x,
                });
              }}
            />
          );
        })}
        {forecastData.timeseries.map((d) => {
          const barWidth = xScale.bandwidth();
          const barHeight = yMax - yScale(d.value) ?? 0;
          const barX = xScale(d.date);
          const barY = yMax - barHeight;
          return (
            <Bar
              key={`bar-${d.date}`}
              x={barX}
              y={barY}
              width={barWidth}
              height={barHeight}
              fill={colors.dataopsDarkGreen}
              opacity={0.75}
              rx={3}
              onMouseLeave={() => {
                hideTooltip();
              }}
              onMouseMove={(event) => {
                const eventSvgCoords = localPoint(event);
                showTooltip({
                  tooltipData: d,
                  tooltipTop: eventSvgCoords?.y,
                  tooltipLeft: eventSvgCoords?.x,
                });
              }}
            />
          );
        })}
        <AxisLeft
          scale={yScale}
          stroke={colors.axisColor}
          tickStroke={colors.axisColor}
          numTicks={3}
          tickFormat={formatTickLabeStorage}
          tickLabelProps={() => ({
            fill: colors.tickLabelColor,
            fontSize: 11,
            textAnchor: 'end',
            dy: '0.33em',
            dx: '-0.25em',
          })}
        />
        <AxisBottom
          top={yMax}
          scale={xScale}
          stroke={colors.axisColor}
          tickStroke={colors.axisColor}
          tickFormat={(d) => formatTickLabelDate(d, tickLabelPrecision)}
          tickLabelProps={() => ({
            fill: colors.tickLabelColor,
            fontSize: 11,
            textAnchor: 'middle',
          })}
          hideTicks
        />
        {tooltipOpen && tooltipData !== undefined && (
          <TooltipInPortal top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
            <div className="flex">
              <div className="pr-2">Date: </div>
              <div>
                {tooltipData !== undefined
                  ? formatTickLabelDate(
                      tooltipData.date ?? '',
                      tickLabelPrecision === 'Monthly' || tickLabelPrecision === 'Daily' ? 'Daily' : 'Hourly',
                    )
                  : ''}
              </div>
            </div>
            <div className="flex">
              <div className="pr-2">Value: </div>
              <div>{formatNumberLong(tooltipData.value, 6)}TB</div>
            </div>
          </TooltipInPortal>
        )}
      </Group>
    </svg>
  );
};

export default StorageCostBarChart;
