import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../../../app/store';
import { IEnvironmentOption, IRequestStatus } from 'utils/types';

export interface AppBarState {
  projects: string[];
  projectsStatus: IRequestStatus;
  selectedProjects: string[];
  environments: IEnvironmentOption[];
  environmentsStatus: IRequestStatus;
  selectedEnvironments: IEnvironmentOption[];
  openContactUsDialog: boolean;
}

const initialState: AppBarState = {
  projects: [],
  projectsStatus: 'idle',
  selectedProjects: [],
  environments: [],
  environmentsStatus: 'idle',
  selectedEnvironments: [],
  openContactUsDialog: false,
};

export const fetchProjects = createAsyncThunk('projects', async () => {
  const response = await axios.get('/restapi/top_bar/get_projects');

  return response.data;
});

export const fetchEnvironments = createAsyncThunk('environments', async (selectedProjects: string[]) => {
  const response = await axios.get('/restapi/top_bar/get_environments', {
    params: {
      projectIds: selectedProjects,
    },
    paramsSerializer: {
      indexes: null,
    },
  });

  console.log('Environments: ', response);
  return response.data;
});

export const appBarSlice = createSlice({
  name: 'appBar',
  initialState,
  reducers: {
    setSelectedProjects: (state, action: PayloadAction<string[]>) => {
      state.selectedProjects = action.payload;
    },
    setSelectedEnvironments: (state, action: PayloadAction<IEnvironmentOption[]>) => {
      state.selectedEnvironments = action.payload;
    },
    setOpenContactUsDialog: (state, action: PayloadAction<boolean>) => {
      state.openContactUsDialog = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjects.pending, (state) => {
        state.projectsStatus = 'loading';
      })
      .addCase(fetchProjects.fulfilled, (state, action) => {
        state.projectsStatus = 'idle';
        let processed = action.payload.filter((d: { name: string }) => d.name !== null);
        processed = processed.filter(
          (value: { name: string }, index: number, self: any) =>
            index === self.findIndex((t: { name: string }) => t.name === value.name),
        );
        processed = processed.map((d: { name: string }, i: number) => {
          return { id: i, name: d.name };
        });
        state.projects = processed;
      })
      .addCase(fetchProjects.rejected, (state) => {
        state.projectsStatus = 'failed';
      })
      .addCase(fetchEnvironments.pending, (state) => {
        state.environmentsStatus = 'loading';
      })
      .addCase(fetchEnvironments.fulfilled, (state, action) => {
        state.environmentsStatus = 'idle';
        let processed = action.payload.filter((d: { name: string }) => d.name !== null);
        processed = processed.filter(
          (value: { name: string; project: string }, index: number, self: any) =>
            index ===
            self.findIndex(
              (t: { name: string; project: string }) => t.name === value.name && t.project === value.project,
            ),
        );
        processed = processed.map((d: { name: string; project: string }, i: number) => {
          return { id: i, name: d.name, project: d.project };
        });
        state.environments = processed;
      })
      .addCase(fetchEnvironments.rejected, (state) => {
        state.environmentsStatus = 'failed';
      });
  },
});

export const { setSelectedProjects, setSelectedEnvironments, setOpenContactUsDialog } = appBarSlice.actions;

export const selectProjects = (state: RootState): string[] => state.appBar.projects;
export const selectSelectedProjects = (state: RootState): string[] => state.appBar.selectedProjects;
export const selectEnvironments = (state: RootState): IEnvironmentOption[] => state.appBar.environments;
export const selectSelectedEnvironments = (state: RootState): IEnvironmentOption[] => state.appBar.selectedEnvironments;
export const selectOpenContactUsDialog = (state: RootState): boolean => state.appBar.openContactUsDialog;

export default appBarSlice.reducer;
