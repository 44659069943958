import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import LoadingView from 'views/LoadingView';

interface PublicRouteProps {
  children: JSX.Element;
}

export default function PublicRoute(props: PublicRouteProps): JSX.Element {
  const { isLoading, isAuthenticated } = useAuth0();

  if (isLoading) {
    return <LoadingView />;
  }

  if (isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  return props.children;
}
