import React, { useMemo } from 'react';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { localPoint } from '@visx/event';
import { BarStack } from '@visx/shape';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { useTooltip, useTooltipInPortal, defaultStyles } from '@visx/tooltip';
import { LegendOrdinal, LegendItem, LegendLabel } from '@visx/legend';
import { IStorageByTypeByDB } from './StorageByTypeByDBCard';
import { formatTickLabelByte, truncateString } from 'utils/formatUtils';
import colors from 'utils/colors';

const margin = {
  left: 45,
  right: 0,
  bottom: 50,
  top: 20,
};

const tooltipStyles = {
  ...defaultStyles,
  minWidth: 120,
  backgroundColor: '#27262c',
  color: 'white',
  fontSize: 12,
};

interface TooltipData {
  database: string;
  active?: number;
  clone?: number;
  time_travel?: number;
  failsafe?: number;
}

const legendGlyphSize = 12;

type IKey = 'active' | 'time_travel' | 'failsafe' | 'clone';
const keys: IKey[] = ['active', 'clone', 'time_travel', 'failsafe'];

interface StorageByTypeByDBBarChartProps {
  data: IStorageByTypeByDB[];
  width: number;
  height: number;
  isDetailsView: boolean;
}

const StorageByTypeByDBBarChart = (props: StorageByTypeByDBBarChartProps): JSX.Element => {
  const width = props.isDetailsView ? Math.max(props.data.length * 180, props.width) : props.width;
  const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } = useTooltip<TooltipData>();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });

  // scales
  const databaseScale = scaleBand<string>({
    domain: props.data.map((d) => d.db_name),
    padding: 0.3,
  });
  const byteScale = useMemo(
    () =>
      scaleLinear<number>({
        domain: [0, Math.max(...props.data.map((d) => d.active + d.time_travel + d.failsafe + d.clone))],
        nice: true,
      }),
    [props.data],
  );
  const colorScale = scaleOrdinal<string, string>({
    domain: keys,
    range: ['#00436c', '#006995', '#16a7d7', '#5ed3ff'],
  });

  const xMax = useMemo(() => width - margin.left - margin.right, [props]);
  const yMax = useMemo(() => props.height - margin.top - margin.bottom, [props]);

  databaseScale.rangeRound([0, xMax]);
  byteScale.range([yMax, 0]);

  const truncateLength = Math.floor(xMax / props.data.length / 12);

  return (
    <>
      <div className="text-sm">
        <LegendOrdinal scale={colorScale}>
          {(labels) => (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {labels.map((label, i) => (
                <LegendItem key={`legend-quantile-${i}`} margin="0 5px">
                  <svg width={legendGlyphSize} height={legendGlyphSize}>
                    <rect fill={label.value} width={legendGlyphSize} height={legendGlyphSize} />
                  </svg>
                  <LegendLabel align="left" margin="0 0 0 4px">
                    {label.text}
                  </LegendLabel>
                </LegendItem>
              ))}
            </div>
          )}
        </LegendOrdinal>
      </div>
      <svg ref={containerRef} width={width} height={props.height}>
        <Group top={margin.top} left={margin.left}>
          <BarStack
            data={props.data}
            keys={keys}
            x={(d) => d.db_name}
            xScale={databaseScale}
            yScale={byteScale}
            color={colorScale}
          >
            {(barStacks) =>
              barStacks.map((barStack) =>
                barStack.bars.map((bar) => (
                  <rect
                    key={`bar-stack-${barStack.index}-${bar.index}-${bar.key}`}
                    x={bar.x}
                    y={bar.y}
                    height={bar.height}
                    width={bar.width}
                    fill={bar.color}
                    rx={2}
                    onMouseLeave={() => {
                      hideTooltip();
                    }}
                    onMouseMove={(event) => {
                      const eventSvgCoords = localPoint(event);
                      showTooltip({
                        tooltipData: {
                          database: bar.bar.data.db_name,
                          ...bar.bar.data,
                        },
                        tooltipTop: eventSvgCoords?.y,
                        tooltipLeft: eventSvgCoords?.x,
                      });
                    }}
                  />
                )),
              )
            }
          </BarStack>
          <AxisLeft
            scale={byteScale}
            stroke={colors.axisColor}
            numTicks={3}
            tickFormat={formatTickLabelByte}
            tickStroke={colors.axisColor}
            tickLength={4}
            tickLabelProps={() => ({
              fill: colors.tickLabelColor,
              fontSize: 11,
              textAnchor: 'end',
              dy: '0.33em',
              dx: '-0.25em',
            })}
          />
          <AxisBottom
            top={yMax}
            scale={databaseScale}
            stroke={colors.axisColor}
            numTicks={500}
            tickStroke={colors.axisColor}
            tickLabelProps={() => ({
              fill: colors.tickLabelColor,
              fontSize: 11,
              textAnchor: 'middle',
            })}
            tickComponent={({ formattedValue, ...tickProps }) => (
              <text
                {...tickProps}
                style={{ cursor: 'default' }}
                onMouseLeave={() => {
                  hideTooltip();
                }}
                onMouseMove={(event) => {
                  const eventSvgCoords = localPoint(event);
                  showTooltip({
                    tooltipData: {
                      database: formattedValue as string,
                    },
                    tooltipTop: eventSvgCoords?.y,
                    tooltipLeft: eventSvgCoords?.x,
                  });
                }}
              >
                {truncateString(formattedValue as string, truncateLength)}
              </text>
            )}
            hideTicks
          />
          {tooltipOpen && tooltipData !== undefined && (
            <TooltipInPortal top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
              <div className="flex">
                <div className="pr-2">Database: </div>
                <div>{tooltipData.database}</div>
              </div>
              {tooltipData.active !== undefined && (
                <div className="flex">
                  <div className="pr-2">Active: </div>
                  <div>{formatTickLabelByte(tooltipData.active)}</div>
                </div>
              )}
              {tooltipData.clone !== undefined && (
                <div className="flex">
                  <div className="pr-2">Clone: </div>
                  <div>{formatTickLabelByte(tooltipData.clone)}</div>
                </div>
              )}
              {tooltipData.time_travel !== undefined && (
                <div className="flex">
                  <div className="pr-2">Time travel: </div>
                  <div>{formatTickLabelByte(tooltipData.time_travel)}</div>
                </div>
              )}
              {tooltipData.failsafe !== undefined && (
                <div className="flex">
                  <div className="pr-2">Failsafe: </div>
                  <div>{formatTickLabelByte(tooltipData.failsafe)}</div>
                </div>
              )}
            </TooltipInPortal>
          )}
        </Group>
      </svg>
    </>
  );
};

export default StorageByTypeByDBBarChart;
