import React, { useEffect, useState } from 'react';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import Card from 'components/Card';
import CardTitle from 'components/CardTitle';
import UsagePerUserDonutChart from './UsagePerUserDonutChart';
import UsagePerUserDonutChartLegend from './UsagePerUserDonutChartLegend';
import UsagePerUserBarChart from './UsagePerUserBarChart';
import DetailsButton from './DetailsButton';
import NoRecentActivity from 'components/NoRecentActivity';
import { useAppSelector } from 'app/hooks';
import { selectSelectedAccounts, selectSelectedWarehouses, selectShowDollars } from '../reducers/finobsSlice';
import { selectStartDate, selectEndDate } from 'app/sharedSlice';
import { useGetCostPerUserQuery } from 'app/spendviewApis';
import { ITimeseries, ITimeseriesElement } from 'utils/types';

// const sampleData = [
//   {
//     category: 'MATECSIGI',
//     timeseries: [
//       {
//         date: '2023-01-06T10:56:51.050000',
//         value: 50,
//       },
//     ],
//     percent: 50,
//   },
//   {
//     category: 'USER1',
//     timeseries: [
//       {
//         date: '2023-01-06T10:56:51.050000',
//         value: 30,
//       },
//     ],
//     percent: 30,
//   },
//   {
//     category: 'USER3',
//     timeseries: [
//       {
//         date: '2023-01-06T10:56:51.050000',
//         value: 10,
//       },
//     ],
//     percent: 10,
//   },
//   {
//     category: 'USER4',
//     timeseries: [
//       {
//         date: '2023-01-06T10:56:51.050000',
//         value: 5,
//       },
//     ],
//     percent: 5,
//   },
//   {
//     category: 'USER5',
//     timeseries: [
//       {
//         date: '2023-01-06T10:56:51.050000',
//         value: 5,
//       },
//     ],
//     percent: 5,
//   },
// ];

interface IUsagePerUserCardProps {
  style: string;
  isDetailsView: boolean;
}

export interface ICostPerUser {
  category: string;
  timeseries: ITimeseriesElement[];
  percent: number;
}

const UsagePerUserCard = (props: IUsagePerUserCardProps): JSX.Element => {
  const [processedData, setProcessedData] = useState<ICostPerUser[]>([]);
  const selectedAccounts = useAppSelector(selectSelectedAccounts);
  const selectedWarehouses = useAppSelector(selectSelectedWarehouses);
  const startDate = useAppSelector(selectStartDate);
  const endDate = useAppSelector(selectEndDate);
  const showDollars = useAppSelector(selectShowDollars);

  const { data, isFetching, isError } = useGetCostPerUserQuery({
    startDate,
    endDate,
    warehouses: selectedWarehouses,
    aggregate_accounts: true,
    aggregate_by_accounts: selectedAccounts,
    in_dollars: showDollars,
  });

  useEffect(() => {
    if (data !== undefined) {
      const tmpProcessedData: ICostPerUser[] = [];
      const totalCost = data.items.reduce(
        (partialSum: number, a: ITimeseries) => partialSum + (a.timeseries[0].value as number),
        0,
      );
      data.items.forEach((d: ITimeseries) => {
        tmpProcessedData.push({ ...d, percent: (d.timeseries[0].value / totalCost) * 100 });
      });
      tmpProcessedData.sort(
        (a: ITimeseries, b: ITimeseries) =>
          Math.max(...b.timeseries.map((t: ITimeseriesElement) => t.value)) -
          Math.max(...a.timeseries.map((t: ITimeseriesElement) => t.value)),
      );
      setProcessedData(tmpProcessedData.slice(0, props.isDetailsView ? 8 : 5));
    }
  }, [data]);

  return (
    <Card style={props.style} isLoading={isFetching} isError={isError}>
      <div className="flex">
        <CardTitle
          title="Top 5 active users"
          subtitleText="Top 5 active users as per their query cost"
          tooltipText="On bottom right, click Show details to see all users"
        />
      </div>
      <div className="w-full h-[80%] flex">
        {processedData.length !== 0 && (
          <>
            <div className="h-full w-[30%]">
              <ParentSize>
                {(parent) => (
                  <UsagePerUserDonutChart
                    data={processedData}
                    tooltipLabel="User"
                    width={parent.width}
                    height={parent.height}
                  />
                )}
              </ParentSize>
            </div>
            <div className="h-full w-[20%]">
              <UsagePerUserDonutChartLegend data={processedData} />
            </div>
            <div className="h-full w-[50%]">
              <ParentSize>
                {(parent) => (
                  <UsagePerUserBarChart
                    data={processedData}
                    tooltipLabel="User"
                    width={parent.width}
                    height={parent.height}
                  />
                )}
              </ParentSize>
            </div>
          </>
        )}
        {processedData.length === 0 && !isFetching && !isError && <NoRecentActivity />}
      </div>
      <DetailsButton data={processedData} isTimeseriesArray={true} componentName="Usage per user" />
    </Card>
  );
};

export default UsagePerUserCard;
