import React, { useEffect, useState } from 'react';
import Dropdown from 'components/Dropdown';
import DropdownMultiselectAutocomplete from 'components/DropdownMultiselectAutocomplete';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  fetchDataProducts,
  selectDataProducts,
  selectSelectedDataProducts,
  setSelectedDataProducts,
  setTimeframeOption,
} from '../reducers/dataProductsSlice';
import { selectSelectedProjects, selectSelectedEnvironments } from 'features/layouts/reducers/appBarSlice';
import { classNames } from 'utils/styleUtils';

const tabs = [
  { name: 'Overview', href: '#', current: true },
  { name: 'Contracts', href: '#', current: false },
  { name: 'Warehouses', href: '#', current: false },
];

const timeframes = ['Last day', 'Last 7 days', 'Last 30 days'];

const DataProductsBar = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const dataProducts = useAppSelector(selectDataProducts);
  const selectedDataProducts = useAppSelector(selectSelectedDataProducts);
  const selectedProjects = useAppSelector(selectSelectedProjects);
  const selectedEnvironments = useAppSelector(selectSelectedEnvironments);
  const [timeframe, setTimeframe] = useState(timeframes[1]);

  useEffect(() => {
    dispatch(fetchDataProducts({ selectedProjects, selectedEnvironments })).catch((err) => err);
  }, [selectedProjects, selectedEnvironments]);

  const handleChange = (target: string): void => {
    dispatch(setTimeframeOption(target));
    setTimeframe(target);
  };

  return (
    <div className="fixed left-16 right-0 z-20 pl-8 pr-8 bg-white">
      <div className="border-b border-gray-200 flex items-center">
        <nav className="-mb-px flex space-x-8 flex-grow" aria-label="Tabs">
          {tabs.map((tab) => (
            <a
              key={tab.name}
              href={tab.href}
              className={classNames(
                tab.current
                  ? 'border-dataops-secondary-blue text-dataops-secondary-blue'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                'whitespace-nowrap py-4 px-1 border-b-2 font-[600] text-sm',
              )}
              aria-current={tab.current ? 'page' : undefined}
            >
              {tab.name}
            </a>
          ))}
        </nav>
        <DropdownMultiselectAutocomplete
          options={dataProducts}
          value={selectedDataProducts}
          onChange={(e) => dispatch(setSelectedDataProducts(e))}
          label="Data Products"
        />
        <Dropdown options={timeframes} value={timeframe} onChange={handleChange} />
      </div>
    </div>
  );
};

export default DataProductsBar;
