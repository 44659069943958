import React from 'react';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { GridColumns, GridRows } from '@visx/grid';
import { Group } from '@visx/group';
import { scaleLinear } from '@visx/scale';
import { ICostOverBusinessValue } from './BusinessValueCard';
import { NumberValue } from 'd3';
import { range } from 'lodash';
import { formatNumberShort } from 'utils/formatUtils';

interface BusinessValueTableProps {
  costLimit: number;
  valueLimit: number;
  data: ICostOverBusinessValue[];
  width: number;
  height: number;
}

const maxRadius = 50;

const margin = {
  left: 40,
  right: maxRadius,
  bottom: 30,
  top: maxRadius,
};

const formatValue = (value: NumberValue): string => `${Math.round(value as number)}`;

const BusinessValueTable = (props: BusinessValueTableProps): JSX.Element => {
  const stats = {
    LowLow: { count: 0, totalCost: 0 },
    LowHigh: { count: 0, totalCost: 0 },
    HighLow: { count: 0, totalCost: 0 },
    HighHigh: { count: 0, totalCost: 0 },
  };

  props.data.forEach((d) => {
    stats[d.valueCostCategory].count++;
    stats[d.valueCostCategory].totalCost += d.cost;
  });

  const xMax = props.width - margin.left - margin.right;
  const yMax = props.height - margin.top - margin.bottom;

  const costScale = scaleLinear<number>({
    domain: [0, Math.max(...props.data.map((d) => d.cost))],
    range: [0, xMax],
    round: true,
    nice: true,
  });

  const maxValue = Math.max(...props.data.map((d) => d.value));
  const maxTickValue = Math.round(maxValue) + 1;
  const tickValues = range(0, maxTickValue, 1);

  const valueScale = scaleLinear<number>({
    domain: [0, maxTickValue],
    range: [yMax, 0],
    round: true,
    nice: true,
  });

  return (
    <svg width={props.width} height={props.height}>
      <Group top={margin.top} left={margin.left}>
        <AxisLeft
          scale={valueScale}
          stroke="#87858d"
          tickStroke="#87858d"
          label="Business Value"
          labelOffset={15}
          labelProps={{
            fontSize: 14,
            fill: '#87858d',
            strokeWidth: 0,
            stroke: '#fff',
            paintOrder: 'stroke',
            fontFamily: 'sans-serif',
            textAnchor: 'middle',
          }}
          tickLabelProps={() => ({
            fontSize: 11,
            textAnchor: 'end',
            dy: '0.33em',
          })}
          tickValues={tickValues}
          tickFormat={formatValue}
          hideTicks
        />
        <AxisBottom
          top={yMax}
          scale={costScale}
          stroke="#87858d"
          tickStroke="#87858d"
          tickLabelProps={() => ({
            fontSize: 11,
            textAnchor: 'middle',
          })}
          numTicks={7}
          tickFormat={(cost: NumberValue) => `${formatNumberShort(cost as number)}$`}
          hideZero
          hideTicks
        />
        <GridRows
          scale={valueScale}
          tickValues={[props.valueLimit]}
          width={xMax}
          stroke="#87858d"
          strokeOpacity={0.4}
          strokeDasharray="4,4"
        />
        <GridColumns
          scale={costScale}
          tickValues={[props.costLimit]}
          height={yMax}
          stroke="#87858d"
          strokeOpacity={0.4}
          strokeDasharray="4,4"
        />
        <foreignObject x={0} y={0} width={xMax} height={yMax}>
          <div className="w-full h-full grid grid-rows-2 grid-cols-2">
            <div className="flex flex-col items-center justify-center">
              <div className="text-green-highlight text-base">High / Low</div>
              <div className="text-primary-text text-xl">${stats.HighLow.totalCost.toLocaleString()} </div>
              <div className="text-secondary-text text-xs">
                {stats.HighLow.count} data {stats.HighLow.count === 1 ? 'product' : 'products'}
              </div>
            </div>
            <div className="flex flex-col items-center justify-center">
              <div className="text-secondary-text text-base">High / High</div>
              <div className="text-primary-text text-xl">${stats.HighHigh.totalCost.toLocaleString()}</div>
              <div className="text-secondary-text text-xs">
                {stats.HighHigh.count} data {stats.HighHigh.count === 1 ? 'product' : 'products'}
              </div>
            </div>
            <div className="flex flex-col items-center justify-center">
              <div className="text-secondary-text text-base">Low / Low</div>
              <div className="text-primary-text text-xl">${stats.LowLow.totalCost.toLocaleString()}</div>
              <div className="text-secondary-text text-xs">
                {stats.LowLow.count} data {stats.LowLow.count === 1 ? 'product' : 'products'}
              </div>
            </div>
            <div className="flex flex-col items-center justify-center">
              <div className="text-red-highlight text-base">Low / High</div>
              <div className="text-primary-text text-xl">${stats.LowHigh.totalCost.toLocaleString()}</div>
              <div className="text-secondary-text text-xs">
                {stats.LowHigh.count} data {stats.LowHigh.count === 1 ? 'product' : 'products'}
              </div>
            </div>
          </div>
        </foreignObject>
      </Group>
    </svg>
  );
};

export default BusinessValueTable;
