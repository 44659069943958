import React, { useEffect, useState } from 'react';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import Card from 'components/Card';
import ActiveUsersDonutChart from './ActiveUsersDonutChart';
import { useAppSelector } from 'app/hooks';
import { selectStartDate, selectEndDate, selectSelectedDataProducts } from '../reducers/dataProductsSlice';
import { selectSelectedProjects, selectSelectedEnvironments } from 'features/layouts/reducers/appBarSlice';
import { useGetActiveUsersQuery } from 'app/dataProductsApis';
import { stringArrayFromOptionArray, truncateString } from 'utils/formatUtils';

export interface IUserData {
  roleName: string;
  usersCount: number;
  percentage: number;
  color: string;
}

// const sampleData: IUserData[] = [
//   { roleName: 'Role 1', usersCount: 9, percentage: 9, color: 'bg-cyan-500' },
//   { roleName: 'Role 2', usersCount: 22, percentage: 22, color: 'bg-cyan-600' },
//   { roleName: 'Role 3', usersCount: 29, percentage: 29, color: 'bg-cyan-700' },
//   { roleName: 'Role 4', usersCount: 41, percentage: 41, color: 'bg-cyan-800' },
// ];

const colors = ['bg-cyan-800', 'bg-cyan-700', 'bg-cyan-600', 'bg-cyan-500'];

const ActiveUsersCard = (props: { style: string }): JSX.Element => {
  const [total, setTotal] = useState<number>(0);
  const [processedData, setProcessedData] = useState<IUserData[]>([]);
  const selectedProjects = useAppSelector(selectSelectedProjects);
  const selectedEnvironments = useAppSelector(selectSelectedEnvironments);
  const selectedDataProducts = useAppSelector(selectSelectedDataProducts);
  const startDate = useAppSelector(selectStartDate);
  const endDate = useAppSelector(selectEndDate);

  const { data } = useGetActiveUsersQuery({
    startDate,
    endDate,
    projectId: selectedProjects,
    env: stringArrayFromOptionArray(selectedEnvironments),
    data_product: selectedDataProducts,
  });

  useEffect(() => {
    if (data !== undefined) {
      const tmpProcessedData: IUserData[] = [];
      for (let i = 0; i < data.users.length; ++i) {
        tmpProcessedData.push({ ...data.users[i], color: colors[i] });
      }
      setProcessedData(tmpProcessedData);
      setTotal(data.total);
    }
  }, [data]);

  return (
    <Card style={`h-46 space-y-2 flex flex-col ${props.style}`}>
      <div className="w-full text-base text-primary-text">Active Users</div>
      <>
        {processedData.length !== 0 && (
          <div className="flex-grow flex space-x-2">
            <div className="w-1/2 h-full space-y-2">
              {processedData.map((user) => (
                <div key={user.roleName} className="w-full flex items-center space-x-2">
                  <div className={`rounded-full w-3 h-3 ${user.color}`} />
                  <div className="flex-2 text-[0.7rem] text-primary-text">
                    {truncateString(user.roleName.replaceAll('DATAOPS_', ''), 7)}
                  </div>
                  <div className="flex-1 text-xs hidden text-primary-text">{user.usersCount}</div>
                  <div className="flex-1 text-xs text-primary-text font-medium">{Math.round(user.percentage)}%</div>
                </div>
              ))}
            </div>
            <div className="w-1/2 h-full">
              <ParentSize>
                {(parent) => (
                  <ActiveUsersDonutChart
                    data={processedData}
                    total={total}
                    width={parent.width}
                    height={parent.height}
                  />
                )}
              </ParentSize>
            </div>
          </div>
        )}
        {processedData.length === 0 && <div className="text-secondary-text text-xs">No users</div>}
      </>
    </Card>
  );
};

export default ActiveUsersCard;
