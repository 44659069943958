import React from 'react';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { localPoint } from '@visx/event';
import { GridColumns, GridRows } from '@visx/grid';
import { Group } from '@visx/group';
import { useTooltip, useTooltipInPortal, defaultStyles } from '@visx/tooltip';
import { scaleLinear } from '@visx/scale';
import { NumberValue } from 'd3';
import { range } from 'lodash';
import { ICostOverBusinessValue } from './BusinessValueCard';
import { formatNumberShort } from 'utils/formatUtils';

interface BusinessValueBubbleChartProps {
  costLimit: number;
  valueLimit: number;
  data: ICostOverBusinessValue[];
  width: number;
  height: number;
}

interface TooltipData {
  name: string;
  queries: number;
  value: number;
  cost: number;
}

const minRadius = 20;
const maxRadius = 50;

const margin = {
  left: 40,
  right: maxRadius,
  bottom: 30,
  top: maxRadius,
};

const tooltipStyles = {
  ...defaultStyles,
  minWidth: 170,
  backgroundColor: '#27262c',
  color: 'white',
  fontSize: 12,
};

const formatValue = (value: NumberValue): string => `${Math.round(value as number)}`;

const BusinessValueBubbleChart = (props: BusinessValueBubbleChartProps): JSX.Element => {
  const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } = useTooltip<TooltipData>();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });

  const xMax = props.width - margin.left - margin.right;
  const yMax = props.height - margin.top - margin.bottom;

  const costScale = scaleLinear<number>({
    domain: [0, Math.max(...props.data.map((d) => d.cost))],
    range: [0, xMax],
    round: true,
    nice: true,
  });

  const maxValue = Math.max(...props.data.map((d) => d.value));
  const maxTickValue = Math.round(maxValue) + 1;
  const tickValues = range(0, maxTickValue, 1);

  const valueScale = scaleLinear<number>({
    domain: [0, maxTickValue],
    range: [yMax, 0],
    round: true,
    nice: true,
  });

  const queryScale = scaleLinear<number>({
    domain: [Math.min(...props.data.map((d) => d.queries)), Math.max(...props.data.map((d) => d.queries))],
    range: [minRadius, maxRadius],
    round: true,
    nice: true,
  });

  return (
    <svg ref={containerRef} width={props.width} height={props.height}>
      <Group top={margin.top} left={margin.left}>
        {props.data.map((product) => (
          <circle
            key={product.name}
            cx={costScale(product.cost)}
            cy={valueScale(product.value)}
            r={queryScale(product.queries)}
            fill={product.color}
            fillOpacity={0.85}
            onMouseLeave={() => {
              hideTooltip();
            }}
            onMouseMove={(event) => {
              const eventSvgCoords = localPoint(event);
              showTooltip({
                tooltipData: product,
                tooltipTop: eventSvgCoords?.y,
                tooltipLeft: eventSvgCoords?.x,
              });
            }}
          />
        ))}
        <AxisLeft
          scale={valueScale}
          stroke="#87858d"
          tickStroke="#87858d"
          label="Business Value"
          labelOffset={15}
          labelProps={{
            fontSize: 14,
            fill: '#87858d',
            strokeWidth: 0,
            stroke: '#fff',
            paintOrder: 'stroke',
            fontFamily: 'sans-serif',
            textAnchor: 'middle',
          }}
          tickLabelProps={() => ({
            fontSize: 11,
            textAnchor: 'end',
            dy: '0.33em',
          })}
          tickValues={tickValues}
          tickFormat={formatValue}
          hideTicks
        />
        <AxisBottom
          top={yMax}
          scale={costScale}
          stroke="#87858d"
          tickStroke="#87858d"
          tickLabelProps={() => ({
            fontSize: 11,
            textAnchor: 'middle',
          })}
          numTicks={5}
          tickFormat={(cost: NumberValue) => `${formatNumberShort(cost as number)}$`}
          hideZero
          hideTicks
        />
        <GridRows
          scale={valueScale}
          tickValues={[props.valueLimit]}
          width={xMax}
          stroke="#87858d"
          strokeOpacity={0.4}
          strokeDasharray="4,4"
        />
        <GridColumns
          scale={costScale}
          tickValues={[props.costLimit]}
          height={yMax}
          stroke="#87858d"
          strokeOpacity={0.4}
          strokeDasharray="4,4"
        />
        <text x={15} y={10} fontSize={16} fill="#87858d">
          High / Low
        </text>
        <text x={15} y={yMax / 2 + 20} fontSize={16} fill="#87858d">
          Low / Low
        </text>
        <text x={xMax / 2 + 15} y={10} fontSize={16} fill="#87858d">
          High / High
        </text>
        <text x={xMax / 2 + 15} y={yMax / 2 + 20} fontSize={16} fill="#87858d">
          Low / High
        </text>
        {tooltipOpen && tooltipData !== undefined && (
          <TooltipInPortal top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
            <div className="text-sm break-words">{tooltipData.name}</div>
            <div className="flex">
              <div className="flex-grow text-secondary-text">Queries: </div>
              <div>{tooltipData.queries}</div>
            </div>
            <div className="flex">
              <div className="flex-grow text-secondary-text">Business Value: </div>
              <div>{tooltipData.value}</div>
            </div>
            <div className="flex">
              <div className="flex-grow text-secondary-text">Cost: </div>
              <div>${tooltipData.cost.toLocaleString()}</div>
            </div>
          </TooltipInPortal>
        )}
      </Group>
    </svg>
  );
};

export default BusinessValueBubbleChart;
