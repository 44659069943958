import React, { useState } from 'react';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import Card from 'components/Card';
import TotalCostOverTimeChart from './TotalCostOverTimeChart';
import Dropdown from 'components/Dropdown';
import { useAppSelector } from 'app/hooks';
import { selectStartDate, selectEndDate, selectSelectedDataProducts } from '../reducers/dataProductsSlice';
import { selectSelectedProjects, selectSelectedEnvironments } from 'features/layouts/reducers/appBarSlice';
import { useGetTotalCostOverTimeQuery } from 'app/dataProductsApis';
import { stringArrayFromOptionArray } from 'utils/formatUtils';

const sortByOptions = ['Hourly'];

export interface ITotalCostOverTime {
  date: string;
  total_cost: number;
  production_cost: number;
  consumption_cost: number;
}

// const data = [
//   { date: '10am', total_cost: 75000, production_cost: 30000, consumption_cost: 45000 },
//   { date: '11am', total_cost: 73000, production_cost: 32000, consumption_cost: 41000 },
//   { date: '12am', total_cost: 55000, production_cost: 25000, consumption_cost: 30000 },
//   { date: '1pm', total_cost: 64000, production_cost: 29000, consumption_cost: 35000 },
//   { date: '2pm', total_cost: 75000, production_cost: 30000, consumption_cost: 45000 },
//   { date: '3pm', total_cost: 73000, production_cost: 32000, consumption_cost: 41000 },
//   { date: '4pm', total_cost: 55000, production_cost: 25000, consumption_cost: 30000 },
//   { date: '5pm', total_cost: 64000, production_cost: 29000, consumption_cost: 35000 },
// ];

const TotalCostOverTimeCard = (props: { style: string }): JSX.Element => {
  const [sortBy, setSortBy] = useState(sortByOptions[0]);
  const selectedProjects = useAppSelector(selectSelectedProjects);
  const selectedEnvironments = useAppSelector(selectSelectedEnvironments);
  const selectedDataProducts = useAppSelector(selectSelectedDataProducts);
  const startDate = useAppSelector(selectStartDate);
  const endDate = useAppSelector(selectEndDate);

  const { data } = useGetTotalCostOverTimeQuery({
    startDate,
    endDate,
    bins: 7,
    projectId: selectedProjects,
    env: stringArrayFromOptionArray(selectedEnvironments),
    data_product: selectedDataProducts,
  });

  return (
    <Card style={`${props.style}`}>
      <div className="flex">
        <div className="flex-grow text-primary-text">Total Cost over time</div>
        <Dropdown options={sortByOptions} value={sortBy} onChange={setSortBy} />
      </div>
      <div className="w-full h-5/6">
        {data !== undefined && data.length !== 0 && (
          <ParentSize>
            {(parent) => <TotalCostOverTimeChart data={data} width={parent.width} height={parent.height} />}
          </ParentSize>
        )}
      </div>
    </Card>
  );
};

export default TotalCostOverTimeCard;
