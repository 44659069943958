import React from 'react';
import SimpleOverviewCard from 'components/SimpleOverviewCard';
import Card from 'components/Card';
import { useAppSelector } from 'app/hooks';
import { selectSelectedAccounts, selectSelectedWarehouses, selectShowDollars } from '../reducers/finobsSlice';
import { selectStartDate, selectEndDate } from 'app/sharedSlice';
import { useGetCostOverviewQuery } from 'app/spendviewApis';
import { classNames } from 'utils/styleUtils';

export interface IOverview {
  number_of_users: number;
  number_of_users_change: number;
  number_of_warehouses: number;
  compute_cost: number;
  credits_used: number;
  monthly_cost: number;
  monthly_cost_change: number;
  average_daily_cost: number;
  average_daily_cost_change: number;
}

const OverviewCard = (props: { style: string }): JSX.Element => {
  const selectedAccounts = useAppSelector(selectSelectedAccounts);
  const selectedWarehouses = useAppSelector(selectSelectedWarehouses);
  const startDate = useAppSelector(selectStartDate);
  const endDate = useAppSelector(selectEndDate);
  const showDollars = useAppSelector(selectShowDollars);

  const { data, isFetching } = useGetCostOverviewQuery({
    startDate,
    endDate,
    warehouses: selectedWarehouses,
    aggregate_accounts: true,
    aggregate_by_accounts: selectedAccounts,
    in_dollars: showDollars,
  });

  return (
    <Card style={classNames(props.style, 'p-0 rounded-md shadow-sm bg-dashboard-background flex space-x-1')}>
      <>
        <div className="flex-1 h-full space-y-1 flex flex-col">
          <SimpleOverviewCard
            label="Number of users"
            value={data?.number_of_users}
            change={data?.number_of_users_change}
            isFetching={isFetching}
            tooltipText="Number of all users who have used the selected accounts over the chosen period"
          />
          <SimpleOverviewCard
            label="Number of warehouses"
            value={data?.number_of_warehouses}
            isFetching={isFetching}
            tooltipText="Number of warehouses used by the selected accounts over the chosen period"
          />
        </div>
        <div className="flex-1 h-full space-y-1 flex flex-col">
          <SimpleOverviewCard
            label="Monthly credits used"
            value={data?.credits_used}
            isFetching={isFetching}
            tooltipText="Last 30 days credits spent across the warehouses in the selected accounts"
          />
          <SimpleOverviewCard
            label="Compute spend per period"
            value={data?.compute_cost}
            valuePrefix={showDollars ? '$' : ''}
            isFetching={isFetching}
            tooltipText="Dollars or credits spent across the warehouses in the selected accounts over the chosen period"
          />
        </div>
        <div className="flex-1 h-full space-y-1 flex flex-col">
          <SimpleOverviewCard
            label="Monthly spend"
            value={data?.monthly_cost}
            valuePrefix={showDollars ? '$' : ''}
            isFetching={isFetching}
            tooltipText="Dollars or credits spent across the warehouses in the selected accounts over the last 30 days"
          />
          <SimpleOverviewCard
            label="Monthly spend change"
            value={data?.monthly_cost_change}
            valuePostfix="%"
            useColor={true}
            isFetching={isFetching}
            tooltipText="Cost percentage change — increase or decrease — between the last 30 days and the equal previous period"
          />
        </div>
        <div className="flex-1 h-full space-y-1 flex flex-col">
          <SimpleOverviewCard
            label="Avg. daily spend"
            value={data?.average_daily_cost}
            valuePrefix={showDollars ? '$' : ''}
            isFetching={isFetching}
            tooltipText="Average dollars or credits spent per day across the selected account billed over the chosen period (compute cost divided by the days set in the date field)"
          />
          <SimpleOverviewCard
            label="Avg. daily spend change"
            value={data?.average_daily_cost_change}
            valuePostfix="%"
            useColor={true}
            isFetching={isFetching}
            tooltipText="Cost percentage change — increase or decrease — between the last 7 days and the equal previous period"
          />
        </div>
      </>
    </Card>
  );
};

export default OverviewCard;
