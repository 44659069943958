import React, { useMemo } from 'react';
import { scaleOrdinal } from 'd3';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { Bar } from '@visx/shape';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear } from '@visx/scale';
import { useTooltip, useTooltipInPortal, defaultStyles } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import { ITimeseriesElement } from 'utils/types';
import { formatTickLabelCost, formatTickLabelCredit, formatNumberLong, truncateString } from 'utils/formatUtils';
import { ICostPerUser } from './UsagePerUserCard';
import colors, { dataopsColorScale } from 'utils/colors';
import useShowDollars from '../hooks/useShowDollar';

const margin = {
  left: 170,
  right: 60,
  bottom: 20,
  top: 0,
};

interface UsagePerUserBarChartProps {
  data: ICostPerUser[];
  tooltipLabel: string;
  width: number;
  height: number;
}

interface TooltipData {
  category: string;
  timeseries?: ITimeseriesElement[];
  percent?: number;
}

const tooltipStyles = {
  ...defaultStyles,
  backgroundColor: '#27262c',
  color: 'white',
  fontSize: 12,
};

const UsagePerUserBarChart = (props: UsagePerUserBarChartProps): JSX.Element => {
  const showDollars = useShowDollars();
  const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } = useTooltip<TooltipData>();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });

  const xMax = props.width - margin.left - margin.right;
  const yMax = props.height - margin.top - margin.bottom;

  const xScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [0, xMax],
        round: true,
        domain: [0, Math.max(...props.data.map((d) => d.timeseries[0].value))],
      }),
    [xMax, props.data],
  );

  const yScale = useMemo(
    () =>
      scaleBand<string>({
        range: [0, yMax],
        round: true,
        domain: [...props.data.map((d) => d.category)],
        padding: props.data.length === 1 ? 0.6 : 0.4,
      }),
    [yMax, props.data],
  );

  const colorScale = scaleOrdinal<string>()
    .domain(props.data.map((d) => d.category))
    .range(dataopsColorScale);

  return (
    <svg ref={containerRef} width={props.width} height={props.height}>
      <Group top={margin.top} left={margin.left}>
        {props.data.map((d) => {
          const category: string = d.category;
          const barWidth = xScale(d.timeseries[0].value) ?? 0;
          const barHeight = yScale.bandwidth();
          const barX = 0;
          const barY = yScale(category);
          return (
            <Bar
              key={`bar-${category}`}
              x={barX}
              y={barY}
              width={barWidth}
              height={barHeight}
              fill={colorScale(category)}
              opacity={0.9}
              rx={3}
              onMouseLeave={() => {
                hideTooltip();
              }}
              onMouseMove={(event) => {
                const eventSvgCoords = localPoint(event);
                showTooltip({
                  tooltipData: d,
                  tooltipTop: eventSvgCoords?.y,
                  tooltipLeft: eventSvgCoords?.x,
                });
              }}
            />
          );
        })}
        <AxisLeft
          scale={yScale}
          stroke={colors.axisColor}
          tickStroke={colors.axisColor}
          tickLabelProps={() => ({
            fill: colors.tickLabelColor,
            fontSize: 11,
            textAnchor: 'end',
            dy: '0.33em',
            dx: '-0.25em',
          })}
          tickComponent={({ formattedValue, ...tickProps }) => (
            <text
              {...tickProps}
              style={{ cursor: 'default' }}
              onMouseLeave={() => {
                hideTooltip();
              }}
              onMouseMove={(event) => {
                const eventSvgCoords = localPoint(event);
                showTooltip({
                  tooltipData: {
                    category: (formattedValue ?? 'null') as string,
                  },
                  tooltipTop: eventSvgCoords?.y,
                  tooltipLeft: eventSvgCoords?.x,
                });
              }}
            >
              {truncateString(formattedValue ?? 'null', 20)}
            </text>
          )}
          hideTicks
        />
        <AxisBottom
          top={yMax}
          scale={xScale}
          stroke={colors.axisColor}
          tickFormat={showDollars ? formatTickLabelCost : formatTickLabelCredit}
          tickStroke={colors.axisColor}
          numTicks={4}
          tickLabelProps={() => ({
            ill: colors.tickLabelColor,
            fontSize: 11,
            textAnchor: 'middle',
          })}
        />
        {tooltipOpen && tooltipData !== undefined && (
          <TooltipInPortal top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
            <div>{truncateString(tooltipData.category ?? 'null', 25)}</div>
            {tooltipData.percent !== undefined && (
              <div className="flex">
                <div className="pr-2">Percent: </div>
                <div>{formatNumberLong(tooltipData.percent)}%</div>
              </div>
            )}
            {tooltipData.timeseries !== undefined && (
              <div className="flex">
                <div className="pr-2">{showDollars ? 'Cost: ' : 'Credits: '}</div>
                <div>
                  {showDollars ? '$' : ''}
                  {formatNumberLong(tooltipData.timeseries[0].value)}
                </div>
              </div>
            )}
          </TooltipInPortal>
        )}
      </Group>
    </svg>
  );
};

export default UsagePerUserBarChart;
