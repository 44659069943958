import React, { useEffect, useState } from 'react';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import Card from 'components/Card';
import CardTitle from 'components/CardTitle';
import QueriesByTypeTreemap from './QueriesByTypeTreemap';
import DetailsButton from './DetailsButton';
import NoRecentActivity from 'components/NoRecentActivity';
import LessThanXDays from 'components/LessThanXDays';
import { useAppSelector } from 'app/hooks';
import { selectSelectedAccounts, selectSelectedWarehouses } from '../reducers/finobsSlice';
import { selectStartDate, selectEndDate } from 'app/sharedSlice';
import { useGetStorageByTypeQuery } from 'app/spendviewApis';
import { IHierarchicalData } from './QueriesByTypeCard';
import { getNumberOfDays } from 'utils/dateUtils';

export interface IStorageByType {
  active: number;
  time_travel: number;
  failsafe: number;
  clone: number;
}

const StorageByTypeCard = (props: { style: string }): JSX.Element => {
  const [processedData, setProcessedData] = useState<IHierarchicalData[]>([]);
  const selectedAccounts = useAppSelector(selectSelectedAccounts);
  const selectedWarehouses = useAppSelector(selectSelectedWarehouses);
  const startDate = useAppSelector(selectStartDate);
  const endDate = useAppSelector(selectEndDate);
  const numberOfDays = getNumberOfDays(startDate, endDate);

  const { data, isFetching, isError } = useGetStorageByTypeQuery(
    {
      startDate,
      endDate,
      warehouses: selectedWarehouses,
      aggregate_accounts: true,
      aggregate_by_accounts: selectedAccounts,
      in_dollars: true,
    },
    { skip: numberOfDays <= 1 },
  );

  useEffect(() => {
    if (data !== undefined) {
      const total = data.active + data.time_travel + data.failsafe + data.clone;
      if (total === 0) {
        setProcessedData([]);
      } else {
        const tmpProcessedData: IHierarchicalData[] = [{ id: 'All', parent: null, size: null, percent: null }];
        tmpProcessedData.push({ id: 'active', parent: 'All', size: data.active, percent: (data.active / total) * 100 });
        tmpProcessedData.push({
          id: 'time_travel',
          parent: 'All',
          size: data.time_travel,
          percent: (data.time_travel / total) * 100,
        });
        tmpProcessedData.push({
          id: 'failsafe',
          parent: 'All',
          size: data.failsafe,
          percent: (data.failsafe / total) * 100,
        });
        tmpProcessedData.push({ id: 'clone', parent: 'All', size: data.clone, percent: (data.clone / total) * 100 });
        tmpProcessedData.sort((a: IHierarchicalData, b: IHierarchicalData) => (a.percent ?? 0) - (b.percent ?? 0));
        setProcessedData(tmpProcessedData);
      }
    }
  }, [data]);

  return (
    <Card style={props.style} isLoading={isFetching} isError={isError}>
      <div className="flex">
        <CardTitle
          title="Storage by type"
          subtitleText="Percentage distribution of data storage by type"
          tooltipText="Percentage of data storage types across selected accounts / or Categories of storage type are: active, clone, failsafe and time_travel"
        />
      </div>
      <div className="w-full h-[85%]">
        {numberOfDays > 1 && processedData.length !== 0 && (
          <ParentSize>
            {(parent) => (
              <QueriesByTypeTreemap
                data={processedData}
                isStorageData={true}
                keys={['active', 'clone', 'time_travel', 'failsafe']}
                width={parent.width}
                height={parent.height}
              />
            )}
          </ParentSize>
        )}
        {numberOfDays > 1 && processedData.length === 0 && !isFetching && !isError && <NoRecentActivity />}
        {numberOfDays <= 1 && !isFetching && !isError && <LessThanXDays days={1} />}
      </div>
      <DetailsButton
        data={data !== undefined ? [data] : []}
        isTimeseriesArray={false}
        componentName="Storage by type"
      />
    </Card>
  );
};

export default StorageByTypeCard;
