import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from './store';
import { IRequestStatus } from 'utils/types';

export interface SharedState {
  timeframeOption: string;
  startDate: string;
  endDate: string;
  timeframeStatus: IRequestStatus;
}

const now = new Date();
const [month, day, year, hour] = [now.getMonth(), now.getDate(), now.getFullYear(), now.getHours()];
const initialStartDate = new Date(year, month, day, hour, 0);
const initialEndDate = new Date(year, month, day, hour, 0);
initialStartDate.setDate(initialEndDate.getDate() - 7);

const initialState: SharedState = {
  timeframeOption: 'Last 7 days',
  startDate: initialStartDate.toISOString(),
  endDate: initialEndDate.toISOString(),
  timeframeStatus: 'idle',
};

export const fetchTimeframe = createAsyncThunk('timeframe', async () => {
  const response = await axios.get('/restapi/top_bar/start_end_date');
  return response.data;
});

export const sharedSlice = createSlice({
  name: 'shared',
  initialState,
  reducers: {
    setTimeframeOption: (
      state,
      action: PayloadAction<{ timeframeOption: string; startDate?: string; endDate?: string }>,
    ) => {
      const now = new Date();
      const [month, day, year, hour] = [now.getMonth(), now.getDate(), now.getFullYear(), now.getHours()];
      const startDate = new Date(year, month, day, hour, 0);
      const endDate = new Date(year, month, day, hour, 0);
      switch (action.payload.timeframeOption) {
        case 'Last day':
          startDate.setDate(startDate.getDate() - 1);
          break;
        case 'Last 7 days':
          startDate.setDate(startDate.getDate() - 7);
          break;
        case 'Last 30 days':
          startDate.setDate(startDate.getDate() - 30);
          break;
        case 'Last 60 days':
          startDate.setDate(startDate.getDate() - 60);
          break;
        case 'Last 90 days':
          startDate.setDate(startDate.getDate() - 90);
          break;
        case 'Last 180 days':
          startDate.setDate(startDate.getDate() - 180);
          break;
        case 'Last 360 days':
          startDate.setDate(startDate.getDate() - 360);
          break;
        case 'Custom': {
          state.timeframeOption = action.payload.timeframeOption;
          let customStartDate = action.payload.startDate ?? '';
          const customEndDate = action.payload.endDate ?? '';
          if (customStartDate === customEndDate) {
            const tmpDate = new Date(customStartDate);
            tmpDate.setDate(tmpDate.getDate() - 1);
            customStartDate = tmpDate.toISOString();
          }
          state.startDate = customStartDate;
          state.endDate = customEndDate;
          axios
            .post('/restapi/top_bar/start_end_date', {
              relative_duration: action.payload.timeframeOption,
              startDate: action.payload.startDate,
              endDate: action.payload.endDate,
            })
            .catch((err) => {
              console.log(err);
            });
          return;
        }
        default:
          console.log('Error');
      }
      state.timeframeOption = action.payload.timeframeOption;
      state.startDate = startDate.toISOString();
      state.endDate = endDate.toISOString();
      axios
        .post('/restapi/top_bar/start_end_date', {
          relative_duration: action.payload.timeframeOption,
          startDate: null,
          endDate: null,
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTimeframe.pending, (state) => {
        state.timeframeStatus = 'loading';
      })
      .addCase(fetchTimeframe.fulfilled, (state, action) => {
        state.timeframeStatus = 'idle';
        if (action.payload.relative_duration !== null) {
          sharedSlice.caseReducers.setTimeframeOption(state, {
            payload: {
              timeframeOption: action.payload.relative_duration,
              startDate: action.payload.startDate ?? undefined,
              endDate: action.payload.endDate ?? undefined,
            },
            type: 'finobs/setTimeframeOption',
          });
        }
      })
      .addCase(fetchTimeframe.rejected, (state) => {
        state.timeframeStatus = 'failed';
      });
  },
});

export const { setTimeframeOption } = sharedSlice.actions;

export const selectTimeframeOption = (state: RootState): string => state.shared.timeframeOption;
export const selectStartDate = (state: RootState): string => state.shared.startDate;
export const selectEndDate = (state: RootState): string => state.shared.endDate;

export default sharedSlice.reducer;
