import React, { useState, useMemo } from 'react';
import axios from 'axios';
import { CurrencyDollarIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import Switch from 'components/Switch';
import AccountFormButton from './AccountFormButton';
import { useTooltip, useTooltipInPortal, defaultStyles } from '@visx/tooltip';
import { ISnowflakeAccount } from 'views/SnowflakeAccountsView';
import DeleteConfirmationDialog from './DeleteConfirmationDialog';
import { classNames } from 'utils/styleUtils';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import {
  fetchAccounts,
  setSelectedAccounts,
  setPendingSelectedAccounts,
  setSelectedWarehouses,
  setPendingSelectedWarehouses,
} from 'features/finobs/reducers/finobsSlice';
import { selectIsAdmin, setHasSnowflakeCredentials } from 'features/authentication/reducers/userSlice';
import {
  useMarkSnowflakeAccountActiveMutation,
  useMarkSnowflakeAccountInactiveMutation,
  useAddAccountToGroupMutation,
  useRemoveAccountFromGroupMutation,
} from 'app/spendviewApis';

const tooltipStyles = {
  ...defaultStyles,
  backgroundColor: '#4b5563',
  color: 'white',
  fontSize: 12,
  zIndex: 100,
  maxWidth: 150,
};

interface SnowflakeAccountCardProps {
  data: ISnowflakeAccount;
  setShowError: (showError: boolean) => void;
  setErrorMessage: (errorMessage: string) => void;
}

const SnowflakeAccountCard = (props: SnowflakeAccountCardProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const [credentialsPanelActive, setCredentialsPanelActive] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [account, setAccount] = useState<string>(props.data.account);
  const [username, setUsername] = useState<string>(props.data.username);
  const [warehouse, setWarehouse] = useState<string>(props.data.warehouse);
  const [role, setRole] = useState<string>(props.data.role);
  const [persist, setPersist] = useState<boolean>(props.data.persist);
  const [isActive, setIsActive] = useState<boolean>(props.data.is_active);
  const [isGroupAccount, setIsGroupAccount] = useState<boolean>(props.data.is_group_account);
  const [password, setPassword] = useState<string>('');
  const [computeCoefficient, setComputeCoefficient] = useState<string>(props.data.compute_coefficient.toString());
  const [storageCoefficient, setStorageCoefficient] = useState<string>(props.data.storage_coefficient.toString());
  const [loading, setLoading] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);
  const [markSnowflakeAccountActive] = useMarkSnowflakeAccountActiveMutation();
  const [markSnowflakeAccountInactive] = useMarkSnowflakeAccountInactiveMutation();
  const [addAccountToGroup] = useAddAccountToGroupMutation();
  const [removeAccountFromGroup] = useRemoveAccountFromGroupMutation();
  const isEditable = useMemo(() => props.data.is_editable, [props.data.is_editable]);
  const isAdmin = useAppSelector(selectIsAdmin);

  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState<boolean>(false);

  const { tooltipOpen, tooltipLeft, tooltipTop, hideTooltip, showTooltip } = useTooltip();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });

  const handleCredetialsSubmit = (e: React.FormEvent): void => {
    e.preventDefault();
    setLoading(true);
    setFailed(false);
    axios
      .put('/restapi/top_bar/snowflake_accounts', {
        account,
        username,
        warehouse,
        role,
        password,
        client_id: props.data.client_id,
        token_url: props.data.token_url,
        persist: props.data.persist,
        id: props.data.id,
      })
      .then((response) => {
        console.log(response);
      })
      .catch((err) => {
        console.log(err);
        setFailed(true);
        props.setErrorMessage(err.response.data);
        props.setShowError(true);
      })
      .finally(() => {
        setLoading(false);
        setEditMode(false);
      });
  };

  const handleParametersSubmit = (e: React.FormEvent): void => {
    e.preventDefault();
    setLoading(true);
    setFailed(false);
    axios
      .post('/restapi/top_bar/usd_convert_coefficient', {
        account,
        compute_coefficient: parseFloat(computeCoefficient),
        storage_coefficient: parseFloat(storageCoefficient),
      })
      .then((response) => {
        console.log(response);
      })
      .catch((err) => {
        console.log(err);
        setFailed(true);
        props.setErrorMessage(err.response.data);
        props.setShowError(true);
      })
      .finally(() => {
        setLoading(false);
        setEditMode(false);
      });
  };

  const changePanel = (): void => {
    setCredentialsPanelActive(!credentialsPanelActive);
    setLoading(false);
    setFailed(false);
    setEditMode(false);
  };

  const changePersist = (): void => {
    if (persist) {
      setPersist(false);
      axios
        .delete('/restapi/top_bar/persist_snowflake_account', {
          params: { account },
        })
        .then(() => {
          dispatch(fetchAccounts()).catch((err) => err);
        })
        .catch((err) => {
          console.log(err);
          setFailed(true);
        });
    } else {
      setPersist(true);
      axios
        .post('/restapi/top_bar/persist_snowflake_account', null, {
          params: { account },
        })
        .then(() => {
          dispatch(fetchAccounts()).catch((err) => err);
        })
        .catch((err) => {
          console.log(err);
          setFailed(true);
        });
    }
  };

  const changeIsActive = (): void => {
    setLoading(true);
    if (isActive) {
      markSnowflakeAccountInactive(account)
        .then(() => {
          setIsActive(false);
          dispatch(fetchAccounts()).catch((err) => err);
          dispatch(setHasSnowflakeCredentials(false));
          dispatch(setSelectedAccounts([]));
          dispatch(setPendingSelectedAccounts([]));
          dispatch(setSelectedWarehouses([]));
          dispatch(setPendingSelectedWarehouses([]));
          // axios
          //   .get('/restapi/top_bar/default_snowflake_account')
          //   .then((result) => {
          //     console.log(result.data);
          //     if (result.data === account) {
          //       console.log('defuult inatiavted');
          //       for (let i = 0; i < allAccounts.length; ++i) {
          //         if (allAccounts[i].is_active && allAccounts[i].account !== account) {
          //           axios
          //             .post('/restapi/top_bar/default_snowflake_account', null, {
          //               params: { account: allAccounts[i].account },
          //             })
          //             .then(() => {
          //               dispatch(fetchAccounts()).catch((err) => err);
          //             })
          //             .catch((err) => {
          //               console.log(err);
          //               setFailed(true);
          //             });
          //         }
          //       }
          //     }
          //   })
          //   .catch((err) => {
          //     console.log(err);
          //     setFailed(true);
          //   });
        })
        .catch((err) => {
          console.log(err);
          setFailed(true);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      markSnowflakeAccountActive(account)
        .then(() => {
          setIsActive(true);
          dispatch(fetchAccounts()).catch((err) => err);
          dispatch(setHasSnowflakeCredentials(false));
          dispatch(setSelectedAccounts([]));
          dispatch(setPendingSelectedAccounts([]));
          dispatch(setSelectedWarehouses([]));
          dispatch(setPendingSelectedWarehouses([]));
        })
        .catch((err) => {
          console.log(err);
          setFailed(true);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const changeGroupAccount = (): void => {
    setLoading(true);
    if (isGroupAccount) {
      removeAccountFromGroup(account)
        .then(() => {
          setIsGroupAccount(false);
        })
        .catch((err) => {
          console.log(err);
          setFailed(true);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      addAccountToGroup(account)
        .then(() => {
          setIsGroupAccount(true);
        })
        .catch((err) => {
          console.log(err);
          setFailed(true);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <div
      className={classNames(
        isActive && isEditable ? 'bg-white border-[2px] border-white' : 'bg-gray-50 border-[2px]',
        'p-4 px-8 rounded-md shadow-sm',
      )}
    >
      <div className="h-full max-w-md flex flex-col mx-[auto]">
        <div className="flex items-start mb-2">
          <div className={classNames(persist ? 'flex-grow' : '', 'text-lg text-gray-800 font-medium')}>
            {props.data.account}
          </div>
          {!persist && (
            <div className="flex-grow ml-2" ref={containerRef}>
              <ExclamationTriangleIcon
                className="h-6 w-6 text-yellow-500 hover:text-yellow-300"
                aria-hidden="true"
                onMouseLeave={() => {
                  hideTooltip();
                }}
                onMouseMove={() => {
                  showTooltip({
                    tooltipLeft: 15,
                    tooltipTop: 15,
                  });
                }}
              />
            </div>
          )}
          <div className="hidden items-center rounded-lg bg-gray-100 p-0.5 sm:flex">
            <button
              type="button"
              onClick={changePanel}
              className={classNames(
                credentialsPanelActive ? (isEditable ? 'bg-white hover:bg-white' : 'bg-gray-50') : 'hover:bg-white',
                ' rounded-md p-1.5 text-gray-400 hover:shadow-sm focus:outline-none focus:ring-2 focus:ring-inset focus:ring-dark-card-background',
              )}
              disabled={!isEditable}
            >
              <img src="/snowflake-icon.png" className="h-5 w-5" aria-hidden="true" />
            </button>
            <button
              type="button"
              onClick={changePanel}
              className={classNames(
                credentialsPanelActive ? '' : isEditable ? 'bg-white' : 'bg-gray-50',
                'ml-0.5 rounded-md p-1.5 text-gray-400 shadow-sm focus:outline-none focus:ring-2 focus:ring-inset focus:ring-dark-card-background',
              )}
              disabled={!isEditable}
            >
              <CurrencyDollarIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
        <div className="my-1 text-gray-700">
          {credentialsPanelActive ? 'Snowflake credentials' : 'Account parameters'}
        </div>
        {credentialsPanelActive && (
          <form className="w-full flex-grow mt-1 flex flex-col">
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="-space-y-px rounded-md shadow-sm">
              <div className="flex max-w-lg shadow-sm">
                <span className="w-[8.5rem] inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-2 text-gray-500 sm:text-sm">
                  Account
                </span>
                <input
                  name="account"
                  type="account"
                  autoComplete="account"
                  value={account}
                  onChange={(e) => setAccount(e.target.value)}
                  required
                  className="relative block w-full appearance-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
                  disabled
                />
              </div>
              <div className="flex max-w-lg rounded-md shadow-sm">
                <span className="w-[8.5rem] inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-2 text-gray-500 sm:text-sm">
                  Username
                </span>
                <input
                  name="username"
                  type="username"
                  autoComplete="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                  className="relative block w-full appearance-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
                  disabled={!editMode}
                />
              </div>
              <div className="flex max-w-lg rounded-md shadow-sm">
                <span className="w-[8.5rem] inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-2 text-gray-500 sm:text-sm">
                  Warehouse
                </span>
                <input
                  name="warehouse"
                  type="warehouse"
                  autoComplete="warehouse"
                  value={warehouse}
                  onChange={(e) => setWarehouse(e.target.value)}
                  required
                  className="relative block w-full appearance-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
                  disabled={!editMode}
                />
              </div>
              <div className="flex max-w-lg rounded-md shadow-sm">
                <span className="w-[8.5rem] inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-2 text-gray-500 sm:text-sm">
                  Role
                </span>
                <input
                  name="role"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                  className="relative block w-full appearance-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
                  disabled={!editMode}
                />
              </div>
              {editMode && (
                <div className="flex max-w-lg rounded-md shadow-sm">
                  <span className="w-[8.5rem] inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-2 text-gray-500 sm:text-sm">
                    Password
                  </span>
                  <input
                    name="password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    className="relative block w-full appearance-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
                  />
                </div>
              )}
            </div>
            {!editMode && (
              <div className="mt-4 flex items-center justify-around">
                <Switch checked={persist} onChange={changePersist} label="Persist" disabled={!isEditable} />
                <Switch checked={isActive} onChange={changeIsActive} label="Active" disabled={!isEditable} />
                {isEditable && isAdmin && (
                  <Switch checked={isGroupAccount} onChange={changeGroupAccount} label="Share" />
                )}
              </div>
            )}
            <div className="my-1 h-8 flex items-center justify-center">
              {loading && !failed && (
                <div
                  className="animate-spin inline-block w-6 h-6 border-[2px] border-current border-t-transparent text-cyan-800 rounded-full"
                  role="status"
                  aria-label="loading"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              )}
              {failed && (
                <div className="text-sm w-full rounded bg-red-50 border-2 border-red-500 flex items-center justify-center">
                  <a className="font-medium text-red-600 hover:text-red-500">Failed to update credentials</a>
                </div>
              )}
            </div>
            {!editMode && (
              <div className="w-full mt-[auto] px-4 flex space-x-4">
                <AccountFormButton label="Edit" onClick={() => setEditMode(true)} disabled={!isEditable} />
                <AccountFormButton
                  label="Delete"
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenDeleteConfirmation(true);
                  }}
                  disabled={!isEditable}
                />
              </div>
            )}
            {editMode && (
              <div className="w-full mt-[auto] px-4 flex space-x-4">
                <AccountFormButton label="Cancel" onClick={() => setEditMode(false)} />
                <AccountFormButton label="Save" onClick={handleCredetialsSubmit} type="submit" />
              </div>
            )}
          </form>
        )}
        {!credentialsPanelActive && (
          <form className="w-full flex-grow mt-1 flex flex-col">
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="-space-y-px rounded-md shadow-sm">
              <div className="flex max-w-lg rounded-md shadow-sm">
                <span className="w-[8.5rem] inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-2 text-gray-500 sm:text-sm">
                  $ per credit
                </span>
                <input
                  name="compute"
                  value={computeCoefficient}
                  onChange={(e) => setComputeCoefficient(e.target.value)}
                  required
                  className="relative block w-full appearance-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
                  disabled={!editMode}
                />
              </div>
              <div className="flex max-w-lg rounded-md shadow-sm">
                <span className="w-[8.5rem] inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-2 text-gray-500 sm:text-sm">
                  $ per TB
                </span>
                <input
                  name="storage"
                  value={storageCoefficient}
                  onChange={(e) => setStorageCoefficient(e.target.value)}
                  required
                  className="relative block w-full appearance-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
                  disabled={!editMode}
                />
              </div>
            </div>
            <div className="my-2 h-8 flex items-center justify-center">
              {loading && !failed && (
                <div
                  className="animate-spin inline-block w-6 h-6 border-[2px] border-current border-t-transparent text-cyan-800 rounded-full"
                  role="status"
                  aria-label="loading"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              )}
              {failed && (
                <div className="text-sm w-full rounded bg-red-50 border-2 border-red-500 flex items-center justify-center">
                  <a className="font-medium text-red-600 hover:text-red-500">Failed to update parameters</a>
                </div>
              )}
            </div>
            {!editMode && (
              <div className="w-full mt-[auto] px-4 flex space-x-4">
                <AccountFormButton label="Edit" onClick={() => setEditMode(true)} disabled={!isEditable} />
                <AccountFormButton
                  label="Delete"
                  onClick={() => setOpenDeleteConfirmation(true)}
                  disabled={!isEditable}
                />
              </div>
            )}
            {editMode && (
              <div className="w-full mt-[auto] px-4 flex space-x-4">
                <AccountFormButton label="Cancel" onClick={() => setEditMode(false)} />
                <AccountFormButton label="Save" onClick={handleParametersSubmit} type="submit" />
              </div>
            )}
          </form>
        )}
      </div>
      {tooltipOpen && (
        <TooltipInPortal top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
          <div className="text-sm">This snowflake account is not persisted and will be removed on logout</div>
        </TooltipInPortal>
      )}
      <DeleteConfirmationDialog open={openDeleteConfirmation} setOpen={setOpenDeleteConfirmation} account={account} />
    </div>
  );
};

export default SnowflakeAccountCard;
