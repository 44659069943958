import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import LoadingView from 'views/LoadingView';

interface ProtectedRouteProps {
  children: JSX.Element;
}

export default function ProtectedRoute(props: ProtectedRouteProps): JSX.Element {
  const { isLoading, isAuthenticated, getAccessTokenSilently, logout } = useAuth0();
  const [hasToken, setHasToken] = useState<boolean>(false);

  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently({
        authorizationParams: {
          audience: 'https://observability.dataops.live/rest',
        },
      })
        .then((token) => {
          axios.defaults.headers.common.Authorization = `Bearer ${token}`;
          setHasToken(true);
        })
        .catch((e) => {
          console.log(e);
          logout({ logoutParams: { returnTo: window.location.origin } }).catch((e) => e);
        });
    }
  }, [isLoading, isAuthenticated]);

  if (isLoading) {
    return <LoadingView />;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  if (!hasToken) {
    return <LoadingView />;
  }

  return props.children;
}
