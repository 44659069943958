import React from 'react';
import { AxisLeft, AxisTop } from '@visx/axis';
import { Group } from '@visx/group';
import { HeatmapRect } from '@visx/heatmap';
import { localPoint } from '@visx/event';
import { scaleBand, scaleLinear } from '@visx/scale';
import { useTooltip, useTooltipInPortal, defaultStyles } from '@visx/tooltip';
import { IDataProductAnalysis } from './DataProductAnalysisCard';
import { formatDate, formatNumberShort, truncateString } from 'utils/formatUtils';

const margin = {
  left: 120,
  right: 0,
  bottom: 0,
  top: 40,
};

interface DataProductAnalysisHeatmapProps {
  data: IDataProductAnalysis[];
  width: number;
  height: number;
}

interface IDataProduct {
  data_product: string;
  value: number;
}

interface IDataProductAnalysisTransformed {
  date: string;
  data_products: IDataProduct[];
}

interface TooltipData {
  data_product: string;
  value: number;
}

const tooltipStyles = {
  ...defaultStyles,
  backgroundColor: '#27262c',
  color: 'white',
  fontSize: 12,
};

const DataProductAnalysisHeatmap = (props: DataProductAnalysisHeatmapProps): JSX.Element => {
  const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } = useTooltip<TooltipData>();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });

  const data: IDataProductAnalysisTransformed[] = [];
  props.data.forEach((d, dIdx) => {
    d.timeseries.forEach((t, tIdx) => {
      if (dIdx === 0) {
        data.push({ date: t.date, data_products: [] });
      }
      data[tIdx].data_products.push({ data_product: d.data_product, value: t.value });
    });
  });

  const colorMax = Math.max(...data.map((date) => Math.max(...date.data_products.map((product) => product.value))));
  const bucketSizeMax = Math.max(...data.map((date) => date.data_products.length));

  const xScale = scaleLinear<number>({
    domain: [0, data.length],
  });

  const yScale = scaleLinear<number>({
    domain: [0, bucketSizeMax],
  });

  const xScaleBand = scaleBand<string>({
    domain: data.map((d) => d.date),
  });

  const yScaleBand = scaleBand<string>({
    domain: data[0].data_products.map((d) => d.data_product),
  });

  const rectColorScale = scaleLinear<string>({
    range: ['#cae8f2', '#0394c1'],
    domain: [0, colorMax],
  });

  const opacityScale = scaleLinear<number>({
    range: [0.1, 1],
    domain: [0, colorMax],
  });

  const xMax = props.width - margin.left - margin.right;
  const yMax = props.height - margin.top - margin.bottom;

  const binWidth = xMax / data.length;
  const binHeight = yMax / bucketSizeMax;

  xScale.range([0, xMax]);
  yScale.range([yMax, 0]);
  xScaleBand.rangeRound([0, xMax]);
  yScaleBand.rangeRound([yMax, 0]);

  return (
    <svg ref={containerRef} width={props.width} height={props.height}>
      {/* <rect x={0} y={0} width={props.width} height={props.height} rx={14} fill="#28272c" /> */}
      <Group top={margin.top} left={margin.left}>
        <HeatmapRect
          data={data}
          xScale={(d) => xScale(d) ?? 0}
          yScale={(d) => yScale(d + 1) ?? 0}
          colorScale={rectColorScale}
          opacityScale={opacityScale}
          binWidth={binWidth}
          binHeight={binHeight}
          gap={5}
          bins={(d: any) => d?.data_products}
          count={(d: any) => d?.value}
        >
          {(heatmap) =>
            heatmap.map((heatmapBins) =>
              heatmapBins.map((bin) => (
                <rect
                  key={`heatmap-rect-${bin.row}-${bin.column}`}
                  className="visx-heatmap-rect"
                  width={bin.width}
                  height={bin.height}
                  x={bin.x}
                  y={bin.y}
                  fill={bin.color}
                  fillOpacity={bin.opacity}
                  onMouseLeave={() => {
                    hideTooltip();
                  }}
                  onMouseMove={(event) => {
                    const eventSvgCoords = localPoint(event);
                    showTooltip({
                      tooltipData: bin.bin,
                      tooltipTop: eventSvgCoords?.y,
                      tooltipLeft: eventSvgCoords?.x,
                    });
                  }}
                />
              )),
            )
          }
        </HeatmapRect>
        <AxisLeft
          scale={yScaleBand}
          tickFormat={(d) => truncateString(d, 15)}
          tickLabelProps={() => ({
            fontSize: 11,
            textAnchor: 'end',
            dy: '0.33em',
          })}
          hideAxisLine
          hideTicks
        />
        <AxisTop
          scale={xScaleBand}
          tickFormat={formatDate}
          tickLabelProps={() => ({
            fontSize: 11,
            textAnchor: 'middle',
          })}
          hideAxisLine
          hideTicks
        />
        {tooltipOpen && tooltipData !== undefined && (
          <TooltipInPortal top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
            <div className="text-sm">{tooltipData.data_product}</div>
            <div className="flex">
              <div className="pr-2">Cost: </div>
              <div>{formatNumberShort(tooltipData.value)}$</div>
            </div>
          </TooltipInPortal>
        )}
      </Group>
    </svg>
  );
};

export default DataProductAnalysisHeatmap;
