import React from 'react';
import SimpleOverviewCard from 'components/SimpleOverviewCard';
import Card from 'components/Card';
import { useAppSelector } from 'app/hooks';
import { selectSelectedAccounts, selectSelectedWarehouses, selectShowDollars } from '../reducers/finobsSlice';
import { selectStartDate, selectEndDate } from 'app/sharedSlice';
import { useGetStorageOverviewQuery } from 'app/spendviewApis';
import { getNumberOfDays } from 'utils/dateUtils';
import { classNames } from 'utils/styleUtils';

export interface IStorageOverview {
  avg_monthly_storage_cost: number;
  avg_monthly_failsafe_cost: number;
  avg_monthly_stage_cost: number;
  avg_monthly_storage_cost_delta: number;
  avg_monthly_stage_cost_delta: number;
  avg_monthly_failsafe_cost_delta: number;
  monthly_storage: number;
  montly_stage: number;
  montly_failsafe: number;
  montly_stage_delta: number;
  montly_failsafe_delta: number;
  monthly_storage_delta: number;
  monthly_overall_tb: number;
  monthly_overall_tb_delta: number;
  monthly_overall_cost: number;
  monthly_overall_cost_delta: number;
  avg_monthly_overall: number;
  avg_monthly_overall_delta: number;
  avg_monthly_overall_tb: number;
  avg_monthly_overall_tb_delta: number;
  total_overall_spend: number;
  total_overall_in_tb: number;
}

const StorageOverviewCard = (props: { style: string }): JSX.Element => {
  const selectedAccounts = useAppSelector(selectSelectedAccounts);
  const selectedWarehouses = useAppSelector(selectSelectedWarehouses);
  const startDate = useAppSelector(selectStartDate);
  const endDate = useAppSelector(selectEndDate);
  const showDollars = useAppSelector(selectShowDollars);
  const numberOfDays = getNumberOfDays(startDate, endDate);

  const { data, isFetching } = useGetStorageOverviewQuery({
    startDate,
    endDate,
    warehouses: selectedWarehouses,
    aggregate_accounts: true,
    aggregate_by_accounts: selectedAccounts,
    in_dollars: showDollars,
  });

  return (
    <Card style={classNames(props.style, 'p-0 rounded-md shadow-sm bg-dashboard-background flex space-x-1')}>
      <>
        <div className="flex-1 h-full space-y-1 flex flex-col">
          <SimpleOverviewCard
            label="Total spend per period"
            value={numberOfDays >= 25 ? data?.total_overall_spend : null}
            valuePrefix={showDollars ? '$' : ''}
            isFetching={isFetching}
            tooltipText="Dollars or credits spent on storage across the warehouses in the selected accounts over the chosen period in the date field"
          />
          <SimpleOverviewCard
            label="TB per period"
            value={data?.total_overall_in_tb}
            valuePostfix="TB"
            isFetching={isFetching}
            tooltipText="Terabyte spent on storage across the warehouses in the selected accounts over the chosen period in the date field"
          />
        </div>
        <div className="flex-1 h-full space-y-1 flex flex-col">
          <SimpleOverviewCard
            label="Monthly storage"
            value={data?.monthly_storage}
            valuePrefix={showDollars ? '$' : ''}
            change={data?.monthly_storage_delta}
            isFetching={isFetching}
            tooltipText="Dollars or credits spent on storage across the warehouses in the selected accounts over the last 30 days"
          />
          <SimpleOverviewCard
            label="Avg. monthly storage"
            value={data?.avg_monthly_storage_cost}
            valuePrefix={showDollars ? '$' : ''}
            change={data?.avg_monthly_storage_cost_delta}
            isFetching={isFetching}
            tooltipText="Average monthly storage spent in dollars or credits across the warehouses in the selected accounts over the last 6 months"
          />
        </div>
        <div className="flex-1 h-full space-y-1 flex flex-col">
          <SimpleOverviewCard
            label="Monthly failsafe"
            value={data?.montly_failsafe}
            valuePrefix={showDollars ? '$' : ''}
            change={data?.montly_failsafe_delta}
            isFetching={isFetching}
            tooltipText="Dollars or credits spent on failsafe across the warehouses in the selected accounts over the last 30 days"
          />
          <SimpleOverviewCard
            label="Avg. monthly failsafe"
            value={data?.avg_monthly_failsafe_cost}
            valuePrefix={showDollars ? '$' : ''}
            change={data?.avg_monthly_failsafe_cost_delta}
            isFetching={isFetching}
            tooltipText="Average monthly failsafe spent in dollars or credits across the warehouses in the selected accounts over the last 6 months"
          />
        </div>
        <div className="flex-1 h-full space-y-1 flex flex-col">
          <SimpleOverviewCard
            label="Monthly stage"
            value={data?.montly_stage}
            valuePrefix={showDollars ? '$' : ''}
            change={data?.montly_stage_delta}
            isFetching={isFetching}
            tooltipText="Dollars or credits spent on stage across the warehouses in the selected accounts over the last 30 days"
          />
          <SimpleOverviewCard
            label="Avg. monthly stage"
            value={data?.avg_monthly_stage_cost}
            valuePrefix={showDollars ? '$' : ''}
            change={data?.avg_monthly_stage_cost_delta}
            isFetching={isFetching}
            tooltipText="Average monthly stage spent in dollars or credits across the warehouses in the selected accounts over the last 6 months"
          />
        </div>
        <div className="flex-1 h-full space-y-1 flex flex-col">
          <SimpleOverviewCard
            label="Monthly combined"
            value={data?.monthly_overall_cost}
            valuePrefix={showDollars ? '$' : ''}
            change={data?.monthly_overall_cost_delta}
            isFetching={isFetching}
            tooltipText="Dollars or credits spent on storage, failsafe, and stage across the warehouses in the selected accounts over the last 30 days"
          />
          <SimpleOverviewCard
            label="Avg. monthly combined"
            value={data?.avg_monthly_overall}
            valuePrefix={showDollars ? '$' : ''}
            change={data?.avg_monthly_overall_delta}
            isFetching={isFetching}
            tooltipText="Average monthly spent in dollars or credits on storage, failsafe, and stage across the warehouses in the selected accounts over the last 6 months"
          />
        </div>
        <div className="flex-1 h-full space-y-1 flex flex-col">
          <SimpleOverviewCard
            label="Monthly in TB"
            value={data?.monthly_overall_tb}
            valuePostfix="TB"
            change={data?.monthly_overall_tb_delta}
            isFetching={isFetching}
            tooltipText="Terabyte spent on storage across the warehouses in the selected accounts over the last 30 days"
          />
          <SimpleOverviewCard
            label="Avg. monthly in TB"
            value={data?.avg_monthly_overall_tb}
            valuePostfix="TB"
            change={data?.avg_monthly_overall_tb_delta}
            isFetching={isFetching}
            tooltipText="Average monthly TB spent across the warehouses in the selected accounts over the last 6 months"
          />
        </div>
      </>
    </Card>
  );
};

export default StorageOverviewCard;
