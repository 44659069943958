import React, { useEffect, useState } from 'react';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import Card from 'components/Card';
import CardTitle from 'components/CardTitle';
import QueriesByTypeTreemap from './QueriesByTypeTreemap';
import DetailsButton from './DetailsButton';
import NoRecentActivity from 'components/NoRecentActivity';
import { useAppSelector } from 'app/hooks';
import { selectSelectedAccounts, selectSelectedWarehouses, selectShowDollars } from '../reducers/finobsSlice';
import { selectStartDate, selectEndDate } from 'app/sharedSlice';
import { useGetTotalCostPerAccountQuery } from 'app/spendviewApis';

import { ITimeseries } from 'utils/types';
import { IHierarchicalData } from './QueriesByTypeCard';

const TotalSpendByAccountTreemapCard = (props: { style: string }): JSX.Element => {
  const [processedData, setProcessedData] = useState<IHierarchicalData[]>([]);
  const selectedAccounts = useAppSelector(selectSelectedAccounts);
  const selectedWarehouses = useAppSelector(selectSelectedWarehouses);
  const startDate = useAppSelector(selectStartDate);
  const endDate = useAppSelector(selectEndDate);
  const showDollars = useAppSelector(selectShowDollars);

  const { data, isFetching, isError } = useGetTotalCostPerAccountQuery({
    startDate,
    endDate,
    bins: 1,
    warehouses: selectedWarehouses,
    aggregate_accounts: true,
    aggregate_by_accounts: selectedAccounts,
    in_dollars: showDollars,
  });

  useEffect(() => {
    if (data !== undefined) {
      const total = data.reduce((partialSum: number, a: ITimeseries) => partialSum + a.timeseries[0].value, 0);
      const tmpProcessedData: IHierarchicalData[] = [{ id: 'All', parent: null, size: null, percent: null }];
      data.forEach((d: ITimeseries) => {
        tmpProcessedData.push({
          id: d.category,
          parent: 'All',
          size: d.timeseries[0].value,
          percent: (d.timeseries[0].value / total) * 100,
        });
      });
      tmpProcessedData.sort((a: IHierarchicalData, b: IHierarchicalData) => (a.percent ?? 0) - (b.percent ?? 0));
      setProcessedData(tmpProcessedData);
    }
  }, [data]);

  return (
    <Card style={props.style} isLoading={isFetching} isError={isError}>
      <div className="flex">
        <CardTitle
          title="Account spend weight"
          subtitleText="Spend distribution between accounts per period"
          tooltipText="On bottom right, click Show details to open an aggregate usage and spend view"
        />
      </div>
      <div className="w-full h-[85%]">
        {processedData.length !== 0 && (
          <ParentSize>
            {(parent) => (
              <QueriesByTypeTreemap
                data={processedData}
                showDollars={showDollars}
                width={parent.width}
                height={parent.height}
              />
            )}
          </ParentSize>
        )}
        {processedData.length === 0 && !isFetching && <NoRecentActivity />}
      </div>
      <DetailsButton
        data={data !== undefined ? data : []}
        isTimeseriesArray={true}
        componentName="Total spend by account"
      />
    </Card>
  );
};

export default TotalSpendByAccountTreemapCard;
