import React from 'react';
import { NavLink } from 'react-router-dom';
import DateRangeSelector from 'components/DateRangeSelector';
import { classNames } from 'utils/styleUtils';

const tabs = [
  { name: 'Overview', href: '/pipelines' },
  { name: 'Pipeline runs', href: '/pipelines/pipeline-runs' },
];

const PipelinesBar = (): JSX.Element => {
  return (
    <div className="fixed left-16 right-0 z-20 pl-8 pr-8 bg-white">
      <div className="border-b border-gray-200 flex items-center">
        <nav className="-mb-px flex space-x-8 flex-grow" aria-label="Tabs">
          {tabs.map((tab) => (
            <NavLink
              key={tab.name}
              to={tab.href}
              end
              className={({ isActive }) =>
                classNames(
                  isActive
                    ? 'border-dataops-secondary-blue text-dataops-secondary-blue'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                  'whitespace-nowrap py-4 px-1 border-b-2 font-[600] text-sm',
                )
              }
            >
              {tab.name}
            </NavLink>
          ))}
        </nav>
        <DateRangeSelector />
      </div>
    </div>
  );
};

export default PipelinesBar;
