import React from 'react';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

interface IExportCSVProps {
  data: any[];
  filename: string;
}

const ExportCSV = (props: IExportCSVProps): JSX.Element => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToExcel = (excelData: any[], fileName: string): void => {
    const ws = XLSX.utils.json_to_sheet(excelData);
    ws['!cols'] = [];
    for (let i = 0; i < Object.keys(excelData[0]).length; ++i) {
      ws['!cols'].push({ width: 30 });
    }
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName.toLowerCase() + fileExtension);
  };

  return (
    <button
      type="button"
      onClick={(e) => exportToExcel(props.data, props.filename)}
      className="rounded-md bg-cyan-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-cyan-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-600"
    >
      Export
    </button>
  );
};

export default ExportCSV;
