import React from 'react';
import PipelineRunsOverviewCard from 'features/pipelines/components/PipelineRunsOverviewCard';
import JobsOverviewCard from 'features/pipelines/components/JobsOverviewCard';
import CommitsOverviewCard from 'features/pipelines/components/CommitsOverviewCard';

export default function PipelineOverviewTab(): JSX.Element {
  if (process.env.REACT_APP_ENV === 'dev' || process.env.REACT_APP_ENV === 'qa') {
    return (
      <div className="w-full mt-[3.2rem] mx-auto py-6 px-dashboard-side bg-dashboard-background grid grid-cols-6 auto-rows-[8rem] xl:auto-rows-[7rem] gap-4">
        <PipelineRunsOverviewCard style="col-span-1 row-span-1" />
        <JobsOverviewCard style="col-span-1 row-span-1" />
        <CommitsOverviewCard style="col-span-1 row-span-1" />
      </div>
    );
  }

  return (
    <div className="w-full mx-auto bg-dashboard-background">
      <img className="w-full" src="/pipeline-overview-mockup.png" alt="" />
    </div>
  );
}
