import React, { useEffect } from 'react';
import StorageOverviewCard from 'features/finobs/components/StorageOverviewCard';
import StorageByTypeCard from 'features/finobs/components/StorageByTypeCard';
import StorageByTypeByDBCard from 'features/finobs/components/StorageByTypeByDBCard';
import StorageCostCard from 'features/finobs/components/StorageCostCard';
import StageCostCard from 'features/finobs/components/StageCostCard';
import FailsafeCostCard from 'features/finobs/components/FailsafeCostCard';
import { useAppDispatch } from 'app/hooks';
import { setShowError } from 'features/finobs/reducers/finobsSlice';

export default function FinObsStorageCostsTab(): JSX.Element {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setShowError(false));
  });

  return (
    <div className="w-full mt-[3.2rem] mx-auto py-6 px-dashboard-side bg-dashboard-background grid grid-cols-6 auto-rows-[9rem] xl:auto-rows-[7rem] gap-4">
      <StorageOverviewCard style="col-span-6 row-span-2" />
      <StorageByTypeCard style="col-span-3 row-span-3" />
      <StorageByTypeByDBCard isDetailsView={false} style="col-span-3 row-span-3" />
      <StorageCostCard isDetailsView={false} style="col-span-3 row-span-3" />
      <StageCostCard isDetailsView={false} style="col-span-3 col-start-4 row-span-2" />
      <FailsafeCostCard isDetailsView={false} style="col-span-3 col-start-4 row-span-2" />
    </div>
  );
}
