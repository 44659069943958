import React from 'react';
import { useTooltip, useTooltipInPortal, defaultStyles } from '@visx/tooltip';
import { localPoint } from '@visx/event';

const tooltipStyles = {
  ...defaultStyles,
  backgroundColor: '#4b5563',
  color: 'white',
  fontSize: 12,
  zIndex: 100,
  maxWidth: '200px',
};

interface TooltipData {
  description: string;
}

interface CardTitleProps {
  title: string;
  subtitleText: string;
  tooltipText: string;
}

const CardTitle = (props: CardTitleProps): JSX.Element => {
  const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } = useTooltip<TooltipData>();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });

  return (
    <>
      <div className="flex-grow font-[550] mb-1 cursor-default">
        <div
          className="text-primary-text"
          ref={containerRef}
          onMouseLeave={() => {
            hideTooltip();
          }}
          onMouseMove={(event) => {
            const eventSvgCoords = localPoint(event);
            showTooltip({
              tooltipData: { description: props.tooltipText },
              tooltipTop: eventSvgCoords?.y,
              tooltipLeft: eventSvgCoords?.x,
            });
          }}
        >
          {props.title}
        </div>
        <div className="text-secondary-text text-xs">{props.subtitleText}</div>
      </div>
      {tooltipOpen && tooltipData !== undefined && (
        <TooltipInPortal detectBounds={true} top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
          <div className="text-sm">{tooltipData.description}</div>
        </TooltipInPortal>
      )}
    </>
  );
};

export default CardTitle;
