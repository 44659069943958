import React from 'react';
import { useTooltip, useTooltipInPortal, defaultStyles } from '@visx/tooltip';
import { localPoint } from '@visx/event';

const tooltipStyles = {
  ...defaultStyles,
  backgroundColor: '#4b5563',
  color: 'white',
  fontSize: 12,
  zIndex: 100,
  maxWidth: 200,
};

interface AccountFormButtonProps {
  label: string;
  onClick: (() => void) | ((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void);
  disabled?: boolean;
  type?: string;
}

const AccountFormButton = (props: AccountFormButtonProps): JSX.Element => {
  const { tooltipOpen, tooltipLeft, tooltipTop, hideTooltip, showTooltip } = useTooltip();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });

  if (props.disabled === true) {
    return (
      <div
        className="w-full"
        ref={containerRef}
        onMouseLeave={() => {
          hideTooltip();
        }}
        onMouseMove={(event) => {
          const eventSvgCoords = localPoint(event);
          showTooltip({
            tooltipTop: eventSvgCoords?.y,
            tooltipLeft: eventSvgCoords?.x,
          });
        }}
      >
        <button
          type={props.type === 'submit' ? 'submit' : 'button'}
          className="bg-gray-500 group relative flex w-full justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white"
          onClick={(e) => props.onClick(e)}
          disabled
        >
          {props.label}
        </button>
        {tooltipOpen && (
          <TooltipInPortal top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
            <div className="text-sm">This snowflake account has been shared by a group admin and cannot be edited</div>
          </TooltipInPortal>
        )}
      </div>
    );
  }

  return (
    <button
      type={props.type === 'submit' ? 'submit' : 'button'}
      className="bg-dataops-secondary-blue hover:bg-hover-secondary-blue group relative flex w-full justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white"
      onClick={(e) => props.onClick(e)}
    >
      {props.label}
    </button>
  );
};

export default AccountFormButton;
