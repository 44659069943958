import React from 'react';
import SimpleOverviewCard from 'components/SimpleOverviewCard';
import Card from 'components/Card';
import { useAppSelector } from 'app/hooks';
import { selectSelectedAccounts, selectSelectedWarehouses, selectShowDollars } from '../reducers/finobsSlice';
import { selectStartDate, selectEndDate } from 'app/sharedSlice';
import { useGetAccountOverviewPeriodQuery } from 'app/spendviewApis';
import { classNames } from 'utils/styleUtils';

export interface IAccountOverviewPeriod {
  number_of_account: number;
  number_of_users: number;
  total_spent_percent_change: number;
  total_spend_per_period: number;
  avg_daily_spent_percent_change: number;
  avg_daily_spent: number;
  number_of_warehouses: number;
  total_credits_used: number;
  total_credits_used_trend: number;
}

const AccountOverviewPeriodCard = (props: { style: string }): JSX.Element => {
  const selectedAccounts = useAppSelector(selectSelectedAccounts);
  const selectedWarehouses = useAppSelector(selectSelectedWarehouses);
  const startDate = useAppSelector(selectStartDate);
  const endDate = useAppSelector(selectEndDate);
  const showDollars = useAppSelector(selectShowDollars);

  const { data, isFetching } = useGetAccountOverviewPeriodQuery({
    startDate,
    endDate,
    warehouses: selectedWarehouses,
    aggregate_accounts: true,
    aggregate_by_accounts: selectedAccounts,
    in_dollars: showDollars,
  });

  return (
    <Card style={classNames(props.style, 'p-0 rounded-md shadow-sm bg-dashboard-background flex space-x-1')}>
      <>
        <div className="flex-1 h-full space-y-1 flex flex-col">
          <SimpleOverviewCard
            label="Number of users"
            value={data?.number_of_users}
            isFetching={isFetching}
            tooltipText="Number of all users who have used the selected accounts over the chosen period"
          />
          <SimpleOverviewCard
            label="Number of warehouses"
            value={data?.number_of_warehouses}
            isFetching={isFetching}
            tooltipText="Number of warehouses used by the selected accounts over the chosen period"
          />
        </div>
        <div className="flex-1 h-full space-y-1 flex flex-col">
          <SimpleOverviewCard
            label="Number of accounts"
            value={data?.number_of_account}
            isFetching={isFetching}
            tooltipText="Number of all the active accounts you are monitoring in Unified Observability"
          />
          <SimpleOverviewCard
            label="Avg. daily spend"
            value={data?.avg_daily_spent}
            valuePrefix={showDollars ? '$' : ''}
            change={data?.avg_daily_spent_percent_change}
            isFetching={isFetching}
            tooltipText="Average dollars or credits spent per day for the selected accounts, billed over the chosen period (compute cost divided by the days set in the top right date field)"
          />
        </div>
        <div className="flex-1 h-full space-y-1 flex flex-col">
          <SimpleOverviewCard
            label="Credits used per period"
            value={data?.total_credits_used}
            change={data?.total_credits_used_trend}
            isFetching={isFetching}
            tooltipText="Credits spent across the warehouses in the selected accounts over the chosen period"
          />
          <SimpleOverviewCard
            label="Total spend per period"
            value={data?.total_spend_per_period}
            valuePrefix={showDollars ? '$' : ''}
            change={data?.total_spent_percent_change}
            isFetching={isFetching}
            tooltipText="Dollars or credits spent on compute and storage across the warehouses in the selected accounts over the chosen period"
          />
        </div>
      </>
    </Card>
  );
};

export default AccountOverviewPeriodCard;
