import React, { useMemo, useRef } from 'react';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import Card from 'components/Card';
import CardTitle from 'components/CardTitle';
import TotalUsersBarChart from './TotalUsersBarChart';
import DetailsButton from './DetailsButton';
import NoRecentActivity from 'components/NoRecentActivity';
import { useAppSelector } from 'app/hooks';
import { selectSelectedAccounts, selectSelectedWarehouses, selectShowDollars } from '../reducers/finobsSlice';
import { selectStartDate, selectEndDate, selectTimeframeOption } from 'app/sharedSlice';
import { useGetUsersPerAccountQuery } from 'app/spendviewApis';
import { getNumberOfBinsHeatmap, adjustStorageDates } from 'utils/dateUtils';

interface ITotalUsersOverTimeCardProps {
  style: string;
  isDetailsView: boolean;
}

const TotalUsersOverTimeCard = (props: ITotalUsersOverTimeCardProps): JSX.Element => {
  const selectedAccounts = useAppSelector(selectSelectedAccounts);
  const selectedWarehouses = useAppSelector(selectSelectedWarehouses);
  const startDate = useAppSelector(selectStartDate);
  const endDate = useAppSelector(selectEndDate);
  const showDollars = useAppSelector(selectShowDollars);
  const timeframeOption = useAppSelector(selectTimeframeOption);
  const windowWidth = useRef(window.innerWidth);
  const bins = useMemo(
    () => getNumberOfBinsHeatmap(timeframeOption, windowWidth.current, props.isDetailsView),
    [timeframeOption],
  );
  const { adjustedStartDate, adjustedEndDate } = useMemo(
    () => adjustStorageDates(startDate, endDate),
    [startDate, endDate],
  );

  const { data, isFetching, isError } = useGetUsersPerAccountQuery({
    startDate: adjustedStartDate,
    endDate: adjustedEndDate,
    warehouses: selectedWarehouses,
    bins,
    aggregate_accounts: true,
    aggregate_by_accounts: selectedAccounts,
    in_dollars: showDollars,
  });

  return (
    <Card style={props.style} isLoading={isFetching} isError={isError}>
      <div className="flex">
        <CardTitle
          title="Total number of users"
          subtitleText="Total number of accounts' users over time"
          tooltipText="Number of users logged in an used Snowflake accounts over the selected period"
        />
      </div>
      <div className="w-full h-[80%]">
        {data !== undefined && data.length !== 0 && (
          <ParentSize>
            {(parent) => <TotalUsersBarChart data={data} width={parent.width} height={parent.height} />}
          </ParentSize>
        )}
        {data !== undefined && data.length === 0 && !isFetching && !isError && <NoRecentActivity />}
      </div>
      <DetailsButton data={data ?? []} isTimeseriesArray={true} componentName="Users over time" />
    </Card>
  );
};

export default TotalUsersOverTimeCard;
