import React from 'react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import Card from 'components/Card';
import { useAppSelector } from 'app/hooks';
import { selectShowError, selectErrorMessage } from 'features/finobs/reducers/finobsSlice';

export default function SnowflakeCredentialsView(): JSX.Element {
  const navigate = useNavigate();
  const showError = useAppSelector(selectShowError);
  const errorMessage = useAppSelector(selectErrorMessage);

  return (
    <div className="h-full w-full overflow-y-auto flex justify-center bg-dashboard-background">
      <Card style="mt-8 max-w-[25rem]">
        <div>
          <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
            <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
          </div>
          {!showError && (
            <div className="mt-3 text-center sm:mt-5">
              <h3>No Snowflake accounts</h3>
              <div className="mt-2">
                <p className="text-sm text-gray-500">Please add a Snowflake account in Settings.</p>
              </div>
            </div>
          )}
          {showError && (
            <div className="mt-3 text-center sm:mt-5">
              <h3>Error while fetching data</h3>
              <div className="mt-2 bg-gray-100 round-md p-2">
                <p className="text-sm text-gray-500 text-left">{errorMessage}</p>
              </div>
            </div>
          )}
        </div>
        <div className="mt-5 sm:mt-6">
          <button
            type="button"
            onClick={() => navigate('/snowflake-accounts')}
            className="inline-flex w-full justify-center rounded-md border border-transparent bg-dataops-secondary-blue px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-hover-secondary-blue sm:text-sm"
          >
            Go to Settings
          </button>
        </div>
      </Card>
    </div>
  );
}
