import React, { useState, useEffect } from 'react';
import { Popover } from '@headlessui/react';
import { DateRangePicker, RangeKeyDict, StaticRange } from 'react-date-range';
import { isSameDay, differenceInDays } from 'date-fns';
import { formatCustomDate, getStartAndEndDate } from 'utils/dateUtils';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { selectTimeframeOption, setTimeframeOption, selectStartDate, selectEndDate } from 'app/sharedSlice';
import { setShowError } from 'features/finobs/reducers/finobsSlice';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

interface DateState {
  startDate: Date;
  endDate: Date;
  key: string;
}

const staticRangeHandler: { range: any; isSelected: (range: any) => boolean } = {
  range: {},
  isSelected(range: any): boolean {
    const definedRange = this.range();
    return isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate);
  },
};

export function createStaticRanges(ranges: any[]): StaticRange[] {
  return ranges.map((range: any) => ({ ...staticRangeHandler, ...range }));
}

export const defaultStaticRanges: StaticRange[] = createStaticRanges([
  {
    label: 'Last day',
    range: () => {
      const { startDate, endDate } = getStartAndEndDate(1);
      return {
        startDate,
        endDate,
      };
    },
  },
  {
    label: 'Last 7 days',
    range: () => {
      const { startDate, endDate } = getStartAndEndDate(7);
      return {
        startDate,
        endDate,
      };
    },
  },
  {
    label: 'Last 30 days',
    range: () => {
      const { startDate, endDate } = getStartAndEndDate(30);
      return {
        startDate,
        endDate,
      };
    },
  },
  {
    label: 'Last 60 days',
    range: () => {
      const { startDate, endDate } = getStartAndEndDate(60);
      return {
        startDate,
        endDate,
      };
    },
  },
  {
    label: 'Last 90 days',
    range: () => {
      const { startDate, endDate } = getStartAndEndDate(90);
      return {
        startDate,
        endDate,
      };
    },
  },
  {
    label: 'Last 180 days',
    range: () => {
      const { startDate, endDate } = getStartAndEndDate(180);
      return {
        startDate,
        endDate,
      };
    },
  },
  {
    label: 'Last 360 days',
    range: () => {
      const { startDate, endDate } = getStartAndEndDate(360);
      return {
        startDate,
        endDate,
      };
    },
  },
]);

const DateRangeSelector = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const timeframeOption = useAppSelector(selectTimeframeOption);
  const startDate = useAppSelector(selectStartDate);
  const endDate = useAppSelector(selectEndDate);
  const [state, setState] = useState<DateState[]>([
    {
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      key: 'selection',
    },
  ]);

  useEffect(() => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    setState([{ startDate: start, endDate: end, key: 'selection' }]);
  }, [startDate, endDate]);

  const handleSelect = (range: RangeKeyDict): void => {
    dispatch(setShowError(false));
    const today = new Date();
    if (!isSameDay(today, range.selection.endDate as Date)) {
      const [startMonth, startDay, startYear] = [
        (range.selection.startDate ?? today).getMonth(),
        (range.selection.startDate ?? today).getDate(),
        (range.selection.startDate ?? today).getFullYear(),
      ];
      const [endMonth, endDay, endYear] = [
        (range.selection.endDate ?? today).getMonth(),
        (range.selection.endDate ?? today).getDate(),
        (range.selection.endDate ?? today).getFullYear(),
      ];
      const adjustedStartDate = new Date(Date.UTC(startYear, startMonth, startDay, 0, 0));
      const adjustedEndDate = new Date(Date.UTC(endYear, endMonth, endDay, 23, 59));
      dispatch(
        setTimeframeOption({
          timeframeOption: 'Custom',
          startDate: adjustedStartDate.toISOString(),
          endDate: adjustedEndDate.toISOString(),
        }),
      );
      return;
    }
    const difference = differenceInDays(range.selection.endDate as Date, range.selection.startDate as Date);
    let customStartDate;
    let customEndDate;
    let timeframeOption = 'Custom';
    switch (difference) {
      case 1:
        timeframeOption = 'Last day';
        break;
      case 7:
        timeframeOption = 'Last 7 days';
        break;
      case 30:
        timeframeOption = 'Last 30 days';
        break;
      case 60:
        timeframeOption = 'Last 60 days';
        break;
      case 90:
        timeframeOption = 'Last 90 days';
        break;
      case 180:
        timeframeOption = 'Last 180 days';
        break;
      case 360:
        timeframeOption = 'Last 360 days';
        break;
      default:
        timeframeOption = 'Custom';
        customStartDate = range.selection.startDate;
        customEndDate = range.selection.endDate;
    }
    dispatch(
      setTimeframeOption({
        timeframeOption,
        startDate: customStartDate?.toISOString(),
        endDate: customEndDate?.toISOString(),
      }),
    );
  };

  return (
    <Popover className="relative">
      <Popover.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-dark-card-background py-1 px-3 text-left shadow-sm focus:border-gray-500 focus:outline-none sm:text-xs">
        {timeframeOption === 'Custom'
          ? `${formatCustomDate(startDate)} - ${formatCustomDate(endDate)}`
          : timeframeOption}
      </Popover.Button>

      <Popover.Panel className="absolute z-10 right-0 top-[2.6rem] border-[1px]">
        <div>
          <DateRangePicker
            onChange={handleSelect}
            ranges={state}
            staticRanges={defaultStaticRanges}
            moveRangeOnFirstSelection={true}
            rangeColors={['#0891b2']}
          />
        </div>
      </Popover.Panel>
    </Popover>
  );
};

export default DateRangeSelector;
