import React from 'react';
import DataProductsBar from 'features/data-products/navigation/DataProductsBar';
import ProductionCostCard from 'features/data-products/components/ProductionCostCard';
import ActiveUsersCard from 'features/data-products/components/ActiveUsersCard';
import DataProductHealthCard from 'features/data-products/components/DataProductHealthCard';
import TopDataProductsCard from 'features/data-products/components/TopDataProductsCard';
import BusinessValueCard from 'features/data-products/components/BusinessValueCard';
import TotalCostOverTimeCard from 'features/data-products/components/TotalCostOverTimeCard';
import DataProductAnalysisCard from 'features/data-products/components/DataProductAnalysisCard';
import TopDataProductUsageCard from 'features/data-products/components/TopDataProductUsageCard';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';

export default function DataProductsView(): JSX.Element {
  return (
    <>
      <div className="h-full min-w-0 flex-1 overflow-y-auto lg:order-last">
        <DataProductsBar />
        <div className="rounded-md bg-yellow-50 p-4 mt-[3.2rem]">
          <div className="flex">
            <div className="flex-shrink-0">
              <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-yellow-800">Attention needed</h3>
              <div className="mt-2 text-sm text-yellow-700">
                <p>
                  The Data Products tab showcases the upcoming DataOps.live Data Products observability component with
                  sample data. Participate in the private preview and provide feedback. To sign up, email
                  <a
                    className="underline text-yellow-800 hover:text-yellow-700"
                    href="mailto: privatepreview@dataops.live"
                  >
                    {' '}
                    privatepreview@dataops.live
                  </a>
                  . We are looking forward to hearing from you!
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full mx-auto py-6 px-dashboard-side bg-dashboard-background grid grid-cols-9 auto-rows-[11.5rem] xl:auto-rows-[11rem] gap-4">
          <ProductionCostCard style="col-span-2" />
          <ActiveUsersCard style="col-span-2 " />
          <DataProductHealthCard style="col-span-2" />
          <TopDataProductsCard style="col-span-3 row-span-4" />
          <BusinessValueCard style="col-span-6 row-start-2 row-span-3 " />
          <DataProductAnalysisCard style="col-span-4 col-start-1 row-start-8 row-span-2" />
          <TopDataProductUsageCard style="col-span-5 col-start-5 row-start-5 row-span-2" />
          <TotalCostOverTimeCard style="col-span-9 row-start-8 row-span-2" />
        </div>
      </div>
    </>
  );
}
