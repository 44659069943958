import React, { useEffect } from 'react';
import OverviewCard from 'features/finobs/components/OverviewCard';
import CostPerWarehouseCard from 'features/finobs/components/CostPerWarehouseCard';
import UsagePerWarehouseTimeseriesCard from 'features/finobs/components/UsagePerWarehouseTimeseriesCard';
import UsagePerWarehouseHeatmapCard from 'features/finobs/components/UsagePerWarehouseHeatmapCard';
import WarehouseActivityCard from 'features/finobs/components/WarehouseActivityCard';
import CostTimeseriesCard from 'features/finobs/components/CostTimeseriesCard';
import WarehouseSizeCard from 'features/finobs/components/WarehouseSizeCard';
import { useAppDispatch } from 'app/hooks';
import { setShowError } from 'features/finobs/reducers/finobsSlice';

export default function FinObsComputeTab(): JSX.Element {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setShowError(false));
  });

  return (
    <div className="w-full mt-[3.2rem] mx-auto py-6 px-dashboard-side bg-dashboard-background grid grid-cols-6 auto-rows-[8rem] xl:auto-rows-[7rem] gap-4">
      <OverviewCard style="col-span-6 row-span-2" />
      <CostPerWarehouseCard isDetailsView={false} style="col-span-3 row-span-3" />
      <UsagePerWarehouseHeatmapCard isDetailsView={false} style="col-span-3 row-start-3 row-span-3" />
      <UsagePerWarehouseTimeseriesCard isDetailsView={false} style="col-span-6 row-span-3" />
      <WarehouseActivityCard style="col-span-2 row-span-3" />
      <CostTimeseriesCard style="col-span-4 col-start-3 row-span-3" />
      <WarehouseSizeCard style="col-span-2 row-span-3" />
    </div>
  );
}
