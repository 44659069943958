import React, { useEffect, useState, useRef, useMemo } from 'react';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import Card from 'components/Card';
import CardTitle from 'components/CardTitle';
import TotalComputeAndStorageCostBarChart from './TotalComputeAndStorageCostBarChart';
import DetailsButton from './DetailsButton';
import NoRecentActivity from 'components/NoRecentActivity';
import Dropdown from 'components/Dropdown';
import { useAppSelector } from 'app/hooks';
import { selectSelectedAccounts, selectSelectedWarehouses, selectShowDollars } from '../reducers/finobsSlice';
import { selectStartDate, selectEndDate, selectTimeframeOption } from 'app/sharedSlice';
import { useGetCostTimeseriesQuery, useGetStorageSpendQuery } from 'app/spendviewApis';
import { getNumberOfBinsHeatmap, getNumberOfDays } from 'utils/dateUtils';

const defaultCostTypeOptions = ['Compute and storage', 'Compute only', 'Storage only'];
export interface ITotalComputeAndStorageCost {
  date: string;
  computeCost: number;
  storageCost: number;
}

interface ITotalComputeAndStorageCostCardProps {
  style: string;
  isDetailsView: boolean;
}

const TotalComputeAndStorageCostCard = (props: ITotalComputeAndStorageCostCardProps): JSX.Element => {
  const [costTypeOptions, setCostTypeOptions] = useState<string[]>(defaultCostTypeOptions);
  const [costType, setCostType] = useState(defaultCostTypeOptions[0]);
  const [processedData, setProcessedData] = useState<ITotalComputeAndStorageCost[]>([]);
  const selectedAccounts = useAppSelector(selectSelectedAccounts);
  const selectedWarehouses = useAppSelector(selectSelectedWarehouses);
  const startDate = useAppSelector(selectStartDate);
  const endDate = useAppSelector(selectEndDate);
  const showDollars = useAppSelector(selectShowDollars);
  const timeframeOption = useAppSelector(selectTimeframeOption);
  const windowWidth = useRef(window.innerWidth);
  const bins = useMemo(
    () => getNumberOfBinsHeatmap(timeframeOption, windowWidth.current, props.isDetailsView),
    [timeframeOption],
  );
  const numberOfDays = useMemo(() => getNumberOfDays(startDate, endDate), [startDate, endDate]);

  const {
    data: computeData,
    isFetching: isComputeFetching,
    isError: isComputeError,
  } = useGetCostTimeseriesQuery({
    startDate,
    endDate,
    bins,
    warehouses: selectedWarehouses,
    aggregate_accounts: true,
    aggregate_by_accounts: selectedAccounts,
    in_dollars: showDollars,
  });

  const {
    data: storageData,
    isFetching: isStorageFetching,
    isError: isStorageError,
  } = useGetStorageSpendQuery(
    {
      startDate,
      endDate,
      bins,
      forecast_bins: 1,
      warehouses: selectedWarehouses,
      aggregate_accounts: true,
      aggregate_by_accounts: selectedAccounts,
      in_dollars: showDollars,
    },
    { skip: numberOfDays < 30 },
  );

  useEffect(() => {
    if (numberOfDays < 30) {
      setCostTypeOptions(['Compute only']);
      setCostType('Compute only');
    } else {
      setCostTypeOptions(defaultCostTypeOptions);
      setCostType(defaultCostTypeOptions[0]);
    }
  }, [numberOfDays]);

  useEffect(() => {
    if (numberOfDays < 30 && computeData !== undefined && computeData.length !== 0) {
      const tmpProcessedData: ITotalComputeAndStorageCost[] = [];
      if (costType === 'Compute only') {
        computeData.forEach((compute, index) => {
          tmpProcessedData.push({
            date: compute.date,
            computeCost: compute.value,
            storageCost: 0,
          });
        });
        setProcessedData(tmpProcessedData);
      }
    }
  }, [computeData, storageData, costType, numberOfDays]);

  useEffect(() => {
    if (numberOfDays >= 30 && computeData !== undefined && storageData !== undefined && computeData.length !== 0) {
      const tmpProcessedData: ITotalComputeAndStorageCost[] = [];
      if (costType === 'Compute and storage') {
        if (timeframeOption === 'Last day' && storageData.length === 0) {
          computeData.forEach((compute, index) => {
            tmpProcessedData.push({
              date: compute.date,
              computeCost: compute.value,
              storageCost: 0,
            });
          });
          setProcessedData(tmpProcessedData);
        } else if (storageData.length !== 0 && computeData.length === storageData[0].timeseries.length) {
          const storageTimeseries =
            storageData[0].category === 'usage' ? storageData[0].timeseries : storageData[1].timeseries;
          computeData.forEach((compute, index) => {
            tmpProcessedData.push({
              date: compute.date,
              computeCost: compute.value,
              storageCost: storageTimeseries[index].value,
            });
          });
          setProcessedData(tmpProcessedData);
        }
      } else if (costType === 'Compute only') {
        computeData.forEach((compute, index) => {
          tmpProcessedData.push({
            date: compute.date,
            computeCost: compute.value,
            storageCost: 0,
          });
        });
        setProcessedData(tmpProcessedData);
      } else if (costType === 'Storage only') {
        if (storageData.length !== 0) {
          const storageTimeseries =
            storageData[0].category === 'usage' ? storageData[0].timeseries : storageData[1].timeseries;
          computeData.forEach((compute, index) => {
            tmpProcessedData.push({
              date: compute.date,
              computeCost: 0,
              storageCost: storageTimeseries[index].value,
            });
          });
          setProcessedData(tmpProcessedData);
        } else {
          setProcessedData([]);
        }
      }
    }
  }, [computeData, storageData, costType, numberOfDays]);

  return (
    <Card
      style={props.style}
      isLoading={isComputeFetching || isStorageFetching}
      isError={isComputeError || isStorageError}
    >
      <div className="flex">
        <CardTitle
          title="Compute and storage spend breakdown"
          subtitleText="Changes in accounts' spending on compute and storage over time"
          tooltipText="On top right, select what spend type to calculate and show"
        />
        <Dropdown options={costTypeOptions} value={costType} onChange={setCostType} />
      </div>
      <div className="w-full h-[70%]">
        {processedData.length !== 0 && (
          <ParentSize>
            {(parent) => (
              <TotalComputeAndStorageCostBarChart data={processedData} width={parent.width} height={parent.height} />
            )}
          </ParentSize>
        )}
        {processedData.length === 0 &&
          !isComputeFetching &&
          !isStorageFetching &&
          !isComputeError &&
          !isStorageError && <NoRecentActivity />}
      </div>
      <DetailsButton data={processedData} isTimeseriesArray={false} componentName="Total compute and storage" />
    </Card>
  );
};

export default TotalComputeAndStorageCostCard;
