import React from 'react';
import useSnowflakeCredentials from '../hooks/useSnowflakeCredentials';
import MissingSnowflakeCredentialsView from 'views/MissingSnowflakeCredentialsView';
import LoadingView from 'views/LoadingView';

interface SnowflakeRequiredRouteProps {
  children: JSX.Element;
}

export default function SnowflakeRequiredRoute(props: SnowflakeRequiredRouteProps): JSX.Element {
  const { hasSnowflakeCredentials, loading } = useSnowflakeCredentials();

  if (loading && !hasSnowflakeCredentials) {
    return <LoadingView />;
  }

  if (!hasSnowflakeCredentials) {
    return <MissingSnowflakeCredentialsView />;
  }

  return props.children;
}
