import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAppDispatch } from 'app/hooks';
import {
  setTabs,
  // setDetailsDialogOpen,
  // setDetailsDialogContent,
  // setDetailsDialogIsTimeseriesArray,
} from '../reducers/finobsSlice';

interface DetailsDialogTableProps {
  data: any[];
  isTimeseriesArray: boolean;
  componentName: string;
}

const DetailsButton = (props: DetailsDialogTableProps): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // Don't render the details button on the details page
  if (location.pathname.includes('/details/')) {
    return <></>;
  }

  return (
    <div className="absolute bottom-[0.5rem] right-[1rem]">
      <button
        type="button"
        onClick={() => {
          // dispatch(setDetailsDialogOpen(true));
          // dispatch(setDetailsDialogContent(props.data));
          // dispatch(setDetailsDialogIsTimeseriesArray(props.isTimeseriesArray));
          const detailsUrl = `/finobs/details/${props.componentName.toLowerCase().replaceAll(' ', '-')}`;
          dispatch(
            setTabs([
              { name: 'Overview', href: '/finobs' },
              { name: 'Compute', href: '/finobs/compute-costs' },
              { name: 'Storage', href: '/finobs/storage-costs' },
              { name: 'Usage', href: '/finobs/usage-costs' },
              {
                name: props.componentName,
                href: detailsUrl,
              },
            ]),
          );
          navigate(detailsUrl);
        }}
        className="text-xs text-cyan-700 hover:text-cyan-900"
      >
        {/* <div className="w-full text-sm align-center">i</div> */}
        <div>Show details</div>
      </button>
    </div>
  );
};

export default DetailsButton;
