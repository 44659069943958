import React from 'react';
import { scaleOrdinal } from 'd3';
import Pie from '@visx/shape/lib/shapes/Pie';
import { Group } from '@visx/group';
import { useTooltip, useTooltipInPortal, defaultStyles } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import { ITimeseriesElement } from 'utils/types';
import { formatNumberLong, truncateString } from 'utils/formatUtils';
import useShowDollars from '../hooks/useShowDollar';
import { dataopsColorScale } from 'utils/colors';

const margin = {
  left: 0,
  right: 0,
  bottom: 0,
  top: 20,
};

export interface IDonutChartData {
  category: string;
  timeseries: ITimeseriesElement[];
  percent: number;
}

interface UsagePerUserDonutChartProps {
  data: IDonutChartData[];
  tooltipLabel: string;
  width: number;
  height: number;
}

interface TooltipData {
  category: string;
  timeseries: ITimeseriesElement[];
  percent: number;
}

const tooltipStyles = {
  ...defaultStyles,
  backgroundColor: '#27262c',
  color: 'white',
  fontSize: 12,
};

const UsagePerUserDonutChart = (props: UsagePerUserDonutChartProps): JSX.Element => {
  const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } = useTooltip<TooltipData>();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });
  const showDollars = useShowDollars();

  // const colorScale = scaleOrdinal(schemeTableau10);
  const colorScale = scaleOrdinal<string>()
    .domain(props.data.map((d) => d.category))
    .range(dataopsColorScale);

  const innerWidth = props.width - margin.left - margin.right;
  const innerHeight = props.height - margin.top - margin.bottom;
  const radius = Math.min(innerWidth, innerHeight) / 2;
  const centerY = innerHeight / 2;
  const centerX = innerWidth / 2;
  const donutThickness = 45;

  return (
    <svg ref={containerRef} width={props.width} height={props.height}>
      <Group top={centerY + margin.top} left={centerX + margin.left}>
        <Pie
          data={props.data}
          pieValue={(d: any) => d.timeseries[0].value}
          outerRadius={radius}
          innerRadius={radius - donutThickness}
          cornerRadius={3}
          padAngle={0.005}
        >
          {(pie) => {
            return (
              <Group className="visx-pie-arcs-group">
                {pie.arcs.map((arc, i) => {
                  const [centroidX, centroidY] = pie.path.centroid(arc);
                  return (
                    <g
                      key={`pie-arc-${i}`}
                      onMouseLeave={() => {
                        hideTooltip();
                      }}
                      onMouseMove={(event) => {
                        const eventSvgCoords = localPoint(event);
                        showTooltip({
                          tooltipData: arc.data,
                          tooltipTop: eventSvgCoords?.y,
                          tooltipLeft: eventSvgCoords?.x,
                        });
                      }}
                    >
                      <path d={pie.path(arc) ?? ''} fill={colorScale(arc.data.category)} />
                      <text
                        x={centroidX}
                        y={centroidY}
                        dy=".33em"
                        fill="#ffffff"
                        fontSize={14}
                        textAnchor="middle"
                        pointerEvents="none"
                      >
                        {arc.data.percent >= 10
                          ? `${arc.data.percent.toLocaleString('en-US', {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 1,
                            })}%`
                          : ''}
                      </text>
                    </g>
                  );
                })}
              </Group>
            );
          }}
        </Pie>
        {tooltipOpen && tooltipData !== undefined && (
          <TooltipInPortal top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
            <div className="flex">
              <div className="pr-2">{props.tooltipLabel}: </div>
              <div>{truncateString(tooltipData.category ?? 'null', 25)}</div>
            </div>
            <div className="flex">
              <div className="pr-2">Percent: </div>
              <div>{formatNumberLong(tooltipData.percent)}%</div>
            </div>
            <div className="flex">
              <div className="pr-2">{showDollars ? 'Cost: ' : 'Credits: '}</div>
              <div>
                {showDollars ? '$' : ''}
                {formatNumberLong(tooltipData.timeseries[0].value)}
              </div>
            </div>
          </TooltipInPortal>
        )}
      </Group>
    </svg>
  );
};

export default UsagePerUserDonutChart;
