import React from 'react';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';

export default function LineageView(): JSX.Element {
  return (
    <div>
      <div className="w-full rounded-md bg-yellow-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-yellow-800">Attention needed</h3>
            <div className="mt-2 text-sm text-yellow-700">
              <p>
                The Impact Assessment tab showcases the upcoming DataOps.live Impact Assessment component. Participate
                in the private preview and provide feedback. To sign up, email
                <a
                  className="underline text-yellow-800 hover:text-yellow-700"
                  href="mailto: privatepreview@dataops.live"
                >
                  {' '}
                  privatepreview@dataops.live
                </a>
                . We are looking forward to hearing from you!
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full mx-auto bg-dashboard-background">
        <img className="w-full" src="/lineage-mockup.png" alt="" />
      </div>
    </div>
  );
}
