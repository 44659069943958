import React from 'react';
import { Outlet } from 'react-router-dom';
import PipelinesBar from 'features/pipelines/navigation/PipelinesBar';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';

export default function PipelinesView(): JSX.Element {
  return (
    <>
      <div className="w-full h-full min-w-0 flex-1 overflow-y-auto lg:order-last">
        <PipelinesBar />
        {process.env.REACT_APP_ENV !== 'dev' && process.env.REACT_APP_ENV !== 'qa' && (
          <div className="w-full rounded-md bg-yellow-50 p-4 mt-[3.2rem]">
            <div className="flex">
              <div className="flex-shrink-0">
                <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-yellow-800">Attention needed</h3>
                <div className="mt-2 text-sm text-yellow-700">
                  <p>
                    The Pipeline Monitoring tab showcases the upcoming DataOps.live Pipeline Monitoring component.
                    Participate in the private preview and provide feedback. To sign up, email
                    <a
                      className="underline text-yellow-800 hover:text-yellow-700"
                      href="mailto: privatepreview@dataops.live"
                    >
                      {' '}
                      privatepreview@dataops.live
                    </a>
                    . We are looking forward to hearing from you!
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
        <Outlet />
      </div>
    </>
  );
}
