import React, { useLayoutEffect } from 'react';
import * as d3 from 'd3';
import { PieArcDatum } from 'd3';

interface RoleData {
  roleName: string;
  usersCount: number;
  percentage: number;
  color: string;
}

interface ActiveUsersDonutChartProps {
  data: RoleData[];
  total: number;
  width: number;
  height: number;
}

const ActiveUsersDonutChart = (props: ActiveUsersDonutChartProps): JSX.Element => {
  const d3Reference: React.RefObject<HTMLDivElement> = React.createRef();

  useLayoutEffect(() => {
    const radius = Math.min(props.width, props.height) / 2;

    // var color = d3.scaleOrdinal(d3.schemeCategory10);
    const color = d3
      .scaleOrdinal()
      .domain(props.data.map((d: RoleData) => d.roleName))
      .range(['#00436c', '#006995', '#16a7d7', '#5ed3ff']);

    d3.select(d3Reference.current).selectAll('*').remove();

    const svg = d3
      .select(d3Reference.current)
      .append('svg')
      .attr('width', 2 * radius)
      .attr('height', 2 * radius)
      .append('g')
      .attr('transform', `translate(${radius}, ${radius})`);

    const arc = d3
      .arc<PieArcDatum<RoleData>>()
      .innerRadius(0.8 * radius)
      .outerRadius(radius);

    const pie = d3
      .pie<RoleData>()
      .value(function (d) {
        return d.usersCount;
      })
      .sort(null);

    svg
      .selectAll('path')
      .data(pie(props.data))
      .enter()
      .append('path')
      .attr('d', arc)
      .attr('fill', function (d: PieArcDatum<RoleData>) {
        return color(d.data.roleName) as string;
      });
  });

  return (
    <div className="flex-1 h-full flex items-center justify-center">
      <div className="w-full h-full flex items-center justify-center" ref={d3Reference}></div>
      <div className=" absolute text-center">
        <div className="text-3xl text-primary-text">{Math.round(props.total)}</div>
        <div className="text-xs text-secondary-text">last 7d</div>
      </div>
    </div>
  );
};

export default ActiveUsersDonutChart;
