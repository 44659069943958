import React from 'react';
import Card from 'components/Card';
import { IDataProduct } from './TopDataProductsCard';
import { formatNumberLong } from 'utils/formatUtils';

const DataProductCard = (props: IDataProduct): JSX.Element => {
  const date = new Date(props.last_refreshed);
  const dateString = `${date.toLocaleString('en-US', { day: 'numeric' })}, ${date.toLocaleString('en-US', {
    month: 'short',
  })}; ${date.toLocaleTimeString('en-GB')}`;

  return (
    <Card style="bg-dark-card-background flex flex-col space-y-4">
      <div className="w-full flex items-center">
        <div id="data-product-name" className="flex-1 text-lg text-primary-text">
          {props.name}
        </div>
        <div className="pl-1 flex-1 text-xs text-secondary-text">
          Last refreshed: <span className="text-primary-text font-medium">{dateString}</span>
        </div>
      </div>
      <div className="w-full flex">
        <div className="flex-1">
          <div className="text-2xl text-primary-text">${formatNumberLong(props.instance_cost)}</div>
          <div className="text-xs text-secondary-text">Instance cost</div>
        </div>
        <div className="flex-1">
          <div id="credits-produce" className="text-2xl text-primary-text">
            {formatNumberLong(props.credits_produce)}{' '}
            <span
              id="credits-produce-change"
              className={`text-sm ${props.credits_produce_change < 0 ? 'text-red-highlight' : 'text-green-highlight'}`}
            >
              {formatNumberLong(props.credits_produce_change)}%
            </span>
          </div>
          <div className="text-xs text-secondary-text">Credits to produce</div>
        </div>
        <div className="flex-1">
          <div id="credits-usage" className="text-2xl text-primary-text">
            {formatNumberLong(props.credits_usage)}{' '}
            <span
              id="credits-usage-change"
              className={`text-sm ${props.credits_usage_change < 0 ? 'text-red-highlight' : 'text-green-highlight'}`}
            >
              {formatNumberLong(props.credits_usage_change)}%
            </span>
          </div>
          <div className="text-xs text-secondary-text">Usage credits</div>
        </div>
      </div>
    </Card>
  );
};

export default DataProductCard;
