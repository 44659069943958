import React, { useEffect, useState } from 'react';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import Card from 'components/Card';
import CardTitle from 'components/CardTitle';
import WarehouseActivityPieChart from './WarehouseActivityPieChart';
import WarehouseActivityPieChartLegend from './WarehouseActivityPieChartLegend';
import WarehouseActivityBarChart from './WarehouseActivityBarChart';
import DetailsButton from './DetailsButton';
import NoRecentActivity from 'components/NoRecentActivity';
import { useAppSelector } from 'app/hooks';
import { selectSelectedAccounts, selectSelectedWarehouses, selectShowDollars } from '../reducers/finobsSlice';
import { selectStartDate, selectEndDate } from 'app/sharedSlice';
import { useGetTotalTimeQuery } from 'app/spendviewApis';
import { ICategoryWithPercent } from 'utils/types';
import themeColors from 'utils/colors';

const colors = {
  SUCCESS: themeColors.dataopsDarkGreen,
  FAIL: themeColors.dataopsRed,
  INCIDENT: '#000000',
};

export interface IWarehouseActivity {
  active_warehouses: number;
  inactive_warehouses: number;
}

const UsageErrorCard = (props: { style: string }): JSX.Element => {
  const [processedData, setProcessedData] = useState<ICategoryWithPercent[]>([]);
  const selectedAccounts = useAppSelector(selectSelectedAccounts);
  const selectedWarehouses = useAppSelector(selectSelectedWarehouses);
  const startDate = useAppSelector(selectStartDate);
  const endDate = useAppSelector(selectEndDate);
  const showDollars = useAppSelector(selectShowDollars);

  const { data, isFetching, isError } = useGetTotalTimeQuery({
    startDate,
    endDate,
    aggregate_accounts: true,
    aggregate_by_accounts: selectedAccounts,
    warehouses: selectedWarehouses,
    in_dollars: showDollars,
  });

  useEffect(() => {
    if (data !== undefined && data.count > 1) {
      const tmpProcessedData: ICategoryWithPercent[] = [];
      const total = data.items.reduce((sum, item) => sum + item.value, 0);
      data.items.forEach((item) => {
        tmpProcessedData.push({ ...item, percent: (item.value / total) * 100 });
      });
      setProcessedData(tmpProcessedData);
    }
  }, [data]);

  return (
    <Card style={props.style} isLoading={isFetching} isError={isError}>
      <div className="flex">
        <CardTitle
          title="Total runtime ratio"
          subtitleText="Percentage distribution of successful and failed querries over the selected period"
          tooltipText="On bottom right, click Show details to see detailed information about querries"
        />
      </div>
      <div className="w-full h-[75%] flex">
        {processedData.length !== 0 && (
          <>
            <div className="h-full w-1/2">
              <div className="h-1/3">
                <WarehouseActivityPieChartLegend data={processedData} colors={colors} />
              </div>
              <div className="h-2/3">
                <ParentSize>
                  {(parent) => (
                    <WarehouseActivityPieChart
                      data={processedData}
                      colors={colors}
                      valueLabel={showDollars ? 'Cost' : 'Credits'}
                      valueUnit={showDollars ? '$' : ''}
                      width={parent.width}
                      height={parent.height}
                    />
                  )}
                </ParentSize>
              </div>
            </div>
            <div className="h-full w-1/2">
              <ParentSize>
                {(parent) => (
                  <WarehouseActivityBarChart
                    data={processedData}
                    colors={colors}
                    valueLabel={showDollars ? 'Cost' : 'Credits'}
                    valueUnit={showDollars ? '$' : ''}
                    width={parent.width}
                    height={parent.height}
                  />
                )}
              </ParentSize>
            </div>
          </>
        )}
        {processedData.length === 0 && !isFetching && !isError && <NoRecentActivity />}
      </div>
      <DetailsButton data={processedData} isTimeseriesArray={false} componentName="Usage error" />
    </Card>
  );
};

export default UsageErrorCard;
