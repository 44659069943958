import React, { useEffect, useState } from 'react';
import SnowflakeAccountCard from 'features/snowflake-accounts/components/SnowflakeAccountCard';
import SnowflakeAccountsBar from 'features/snowflake-accounts/navigation/SnowflakeAccountsBar';
import SnowflakeCredentialsForm from 'features/authentication/components/SnowflakeCredentialsForm';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { fetchAccounts, selectAccounts } from 'features/finobs/reducers/finobsSlice';

export interface ISnowflakeAccount {
  account: string;
  username: string;
  warehouse: string;
  role: string;
  persist: boolean;
  is_active: boolean;
  compute_coefficient: number;
  storage_coefficient: number;
  id: number;
  client_id: string;
  token_url: string;
  is_group_account: boolean;
  is_editable: boolean;
}

export default function SnowflakeAccountsView(): JSX.Element {
  const dispatch = useAppDispatch();
  const accounts = useAppSelector(selectAccounts);
  const [accountDialogOpen, setAccountDialogOpen] = useState(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState('Update failed');

  useEffect(() => {
    dispatch(fetchAccounts()).catch((err) => err);
  }, [accountDialogOpen]);

  return (
    <>
      <div className="h-full min-w-0 flex-1">
        <SnowflakeAccountsBar setAccountDialogOpen={setAccountDialogOpen} />
        {showError && (
          <>
            <div className="fixed top-[7.2rem] left-16 right-0 z-10 rounded-md bg-red-50 p-4">
              <div className="flex w-full">
                <div className="ml-3 w-full">
                  <div className="flex">
                    <h3 className="flex-grow text-sm font-medium text-red-800">Update error</h3>
                    <button
                      type="button"
                      onClick={() => setShowError(false)}
                      className="h-5 w-5 mr-4 inline-flex items-center rounded-full text-red-800 hover:bg-red-100"
                    >
                      <XMarkIcon className="w-full" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="w-full text-sm text-red-800">{errorMessage}</div>
                </div>
              </div>
            </div>
            <div className="mt-[7.5rem]"></div>
          </>
        )}
        <div className="w-full h-full min-w-0 mt-[3.2rem] sm:p-6 lg:p-8 bg-dashboard-background">
          <div className="grid grid-cols-1 gap-10 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 wide:grid-cols-4 auto-rows-[24rem]">
            {accounts.map((account) => (
              <SnowflakeAccountCard
                key={account.account}
                data={account}
                setShowError={setShowError}
                setErrorMessage={setErrorMessage}
              />
            ))}
          </div>
        </div>
      </div>
      <SnowflakeCredentialsForm accountDialogOpen={accountDialogOpen} setAccountDialogOpen={setAccountDialogOpen} />
    </>
  );
}
