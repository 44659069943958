import React, { useLayoutEffect } from 'react';
import * as d3 from 'd3';

interface DataQualityBarProps {
  name: string;
  percentage: number;
  expectedPercentage: number;
  colorScheme: string;
  width: number;
  height: number;
}

const DataQualityBar = (props: DataQualityBarProps): JSX.Element => {
  const d3Ref: React.RefObject<HTMLDivElement> = React.createRef();

  const data = [
    { percentage: 100, color: props.colorScheme === 'yellow' ? '#daf2e7' : '#e3f3e1' },
    { percentage: props.expectedPercentage, color: props.colorScheme === 'yellow' ? '#fddeb5' : '#fddeb5' },
    { percentage: props.percentage, color: props.colorScheme === 'yellow' ? '#f79009' : '#46be88' },
  ];

  useLayoutEffect(() => {
    const margin = 2;
    const height = d3Ref.current?.getBoundingClientRect().height ?? 0;

    d3.select(d3Ref.current).selectAll('*').remove();

    const svg = d3.select(d3Ref.current).append('svg').attr('width', props.width).attr('height', height);

    const x = d3.scaleLinear().domain([0, 100]).range([0, props.width]);

    svg
      .selectAll('rect')
      .data(data)
      .join('rect')
      .attr('x', 0)
      .attr('y', margin)
      .attr('width', function (d) {
        return x(d.percentage);
      })
      .attr('height', height - 2 * margin)
      .attr('fill', function (d) {
        return d.color;
      })
      .attr('rx', (height - margin) / 2)
      .attr('ry', (height - margin) / 2);

    svg
      .selectAll('line')
      .data([data[1]])
      .join('line')
      .attr('stroke', 'black')
      .attr('stroke-width', '1px')
      // .attr('stroke-dasharray', '2, 2')
      .attr('x1', function (d) {
        return x(d.percentage);
      })
      .attr('y1', 0)
      .attr('x2', function (d) {
        return x(d.percentage);
      })
      .attr('y1', height);
  });

  return (
    <div className="flex-grow space-y-1 flex flex-col justify-center">
      <div className="flex justify-between">
        <div className="text-xs text-secondary-text">{props.name}</div>
        <div className="text-xs text-primary-text font-medium">{props.percentage}%</div>
      </div>
      <div className="w-full h-4" ref={d3Ref}></div>
    </div>
  );
};

export default DataQualityBar;
