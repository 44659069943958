import React, { useEffect, useState, useMemo } from 'react';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import { ITimeseries, ITimeseriesElement } from 'utils/types';
import Card from 'components/Card';
import CardTitle from 'components/CardTitle';
import UsagePerWarehouseTimeseriesChart from './UsagePerWarehouseTimeseriesChart';
import Dropdown from 'components/Dropdown';
import DetailsButton from './DetailsButton';
import NoRecentActivity from 'components/NoRecentActivity';
import { useAppSelector } from 'app/hooks';
import { selectSelectedAccounts, selectSelectedWarehouses, selectShowDollars } from '../reducers/finobsSlice';
import { selectStartDate, selectEndDate, selectTimeframeOption } from 'app/sharedSlice';
import { useGetUsersPerAccountQuery } from 'app/spendviewApis';
import { getNumberOfBins, adjustDates, getTimeStepOptions } from 'utils/dateUtils';

interface IUsersPerAccountCardProps {
  style: string;
  isDetailsView: boolean;
}

const UsersPerAccountCard = (props: IUsersPerAccountCardProps): JSX.Element => {
  const [processedData, setProcessedData] = useState<ITimeseries[]>([]);
  const startDate = useAppSelector(selectStartDate);
  const endDate = useAppSelector(selectEndDate);
  const showDollars = useAppSelector(selectShowDollars);
  const timeframeOption = useAppSelector(selectTimeframeOption);
  const timeStepOptions = useMemo(() => getTimeStepOptions(startDate, endDate), [startDate, endDate]);
  const [timeStep, setTimeStep] = useState(timeframeOption === 'Last 7 days' ? 'Daily' : timeStepOptions[0]);
  const selectedAccounts = useAppSelector(selectSelectedAccounts);
  const selectedWarehouses = useAppSelector(selectSelectedWarehouses);
  const bins = useMemo(() => getNumberOfBins(startDate, endDate, timeStep), [startDate, endDate, timeStep]);
  const { adjustedStartDate, adjustedEndDate } = useMemo(
    () => adjustDates(endDate, bins, timeStep),
    [startDate, endDate, timeStep],
  );

  useEffect(() => {
    setTimeStep(timeframeOption === 'Last 7 days' ? 'Daily' : timeStepOptions[0]);
  }, [timeStepOptions]);

  const { data, isFetching, isError } = useGetUsersPerAccountQuery({
    startDate: adjustedStartDate,
    endDate: adjustedEndDate,
    bins,
    warehouses: selectedWarehouses,
    aggregate_accounts: true,
    aggregate_by_accounts: selectedAccounts,
    in_dollars: showDollars,
  });

  useEffect(() => {
    if (data !== undefined) {
      const tmpProcessedData = [...data];
      tmpProcessedData.sort(
        (a: ITimeseries, b: ITimeseries) =>
          Math.max(...b.timeseries.map((t: ITimeseriesElement) => t.value)) -
          Math.max(...a.timeseries.map((t: ITimeseriesElement) => t.value)),
      );
      setProcessedData(tmpProcessedData.slice(0, props.isDetailsView ? 8 : 4));
    }
  }, [data]);

  return (
    <Card style={props.style} isLoading={isFetching} isError={isError}>
      <div className="flex">
        <CardTitle
          title="Users per account"
          subtitleText="Changes in users number per account over time"
          tooltipText="On top right, select the granularity of the change in users' number"
        />
        <Dropdown options={timeStepOptions} value={timeStep} onChange={setTimeStep} />
      </div>
      <div className="w-full h-[80%]">
        {processedData.length !== 0 && (
          <ParentSize>
            {(parent) => (
              <UsagePerWarehouseTimeseriesChart
                data={processedData}
                useDollars={false}
                width={parent.width}
                height={parent.height}
              />
            )}
          </ParentSize>
        )}
        {processedData.length === 0 && !isFetching && !isError && <NoRecentActivity />}
      </div>
      <DetailsButton data={processedData} isTimeseriesArray={true} componentName="Users per account" />
    </Card>
  );
};

export default UsersPerAccountCard;
