import React, { useEffect, useState, useRef } from 'react';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import Card from 'components/Card';
import CardTitle from 'components/CardTitle';
import CostPerWarehouseBarChart from './CostPerWarehouseBarChart';
// import Dropdown from 'components/Dropdown';
import DetailsButton from './DetailsButton';
import NoRecentActivity from 'components/NoRecentActivity';
import { useAppSelector } from 'app/hooks';
import { selectSelectedAccounts, selectSelectedWarehouses, selectShowDollars } from '../reducers/finobsSlice';
import { selectStartDate, selectEndDate } from 'app/sharedSlice';
import { useGetCostPerWarehouseUnifiedQuery } from 'app/spendviewApis';
import { classNames } from 'utils/styleUtils';

// const costTypeOptions = ['Compute cost', 'Cumulative cost'];

export interface ICostPerWarehouseUnified {
  wh_name: string;
  cost: number;
  queries_cost: number;
  limit?: number;
}

interface ICostPerWarehouseProps {
  style: string;
  isDetailsView: boolean;
}

const CostPerWarehouseCard = (props: ICostPerWarehouseProps): JSX.Element => {
  const [processedData, setProcessedData] = useState<ICostPerWarehouseUnified[]>([]);
  // const [costType, setCostType] = useState(costTypeOptions[0]);
  const selectedAccounts = useAppSelector(selectSelectedAccounts);
  const selectedWarehouses = useAppSelector(selectSelectedWarehouses);
  const startDate = useAppSelector(selectStartDate);
  const endDate = useAppSelector(selectEndDate);
  const showDollars = useAppSelector(selectShowDollars);
  const windowWidth = useRef(window.innerWidth);

  const { data, isFetching, isError } = useGetCostPerWarehouseUnifiedQuery({
    startDate,
    endDate,
    bins: 1,
    warehouses: selectedWarehouses,
    cost_type: 'compute_cost',
    aggregate_accounts: true,
    aggregate_by_accounts: selectedAccounts,
    in_dollars: showDollars,
  });

  useEffect(() => {
    if (data !== undefined) {
      const tmpProcessedData: ICostPerWarehouseUnified[] = [];
      data.forEach((d: ICostPerWarehouseUnified) => {
        tmpProcessedData.push(d);
      });
      tmpProcessedData.sort((a: ICostPerWarehouseUnified, b: ICostPerWarehouseUnified) => b.cost - a.cost);
      if (props.isDetailsView) {
        setProcessedData(tmpProcessedData.slice(0, 100));
      } else {
        const numWarehouses = windowWidth.current > 1920 ? 6 : 4;
        setProcessedData(tmpProcessedData.slice(0, numWarehouses));
      }
    }
  }, [data]);

  return (
    <Card style={props.style} isLoading={isFetching} isError={isError}>
      <div className="flex">
        <CardTitle
          title="Warehouse utilization"
          subtitleText="Total and query spend per warehouse over the selected period"
          tooltipText="One bar shows the actual spend per warehouse, and the other is the estimated spend based on the runtime of all the queries on the warehouse"
        />
        {/* <Dropdown options={costTypeOptions} value={costType} onChange={setCostType} /> */}
      </div>
      <div
        className={classNames(
          'w-full h-[75%] overflow-y-hidden',
          props.isDetailsView ? 'overflow-x-scroll' : 'overflow-x-hidden',
        )}
      >
        {processedData.length !== 0 && (
          <ParentSize>
            {(parent) => (
              <CostPerWarehouseBarChart
                data={processedData}
                width={parent.width}
                height={parent.height}
                isDetailsView={props.isDetailsView}
              />
            )}
          </ParentSize>
        )}
        {processedData.length === 0 && !isFetching && !isError && <NoRecentActivity />}
      </div>
      <DetailsButton data={processedData} isTimeseriesArray={false} componentName="Cost per warehouse" />
    </Card>
  );
};

export default CostPerWarehouseCard;
