import React from 'react';
import SimpleOverviewCard from 'components/SimpleOverviewCard';
import Card from 'components/Card';
import { classNames } from 'utils/styleUtils';

const CommitsOverviewCard = (props: { style: string }): JSX.Element => {
  return (
    <Card style={classNames(props.style, 'p-0 rounded-md shadow-sm bg-dashboard-background flex space-x-1')}>
      <SimpleOverviewCard
        label="Commits"
        value={12}
        change={20}
        isFetching={false}
        tooltipText="Number of commits over the chosen period"
      />
    </Card>
  );
};

export default CommitsOverviewCard;
