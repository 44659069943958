import React, { useMemo } from 'react';
import SimpleOverviewCard from 'components/SimpleOverviewCard';
import Card from 'components/Card';
import { useAppSelector } from 'app/hooks';
import { selectSelectedAccounts, selectSelectedWarehouses, selectShowDollars } from '../reducers/finobsSlice';
import { selectStartDate, selectEndDate } from 'app/sharedSlice';
import { useGetAccountOverviewMonthlyQuery } from 'app/spendviewApis';
import { classNames } from 'utils/styleUtils';

export interface IAccountOverviewMonthly {
  monthly_compute_cost: number;
  monthly_compute_cost_trend: number;
  monthly_storage_cost: number;
  monthly_storage_cost_trend: number;
  monthly_credits_used: number;
  monthly_storage: number;
  compute_next_month_forecast: number;
  storage_next_month_forecast: number;
}

const AccountOverviewMonthlyCard = (props: { style: string }): JSX.Element => {
  const selectedAccounts = useAppSelector(selectSelectedAccounts);
  const selectedWarehouses = useAppSelector(selectSelectedWarehouses);
  const startDate = useAppSelector(selectStartDate);
  const endDate = useAppSelector(selectEndDate);
  const staticStartDate = useMemo(() => startDate, []);
  const staticEndDate = useMemo(() => endDate, []);
  const showDollars = useAppSelector(selectShowDollars);

  const { data, isFetching } = useGetAccountOverviewMonthlyQuery({
    startDate: staticStartDate,
    endDate: staticEndDate,
    warehouses: selectedWarehouses,
    aggregate_accounts: true,
    aggregate_by_accounts: selectedAccounts,
    in_dollars: showDollars,
  });

  return (
    <Card style={classNames(props.style, 'p-0 rounded-md shadow-sm bg-dashboard-background flex space-x-1')}>
      <>
        <div className="flex-1 h-full space-y-1 flex flex-col">
          <SimpleOverviewCard
            label="Monthly credits used"
            value={data?.monthly_credits_used}
            isFetching={isFetching}
            tooltipText="Last 30 days credits spent across the warehouses in the selected accounts"
          />
          <SimpleOverviewCard
            label="Monthly storage in TB"
            value={data?.monthly_storage}
            valuePostfix="TB"
            isFetching={isFetching}
            tooltipText="Last 30 days Terabyte spent on storage across the warehouses in the selected accounts"
          />
        </div>
        <div className="flex-1 h-full space-y-1 flex flex-col">
          <SimpleOverviewCard
            label="Monthly compute spend"
            value={data?.monthly_compute_cost}
            valuePrefix={showDollars ? '$' : ''}
            change={data?.monthly_compute_cost_trend}
            isFetching={isFetching}
            tooltipText="Dollars or credits spent across the warehouses in the selected accounts over the last 30 days"
          />
          <SimpleOverviewCard
            label="Monthly storage spend"
            value={data?.monthly_storage_cost}
            valuePrefix={showDollars ? '$' : ''}
            change={data?.monthly_storage_cost_trend}
            isFetching={isFetching}
            tooltipText="Dollars or credits spent on storage across the warehouses in the selected accounts over the last 30 days"
          />
        </div>
        <div className="flex-1 h-full space-y-1 flex flex-col">
          <SimpleOverviewCard
            label="Next month compute spend"
            value={data?.compute_next_month_forecast}
            valuePrefix={showDollars ? '$' : ''}
            isFetching={isFetching}
            tooltipText="Dollars or credits spent across the warehouses in the selected accounts over the next 30 days"
          />
          <SimpleOverviewCard
            label="Next month storage spend"
            value={data?.storage_next_month_forecast}
            valuePrefix={showDollars ? '$' : ''}
            isFetching={isFetching}
            tooltipText="Dollars or credits spent on storage for all the selected accounts over the next 30 days"
          />
        </div>
      </>
    </Card>
  );
};

export default AccountOverviewMonthlyCard;
