import React, { useMemo } from 'react';
import { ITimeseries, ITimeseriesElement } from 'utils/types';
import { AnimatedAxis, AnimatedGrid, AnimatedAreaSeries, XYChart, Tooltip } from '@visx/xychart';
import { curveMonotoneX } from '@visx/curve';
import { defaultStyles } from '@visx/tooltip';
import { formatTickLabelCost, formatTickLabelCredit, formatNumberShort } from 'utils/formatUtils';
import colors from 'utils/colors';
import { useAppSelector } from 'app/hooks';
import { selectStartDate, selectEndDate } from 'app/sharedSlice';
import { getTickLabelPrecision, getTickValues, formatTickDate, formatTickLabelDate } from 'utils/dateUtils';
import useShowDollars from '../hooks/useShowDollar';

interface CostTimeseriesChartProps {
  data: ITimeseries[];
  width: number;
  height: number;
}

const tooltipStyles = {
  ...defaultStyles,
  minWidth: 120,
  backgroundColor: '#27262c',
  color: 'white',
  fontSize: 12,
};

const CostTimeseriesChart = (props: CostTimeseriesChartProps): JSX.Element => {
  const showDollars = useShowDollars();
  const startDate = useAppSelector(selectStartDate);
  const endDate = useAppSelector(selectEndDate);
  const start = new Date(startDate);
  const end = new Date(endDate);
  const differenceInHours = (end.getTime() - start.getTime()) / (1000 * 60 * 60);
  const tickLabelPrecision = useMemo(() => getTickLabelPrecision(differenceInHours), [props.data]);
  const allTickValues = props.data[0].timeseries.map((d) => d.date);
  const tickValues = useMemo(
    () => getTickValues(allTickValues, tickLabelPrecision).map((d) => new Date(d)),
    [props.data],
  );

  return (
    <XYChart
      width={props.width}
      height={props.height}
      xScale={{ type: 'time' }}
      yScale={{ type: 'linear', nice: true }}
      margin={{ top: 20, bottom: 50, left: 40, right: 40 }}
    >
      <AnimatedAxis
        orientation="bottom"
        tickValues={tickValues}
        tickFormat={(d) => formatTickDate(d, tickLabelPrecision)}
        stroke={colors.axisColor}
        tickStroke={colors.axisColor}
      />
      <AnimatedAxis
        orientation="left"
        numTicks={5}
        tickFormat={showDollars ? formatTickLabelCost : formatTickLabelCredit}
        stroke={colors.axisColor}
        tickStroke={colors.axisColor}
        tickLabelProps={() => ({
          fontSize: 11,
          color: '#87858d',
        })}
      />
      <AnimatedGrid
        columns={false}
        numTicks={5}
        lineStyle={{ stroke: '#87858d', strokeWidth: '1px', strokeOpacity: 0.2 }}
      />
      <>
        {props.data.map((data, index) => (
          <AnimatedAreaSeries
            curve={curveMonotoneX}
            key={index}
            dataKey={data.category}
            data={data.timeseries}
            xAccessor={(d) => new Date(d.date)}
            yAccessor={(d) => d.value}
            fill={colors.dataopsDarkBlue}
            fillOpacity={0.4}
          />
        ))}
      </>
      <Tooltip<ITimeseriesElement>
        snapTooltipToDatumX
        snapTooltipToDatumY
        showVerticalCrosshair
        style={tooltipStyles}
        renderTooltip={({ tooltipData }) => (
          <div>
            <div>{showDollars ? 'Cost' : 'Credits'}</div>
            <div>
              Date:{' '}
              {tooltipData !== undefined
                ? formatTickLabelDate(
                    tooltipData.nearestDatum?.datum.date ?? '',
                    tickLabelPrecision === 'Monthly' ? 'Daily' : 'Hourly',
                  )
                : ''}
            </div>
            <div>
              Value: {showDollars ? '$' : ''}
              {tooltipData !== undefined ? formatNumberShort(tooltipData.nearestDatum?.datum.value ?? 0) : ''}
            </div>
          </div>
        )}
      />
    </XYChart>
  );
};

export default CostTimeseriesChart;
