import { zeroPadding } from './formatUtils';
import { isSameDay } from 'date-fns';

export type ITickLabelPrecision = 'Default' | 'Hourly' | 'Daily' | 'Monthly';

export function formatTickLabelDate(dateString: string, tickLabelPrecision: ITickLabelPrecision): string {
  const date = new Date(dateString);
  const month = zeroPadding(date.getMonth() + 1, 2);
  const day = zeroPadding(date.getDate(), 2);
  const hour = zeroPadding(date.getHours(), 2);
  const minute = zeroPadding(date.getMinutes(), 2);

  if (tickLabelPrecision === 'Default') {
    return `${month}/${day} ${hour}:${minute}`;
  } else if (tickLabelPrecision === 'Hourly') {
    return `${month}/${day} ${hour}:${minute}`;
  } else if (tickLabelPrecision === 'Monthly') {
    return date.toLocaleString('default', { month: 'short' });
  } else {
    return `${month}/${day}`;
  }
}

export function formatTickDate(date: Date, tickLabelPrecision: ITickLabelPrecision): string {
  const month = zeroPadding(date.getMonth() + 1, 2);
  const day = zeroPadding(date.getDate(), 2);
  const hour = zeroPadding(date.getHours(), 2);
  const minute = zeroPadding(date.getMinutes(), 2);

  if (tickLabelPrecision === 'Default') {
    return `${month}/${day} ${hour}:${minute}`;
  } else if (tickLabelPrecision === 'Hourly') {
    return `${month}/${day} ${hour}:${minute}`;
  } else if (tickLabelPrecision === 'Monthly') {
    return date.toLocaleString('default', { month: 'short' });
  } else {
    return `${month}/${day}`;
  }
}

export function getTickLabelPrecision(timeframeInHours: number): ITickLabelPrecision {
  if (timeframeInHours < 4) {
    return 'Default';
  } else if (timeframeInHours < 24 * 4) {
    return 'Hourly';
  } else if (timeframeInHours < 24 * 28 * 4) {
    return 'Daily';
  } else {
    return 'Monthly';
  }
}

export function getTickLabelPrecisionHeatmap(timeframeInHours: number): ITickLabelPrecision {
  if (timeframeInHours < 4) {
    return 'Default';
  } else if (timeframeInHours < 24 * 4) {
    return 'Hourly';
  } else if (timeframeInHours < 24 * 28 * 8) {
    return 'Daily';
  } else {
    return 'Monthly';
  }
}

export function getTickValues(allTickValues: string[], tickLabelPrecision: ITickLabelPrecision): string[] {
  let tickValues = [];
  for (let i = 1; i < allTickValues.length; ++i) {
    const previousDate = new Date(allTickValues[i - 1]);
    const date = new Date(allTickValues[i]);
    if (tickLabelPrecision === 'Default') {
      tickValues.push(allTickValues[i]);
    } else if (tickLabelPrecision === 'Hourly') {
      if (previousDate.getHours() !== date.getHours()) {
        tickValues.push(allTickValues[i]);
      }
    } else if (tickLabelPrecision === 'Monthly') {
      if (previousDate.getMonth() !== date.getMonth()) {
        tickValues.push(allTickValues[i]);
      }
    } else {
      if (previousDate.getDate() !== date.getDate()) {
        tickValues.push(allTickValues[i]);
      }
    }
  }

  const limit = tickLabelPrecision === 'Hourly' ? 6 : 12;
  while (tickValues.length > limit) {
    tickValues = tickValues.filter((d, index) => index % 2 === 0);
  }

  return tickValues;
}

export function getNumberOfBins(startDate: string, endDate: string, timeStepOption: string): number {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const difference = end.getTime() - start.getTime();
  switch (timeStepOption) {
    case 'Hourly':
      return Math.ceil(difference / (1000 * 60 * 60));
    case 'Daily':
      return Math.ceil(difference / (1000 * 60 * 60 * 24));
    case 'Weekly':
      return Math.ceil(difference / (1000 * 60 * 60 * 24 * 7));
    case 'Monthly':
      return Math.ceil(difference / (1000 * 60 * 60 * 24 * 28));
  }
  return 7;
}

export function getNumberOfBinsHeatmap(timeframeOption: string, screenWidth: number, isDetailsView: boolean): number {
  const wideScreen = screenWidth > 1920;
  switch (timeframeOption) {
    case 'Last day':
      return isDetailsView ? 12 : wideScreen ? 8 : 6;
    case 'Last 7 days':
      return 7;
    case 'Last 30 days':
    case 'Last 60 days':
    case 'Last 90 days':
    case 'Last 180 days':
    case 'Last 360 days':
      return isDetailsView ? 12 : wideScreen ? 9 : 6;
    case 'Custom':
      return isDetailsView ? 12 : wideScreen ? 8 : 6;
  }
  return 7;
}

export function getTimeStepOptions(startDate: string, endDate: string): string[] {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const differenceInHours = (end.getTime() - start.getTime()) / (1000 * 60 * 60);
  if (differenceInHours < 4) {
    return ['Hourly'];
  } else if (differenceInHours < 24 * 4) {
    return ['Hourly'];
  } else if (differenceInHours < 24 * 7 * 3) {
    return ['Hourly', 'Daily'];
  } else if (differenceInHours < 24 * 28 * 3) {
    return ['Daily', 'Weekly'];
  } else {
    return ['Daily', 'Weekly', 'Monthly'];
  }
}

export function adjustDates(
  endDate: string,
  bins: number,
  timeStep: string,
): { adjustedStartDate: string; adjustedEndDate: string } {
  const end = new Date(endDate);
  const adjustedStartDate = new Date(endDate);
  let adjustedEndDate = new Date(endDate);
  if (timeStep === 'Hourly') {
    adjustedStartDate.setHours(end.getHours() - bins);
  } else if (timeStep === 'Daily') {
    adjustedStartDate.setDate(end.getDate() - bins);
  } else if (timeStep === 'Weekly') {
    adjustedStartDate.setDate(end.getDate() - bins * 7);
  } else if (timeStep === 'Monthly') {
    const [month, year] = [end.getMonth(), end.getFullYear()];
    adjustedEndDate = new Date(year, month, 1, 10, 0);
    adjustedStartDate.setDate(adjustedEndDate.getDate() - bins * 28);
  }
  return { adjustedStartDate: adjustedStartDate.toISOString(), adjustedEndDate: adjustedEndDate.toISOString() };
}

/**
 * The start date and end date have to be shifted one day, because
 * we don't have data for the current day.
 */
export function adjustStorageDates(
  startDate: string,
  endDate: string,
): { adjustedStartDate: string; adjustedEndDate: string } {
  const today = new Date();
  const endDateTmp = new Date(endDate);
  if (!isSameDay(today, endDateTmp)) {
    return { adjustedStartDate: startDate, adjustedEndDate: endDate };
  }
  const adjustedStartDate = new Date(startDate);
  adjustedStartDate.setDate(adjustedStartDate.getDate() - 1);
  const adjustedEndDate = new Date(endDate);
  adjustedEndDate.setDate(adjustedEndDate.getDate() - 1);

  return { adjustedStartDate: adjustedStartDate.toISOString(), adjustedEndDate: adjustedEndDate.toISOString() };
}

export function getStartAndEndDate(rangeInDays: number): { startDate: Date; endDate: Date } {
  const now = new Date();
  const [month, day, year, hour] = [now.getMonth(), now.getDate(), now.getFullYear(), now.getHours()];
  const startDate = new Date(year, month, day, hour, 0);
  const endDate = new Date(year, month, day, hour, 0);
  startDate.setDate(startDate.getDate() - rangeInDays);
  return { startDate, endDate };
}

export function formatCustomDate(dateString: string): string {
  const date = new Date(dateString);
  const day = date.getDate();
  const formattedDate = `${date.toLocaleString('default', { month: 'short' })} ${day}`;
  return formattedDate;
}

export function getNumberOfDays(startDate: string, endDate: string): number {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const differenceInDays = Math.round(Math.abs((end.getTime() - start.getTime()) / (24 * 60 * 60 * 1000)));
  return differenceInDays;
}
