import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../../../app/store';
import { IRequestStatus, ITab } from 'utils/types';
import { ISnowflakeAccount } from 'views/SnowflakeAccountsView';

export interface FinObsState {
  tabs: ITab[];
  showDollars: boolean;
  accounts: ISnowflakeAccount[];
  accountsStatus: IRequestStatus;
  selectedAccounts: string[];
  pendingSelectedAccounts: string[];
  pendingAccountSelection: boolean;
  showInactiveWarehouses: boolean;
  warehouses: string[];
  warehousesStatus: IRequestStatus;
  selectedWarehouses: string[];
  pendingSelectedWarehouses: string[];
  selectedWarehousesStatus: IRequestStatus;
  showError: boolean;
  errorMessage: string;
  detailsDialogOpen: boolean;
  detailsDialogContent: any;
  detailsDialogIsTimeseriesArray: boolean;
}

const defaultTabs = [
  { name: 'Overview', href: '/finobs' },
  { name: 'Compute', href: '/finobs/compute-costs' },
  { name: 'Storage', href: '/finobs/storage-costs' },
  { name: 'Usage', href: '/finobs/usage-costs' },
];

const initialState: FinObsState = {
  tabs: defaultTabs,
  showDollars: true,
  accounts: [],
  accountsStatus: 'idle',
  selectedAccounts: [],
  pendingSelectedAccounts: [],
  pendingAccountSelection: false,
  showInactiveWarehouses: false,
  warehouses: [],
  warehousesStatus: 'idle',
  selectedWarehouses: [],
  pendingSelectedWarehouses: [],
  selectedWarehousesStatus: 'idle',
  showError: false,
  errorMessage: '',
  detailsDialogOpen: false,
  detailsDialogContent: {},
  detailsDialogIsTimeseriesArray: false,
};

export const fetchAccounts = createAsyncThunk('accounts', async () => {
  const response = await axios.get('/restapi/top_bar/snowflake_accounts');
  return response.data;
});

export const fetchWarehouses = createAsyncThunk(
  'warehouses',
  async (filter: {
    startDate: string;
    endDate: string;
    selectedAccounts: string[];
    showInactiveWarehouses: boolean;
  }) => {
    const { startDate, endDate, selectedAccounts, showInactiveWarehouses } = filter;
    if (!showInactiveWarehouses) {
      const response = await axios.get('/restapi/cost_router/active_warehouses', {
        params: {
          startDate,
          endDate,
          aggregate_accounts: true,
          aggregate_by_accounts: selectedAccounts,
        },
        paramsSerializer: {
          indexes: null,
        },
      });

      return response.data.items;
    } else {
      const response = await axios.get('/restapi/cost_router/warehouses_list', {
        params: {
          startDate,
          endDate,
          aggregate_accounts: true,
          aggregate_by_accounts: selectedAccounts,
        },
        paramsSerializer: {
          indexes: null,
        },
      });

      return response.data;
    }
  },
);

export const finobSlice = createSlice({
  name: 'finobs',
  initialState,
  reducers: {
    setTabs: (state, action: PayloadAction<ITab[]>) => {
      state.tabs = action.payload;
    },
    setShowDollars: (state, action: PayloadAction<boolean>) => {
      state.showDollars = action.payload;
    },
    setSelectedAccounts: (state, action: PayloadAction<string[]>) => {
      state.showError = false;
      state.selectedAccounts = action.payload;
    },
    setPendingSelectedAccounts: (state, action: PayloadAction<string[]>) => {
      state.pendingSelectedAccounts = action.payload;
      state.pendingAccountSelection = true;
    },
    setShowInactiveWarehouses: (state, action: PayloadAction<boolean>) => {
      state.showInactiveWarehouses = action.payload;
    },
    setSelectedWarehouses: (state, action: PayloadAction<string[]>) => {
      state.showError = false;
      state.selectedWarehouses = action.payload;
    },
    setPendingSelectedWarehouses: (state, action: PayloadAction<string[]>) => {
      state.pendingSelectedWarehouses = action.payload;
    },
    setShowError: (state, action: PayloadAction<boolean>) => {
      state.showError = action.payload;
    },
    setErrorMessage: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload;
    },
    setDetailsDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.detailsDialogOpen = action.payload;
    },
    setDetailsDialogContent: (state, action: PayloadAction<any>) => {
      state.detailsDialogContent = action.payload;
    },
    setDetailsDialogIsTimeseriesArray: (state, action: PayloadAction<boolean>) => {
      state.detailsDialogIsTimeseriesArray = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAccounts.pending, (state) => {
        state.accountsStatus = 'loading';
      })
      .addCase(fetchAccounts.fulfilled, (state, action) => {
        state.accountsStatus = 'idle';
        const processed = Object.keys(action.payload).map((key) => action.payload[key]);
        state.accounts = processed;
      })
      .addCase(fetchAccounts.rejected, (state) => {
        state.accountsStatus = 'failed';
      })
      .addCase(fetchWarehouses.pending, (state) => {
        state.warehousesStatus = 'loading';
      })
      .addCase(fetchWarehouses.fulfilled, (state, action) => {
        state.warehousesStatus = 'idle';
        const processed = action.payload.filter(
          (value: string, index: number, self: any) => index === self.findIndex((t: string) => t === value),
        );
        state.warehouses = processed;
        state.selectedWarehouses = processed.filter((warehouse: string) =>
          state.selectedWarehouses.includes(warehouse),
        );
        state.pendingSelectedWarehouses = processed.filter((warehouse: string) =>
          state.pendingSelectedWarehouses.includes(warehouse),
        );
        state.pendingAccountSelection = false;
      })
      .addCase(fetchWarehouses.rejected, (state) => {
        state.warehousesStatus = 'failed';
      });
  },
});

export const {
  setTabs,
  setShowDollars,
  setSelectedAccounts,
  setPendingSelectedAccounts,
  setShowInactiveWarehouses,
  setSelectedWarehouses,
  setPendingSelectedWarehouses,
  setShowError,
  setErrorMessage,
  setDetailsDialogOpen,
  setDetailsDialogContent,
  setDetailsDialogIsTimeseriesArray,
} = finobSlice.actions;

export const selectTabs = (state: RootState): ITab[] => state.finobs.tabs;
export const selectShowDollars = (state: RootState): boolean => state.finobs.showDollars;
export const selectAccounts = (state: RootState): ISnowflakeAccount[] => state.finobs.accounts;
export const selectSelectedAccounts = (state: RootState): string[] => state.finobs.selectedAccounts;
export const selectPendingSelectedAccounts = (state: RootState): string[] => state.finobs.pendingSelectedAccounts;
export const selectPendingAccountSelection = (state: RootState): boolean => state.finobs.pendingAccountSelection;
export const selectShowInactiveWarehouses = (state: RootState): boolean => state.finobs.showInactiveWarehouses;
export const selectWarehouses = (state: RootState): string[] => state.finobs.warehouses;
export const selectSelectedWarehouses = (state: RootState): string[] => state.finobs.selectedWarehouses;
export const selectPendingSelectedWarehouses = (state: RootState): string[] => state.finobs.pendingSelectedWarehouses;
export const selectShowError = (state: RootState): boolean => state.finobs.showError;
export const selectErrorMessage = (state: RootState): string => state.finobs.errorMessage;
export const selectDetailsDialogOpen = (state: RootState): boolean => state.finobs.detailsDialogOpen;
export const selectDetailsDialogContent = (state: RootState): any => state.finobs.detailsDialogContent;
export const selectDetailsDialogIsTimeseriesArray = (state: RootState): boolean =>
  state.finobs.detailsDialogIsTimeseriesArray;

export default finobSlice.reducer;
