import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

export default function LoginForm(): JSX.Element {
  const { loginWithRedirect } = useAuth0();

  return (
    <main className="w-full h-full">
      <div className="absolute w-[12rem] mt-4 ml-4">
        <img src="dataops-logo-blue.svg" />
      </div>
      <div className="w-full h-full flex flex-col items-center justify-center">
        <button
          className="relative flex w-[12rem] mx-[auto] justify-center rounded-md border border-transparent bg-dataops-primary-blue py-2 px-4 text-sm font-medium text-white hover:bg-cyan-700"
          onClick={() => {
            loginWithRedirect({
              authorizationParams: {
                redirect_uri: `${window.location.origin}/auth-callback`,
              },
            }).catch((e) => e);
          }}
        >
          Sign in
        </button>
        <div className="mt-6 flex flex-col items-center justify-center">
          <div className="flex">
            <div className="text-gray-700 mr-2">Don&apos;t have a DataOps.live Observability account? </div>
            <a
              href="https://www.dataops.live/spendview-signup"
              className="text-dataops-primary-blue hover:text-dataops-secondary-blue"
            >
              Sign up
            </a>
          </div>
          <div className="flex">
            <div className="text-gray-700 mr-2">Have problems signing-in?</div>
            <a
              href="https://docs.dataops.live/docs/support/"
              className="text-dataops-primary-blue hover:text-dataops-secondary-blue"
            >
              Contact support
            </a>
          </div>
        </div>
      </div>
    </main>
  );
}
