import React from 'react';
import { useTooltip, useTooltipInPortal, defaultStyles } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import { formatNumberLong } from 'utils/formatUtils';
import { classNames } from 'utils/styleUtils';

const tooltipStyles = {
  ...defaultStyles,
  backgroundColor: '#4b5563',
  color: 'white',
  fontSize: 12,
  zIndex: 30,
  maxWidth: '200px',
};

interface TooltipData {
  description: string;
}

interface SimpleOverviewCardProps {
  label: string;
  value: number | undefined | null;
  isFetching: boolean;
  valuePrefix?: string;
  valuePostfix?: string;
  change?: number | undefined | null;
  useColor?: boolean;
  tooltipText: string;
}

const SimpleOverviewCard = (props: SimpleOverviewCardProps): JSX.Element => {
  const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } = useTooltip<TooltipData>();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });

  const isValueIncreasing = (): boolean => {
    if (props.useColor === true && (props.value ?? 0) >= 0) {
      return true;
    } else if (props.useColor !== true && (props.change ?? 0) >= 0) {
      return true;
    }
    return false;
  };

  return (
    <div
      className={classNames(
        props.isFetching ? 'animate-[pulse_1.5s_cubic-bezier(0.4,_0,_0.6,_0.1)_infinite]' : '',
        'p-2 flex-1 rounded-md shadow-sm bg-white space-y-1 xl:space-y-2 flex flex-col items-center justify-center text-center',
      )}
    >
      <div
        className="text-xs text-primary-text cursor-default"
        ref={containerRef}
        onMouseLeave={() => {
          hideTooltip();
        }}
        onMouseMove={(event) => {
          const eventSvgCoords = localPoint(event);
          showTooltip({
            tooltipData: { description: props.tooltipText },
            tooltipTop: eventSvgCoords?.y,
            tooltipLeft: eventSvgCoords?.x,
          });
        }}
      >
        {props.label}
      </div>
      <div className="flex items-center justify-center">
        <div
          className={classNames(
            props.useColor === undefined || !props.useColor || props.value === undefined || props.value === null
              ? 'text-primary-text'
              : props.value <= 0
              ? 'text-green-highlight'
              : 'text-red-highlight',
            'text-xl 2xl:text-2xl',
          )}
        >
          {(props.value as number) < 0 ? '-' : ''}
          {props.value === undefined || props.value === null ? '' : props.valuePrefix ?? ''}
          {props.value === undefined || props.value === null
            ? props.isFetching
              ? ''
              : 'N/A'
            : formatNumberLong(Math.abs(props.value))}
          {props.value === undefined || props.value === null ? '' : props.valuePostfix ?? ''}{' '}
          {(props.change !== undefined || props.change !== null) && (
            <span
              className={`text-xs 2xl:text-base ${
                (props.change ?? 0) <= 0 ? 'text-green-highlight' : 'text-red-highlight'
              }`}
            >
              {props.change === undefined || props.change === null ? '' : `${formatNumberLong(props.change)}%`}
            </span>
          )}
        </div>
        {props.value !== undefined &&
          props.value !== null &&
          ((props.useColor === true && props.value !== 0) ||
            (props.change !== undefined && props.change !== null && props.change !== 0)) &&
          (isValueIncreasing() ? (
            <div className="w-6">
              <svg
                clipRule="evenodd"
                fillRule="evenodd"
                strokeLinejoin="round"
                strokeMiterlimit="2"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="m9.001 10.978h-3.251c-.412 0-.75-.335-.75-.752 0-.188.071-.375.206-.518 1.685-1.775 4.692-4.945 6.069-6.396.189-.2.452-.312.725-.312.274 0 .536.112.725.312 1.377 1.451 4.385 4.621 6.068 6.396.136.143.207.33.207.518 0 .417-.337.752-.75.752h-3.251v9.02c0 .531-.47 1.002-1 1.002h-3.998c-.53 0-1-.471-1-1.002z"
                  fillRule="nonzero"
                  fill="#eb3943"
                />
              </svg>
            </div>
          ) : (
            <div className="w-6">
              <svg
                clipRule="evenodd"
                fillRule="evenodd"
                strokeLinejoin="round"
                strokeMiterlimit="2"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="m9.001 13.022h-3.251c-.412 0-.75.335-.75.752 0 .188.071.375.206.518 1.685 1.775 4.692 4.945 6.069 6.396.189.2.452.312.725.312.274 0 .536-.112.725-.312 1.377-1.451 4.385-4.621 6.068-6.396.136-.143.207-.33.207-.518 0-.417-.337-.752-.75-.752h-3.251v-9.02c0-.531-.47-1.002-1-1.002h-3.998c-.53 0-1 .471-1 1.002z"
                  fillRule="nonzero"
                  fill="#46be88"
                />
              </svg>
            </div>
          ))}
      </div>
      {tooltipOpen && tooltipData !== undefined && (
        <TooltipInPortal top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
          <div className="text-sm">{tooltipData.description}</div>
        </TooltipInPortal>
      )}
    </div>
  );
};

export default SimpleOverviewCard;
