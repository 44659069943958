import React, { useEffect, useState, useRef } from 'react';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import Card from 'components/Card';
import CardTitle from 'components/CardTitle';
import WarehouseQueueingBarChart from './WarehouseQueueingBarChart';
import DetailsButton from './DetailsButton';
import NoRecentActivity from 'components/NoRecentActivity';
import { useAppSelector } from 'app/hooks';
import { selectSelectedAccounts, selectSelectedWarehouses, selectShowDollars } from '../reducers/finobsSlice';
import { selectStartDate, selectEndDate } from 'app/sharedSlice';
import { useGetWarehouseQueueingQuery } from 'app/spendviewApis';
import { classNames } from 'utils/styleUtils';

export interface IWarehouseQueueing {
  wh_name: string;
  queued: number;
  queries_per_wh: number;
  queued_queries_per_wh: number;
}

interface IWarehouseQueueingProps {
  style: string;
  isDetailsView: boolean;
}

const WarehouseQueueingCard = (props: IWarehouseQueueingProps): JSX.Element => {
  const [processedData, setProcessedData] = useState<IWarehouseQueueing[]>([]);
  const selectedAccounts = useAppSelector(selectSelectedAccounts);
  const selectedWarehouses = useAppSelector(selectSelectedWarehouses);
  const startDate = useAppSelector(selectStartDate);
  const endDate = useAppSelector(selectEndDate);
  const showDollars = useAppSelector(selectShowDollars);
  const windowWidth = useRef(window.innerWidth);

  const { data, isFetching, isError } = useGetWarehouseQueueingQuery({
    startDate,
    endDate,
    warehouses: selectedWarehouses,
    aggregate_accounts: true,
    aggregate_by_accounts: selectedAccounts,
    in_dollars: showDollars,
  });

  useEffect(() => {
    if (data !== undefined) {
      const tmpProcessedData: IWarehouseQueueing[] = [];
      data.forEach((d: IWarehouseQueueing) => {
        tmpProcessedData.push(d);
      });
      tmpProcessedData.sort((a: IWarehouseQueueing, b: IWarehouseQueueing) => b.queries_per_wh - a.queries_per_wh);
      if (props.isDetailsView) {
        setProcessedData(tmpProcessedData);
      } else {
        setProcessedData(tmpProcessedData.slice(0, windowWidth.current > 1920 ? 6 : 4));
      }
    }
  }, [data]);

  return (
    <Card style={props.style} isLoading={isFetching} isError={isError}>
      <div className="flex">
        <CardTitle
          title="Warehouse queueing"
          subtitleText="Queueing values per warehouse"
          tooltipText="Values of the queries executed and those queued per warehouse in the selected accounts"
        />
      </div>
      <div
        className={classNames(
          'w-full h-[75%] overflow-y-hidden',
          props.isDetailsView ? 'overflow-x-scroll' : 'overflow-x-hidden',
        )}
      >
        {processedData.length !== 0 && (
          <ParentSize>
            {(parent) => (
              <WarehouseQueueingBarChart
                data={processedData}
                width={parent.width}
                height={parent.height}
                isDetailsView={props.isDetailsView}
              />
            )}
          </ParentSize>
        )}
        {processedData.length === 0 && !isFetching && !isError && <NoRecentActivity />}
      </div>
      <DetailsButton data={processedData} isTimeseriesArray={false} componentName="Warehouse queueing" />
    </Card>
  );
};

export default WarehouseQueueingCard;
