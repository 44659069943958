import React from 'react';
import { Switch as HeadlessUiSwitch } from '@headlessui/react';
import { classNames } from 'utils/styleUtils';

export interface SwitchProps {
  checked: boolean;
  onChange: () => void;
  label: string;
  disabled?: boolean;
}

const Switch = (props: SwitchProps): JSX.Element => {
  return (
    <HeadlessUiSwitch.Group as="div" className="flex items-center">
      <HeadlessUiSwitch
        checked={props.checked}
        onChange={() => props.onChange()}
        className={classNames(
          props.checked
            ? props.disabled === true
              ? 'bg-gray-300 cursor-default'
              : 'bg-dataops-secondary-blue cursor-pointer'
            : 'bg-gray-200',
          'relative inline-flex h-6 w-11 flex-shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none',
        )}
        disabled={props.disabled ?? false}
      >
        <span
          aria-hidden="true"
          className={classNames(
            props.checked ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
          )}
        />
      </HeadlessUiSwitch>
      <HeadlessUiSwitch.Label as="span" className="ml-2 text-sm">
        <span className={classNames(props.disabled === true ? 'text-gray-400' : 'text-gray-700', 'font-medium')}>
          {props.label}
        </span>
      </HeadlessUiSwitch.Label>
    </HeadlessUiSwitch.Group>
  );
};

export default Switch;
