import React from 'react';
import Card from 'components/Card';
import DataProductCard from './DataProductCard';
import { useAppSelector } from 'app/hooks';
import { selectStartDate, selectEndDate, selectSelectedDataProducts } from '../reducers/dataProductsSlice';
import { selectSelectedProjects, selectSelectedEnvironments } from 'features/layouts/reducers/appBarSlice';
import { stringArrayFromOptionArray } from 'utils/formatUtils';
import { useGetTopDataProductsQuery } from 'app/dataProductsApis';

export interface IDataProduct {
  name: string;
  instance_cost: number;
  credits_produce: number;
  credits_produce_change: number;
  credits_usage: number;
  credits_usage_change: number;
  last_refreshed: string;
}

const TopDataProductsCard = (props: { style: string }): JSX.Element => {
  const selectedProjects = useAppSelector(selectSelectedProjects);
  const selectedEnvironments = useAppSelector(selectSelectedEnvironments);
  const selectedDataProducts = useAppSelector(selectSelectedDataProducts);
  const startDate = useAppSelector(selectStartDate);
  const endDate = useAppSelector(selectEndDate);

  const { data } = useGetTopDataProductsQuery({
    startDate,
    endDate,
    projectId: selectedProjects,
    env: stringArrayFromOptionArray(selectedEnvironments),
    data_product: selectedDataProducts,
  });

  return (
    <Card style={`flex flex-col space-y-4 ${props.style}`}>
      <div className="w-full flex items-center">
        <div className="flex-grow text-primary-text">Most Expensive Data Products</div>
        {/* <div className="text-sm text-secondary-text">Data sets: {data.datasets}</div> */}
      </div>
      <>
        {(data ?? ([] as IDataProduct[])).slice(0, 5).map((product) => (
          <DataProductCard key={product.name} {...product} />
        ))}
      </>
    </Card>
  );
};

export default TopDataProductsCard;
