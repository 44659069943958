import React, { useEffect, useState, useRef } from 'react';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import Card from 'components/Card';
import CardTitle from 'components/CardTitle';
import WarehouseSpillingBarChart from './WarehouseSpillingBarChart';
import DetailsButton from './DetailsButton';
import NoRecentActivity from 'components/NoRecentActivity';
import { useAppSelector } from 'app/hooks';
import { selectSelectedAccounts, selectSelectedWarehouses, selectShowDollars } from '../reducers/finobsSlice';
import { selectStartDate, selectEndDate } from 'app/sharedSlice';
import { useGetWarehouseSpillingQuery } from 'app/spendviewApis';
import { classNames } from 'utils/styleUtils';

export interface IWarehouseSpilling {
  wh_name: string;
  local_spilled: number;
  remote_spilled: number;
}

interface IWarehouseSpillingProps {
  style: string;
  isDetailsView: boolean;
}

const WarehouseSpillingCard = (props: IWarehouseSpillingProps): JSX.Element => {
  const [processedData, setProcessedData] = useState<IWarehouseSpilling[]>([]);
  const selectedAccounts = useAppSelector(selectSelectedAccounts);
  const selectedWarehouses = useAppSelector(selectSelectedWarehouses);
  const startDate = useAppSelector(selectStartDate);
  const endDate = useAppSelector(selectEndDate);
  const showDollars = useAppSelector(selectShowDollars);
  const windowWidth = useRef(window.innerWidth);

  const { data, isFetching, isError } = useGetWarehouseSpillingQuery({
    startDate,
    endDate,
    warehouses: selectedWarehouses,
    aggregate_accounts: true,
    aggregate_by_accounts: selectedAccounts,
    in_dollars: showDollars,
  });

  useEffect(() => {
    if (data !== undefined) {
      const tmpProcessedData: IWarehouseSpilling[] = [];
      data.forEach((d: IWarehouseSpilling) => {
        tmpProcessedData.push(d);
      });
      tmpProcessedData.sort((a: IWarehouseSpilling, b: IWarehouseSpilling) => b.remote_spilled - a.remote_spilled);
      if (props.isDetailsView) {
        setProcessedData(tmpProcessedData);
      } else {
        setProcessedData(tmpProcessedData.slice(0, windowWidth.current > 1920 ? 6 : 4));
      }
    }
  }, [data]);

  return (
    <Card style={props.style} isLoading={isFetching} isError={isError}>
      <div className="flex">
        <CardTitle
          title="Warehouse spilling"
          subtitleText="Remote and local spilling values per warehouse"
          tooltipText="Values of data stored on the local disk of each warehouse node and that written to the remote cloud storage where the warehouse cannot fit an operation in memory"
        />
      </div>
      <div
        className={classNames(
          'w-full h-[75%] overflow-y-hidden',
          props.isDetailsView ? 'overflow-x-scroll' : 'overflow-x-hidden',
        )}
      >
        {processedData.length !== 0 && (
          <ParentSize>
            {(parent) => (
              <WarehouseSpillingBarChart
                data={processedData}
                width={parent.width}
                height={parent.height}
                isDetailsView={props.isDetailsView}
              />
            )}
          </ParentSize>
        )}
        {processedData.length === 0 && !isFetching && !isError && <NoRecentActivity />}
      </div>
      <DetailsButton data={processedData} isTimeseriesArray={false} componentName="Warehouse spilling" />
    </Card>
  );
};

export default WarehouseSpillingCard;
