import { observabilityApi } from './apis';
import { IUserData } from 'features/data-products/components/ActiveUsersCard';
import { IRawBusinessValueData } from 'features/data-products/components/BusinessValueCard';
import { IDataProductAnalysis } from 'features/data-products/components/DataProductAnalysisCard';
import { IProductionCost } from 'features/data-products/components/ProductionCostCard';
import { IDataProduct } from 'features/data-products/components/TopDataProductsCard';
import { ITopDataProductUsage } from 'features/data-products/components/TopDataProductUsageCard';
import { ITotalCostOverTime } from 'features/data-products/components/TotalCostOverTimeCard';

interface IDataProductsRequestParams {
  startDate: string;
  endDate: string;
  projectId: string[];
  env: string[];
  data_product: string[];
  cost_type?: string;
}

interface IDataProductsRequestParamsWithBins {
  startDate: string;
  endDate: string;
  projectId: string[];
  env: string[];
  data_product: string[];
  bins: number;
  cost_type?: string;
}

const extendedApi = observabilityApi.injectEndpoints({
  endpoints: (builder) => ({
    getActiveUsers: builder.query<{ total: number; users: IUserData[] }, IDataProductsRequestParams>({
      query: (params) => ({
        url: 'products/overview/active_users',
        params: { ...params },
      }),
      providesTags: ['UniObs'],
    }),
    getBusinessValue: builder.query<IRawBusinessValueData[], IDataProductsRequestParams>({
      query: (params) => ({
        url: 'products/overview/cost_over_business_value',
        params: { ...params },
      }),
      providesTags: ['UniObs'],
    }),
    getDataProductAnalysis: builder.query<IDataProductAnalysis[], IDataProductsRequestParamsWithBins>({
      query: (params) => ({
        url: 'products/overview/top_data_product_usage',
        params: { ...params },
      }),
      providesTags: ['UniObs'],
    }),
    getProductionCost: builder.query<IProductionCost, IDataProductsRequestParamsWithBins>({
      query: (params) => ({
        url: 'products/overview/production_cost',
        params: { ...params },
      }),
      providesTags: ['UniObs'],
    }),
    getTopDataProducts: builder.query<IDataProduct[], IDataProductsRequestParams>({
      query: (params) => ({
        url: 'products/overview/top_data_products',
        params: { ...params },
      }),
      providesTags: ['UniObs'],
    }),
    getTopDataProductUsage: builder.query<ITopDataProductUsage[], IDataProductsRequestParamsWithBins>({
      query: (params) => ({
        url: 'products/overview/top_data_product_usage',
        params: { ...params },
      }),
      providesTags: ['UniObs'],
    }),
    getTotalCostOverTime: builder.query<ITotalCostOverTime[], IDataProductsRequestParamsWithBins>({
      query: (params) => ({
        url: 'products/overview/cost_over_time',
        params: { ...params },
      }),
      providesTags: ['UniObs'],
    }),
  }),
});

export const {
  useGetActiveUsersQuery,
  useGetBusinessValueQuery,
  useGetDataProductAnalysisQuery,
  useGetProductionCostQuery,
  useGetTopDataProductsQuery,
  useGetTopDataProductUsageQuery,
  useGetTotalCostOverTimeQuery,
} = extendedApi;
