import React, { useEffect, useState } from 'react';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import Card from 'components/Card';
import CardTitle from 'components/CardTitle';
import QueriesByTypeTreemap from './QueriesByTypeTreemap';
import DetailsButton from './DetailsButton';
import NoRecentActivity from 'components/NoRecentActivity';
import { useAppSelector } from 'app/hooks';
import { selectSelectedAccounts, selectSelectedWarehouses, selectShowDollars } from '../reducers/finobsSlice';
import { selectStartDate, selectEndDate } from 'app/sharedSlice';
import { useGetWarehousesBySizeQuery } from 'app/spendviewApis';
import { ICategory } from 'utils/types';
import { IHierarchicalData } from './QueriesByTypeCard';

const WarehouseSizeCard = (props: { style: string }): JSX.Element => {
  const [processedData, setProcessedData] = useState<IHierarchicalData[]>([]);
  const selectedAccounts = useAppSelector(selectSelectedAccounts);
  const selectedWarehouses = useAppSelector(selectSelectedWarehouses);
  const startDate = useAppSelector(selectStartDate);
  const endDate = useAppSelector(selectEndDate);
  const showDollars = useAppSelector(selectShowDollars);

  const { data, isFetching, isError } = useGetWarehousesBySizeQuery({
    startDate,
    endDate,
    warehouses: selectedWarehouses,
    aggregate_accounts: true,
    aggregate_by_accounts: selectedAccounts,
    in_dollars: showDollars,
  });

  useEffect(() => {
    if (data !== undefined) {
      if (data.items.length === 0) {
        setProcessedData([]);
      } else {
        const total = data.items.reduce((partialSum: number, a: ICategory) => partialSum + a.value, 0);
        const tmpProcessedData: IHierarchicalData[] = [{ id: 'All', parent: null, size: null, percent: null }];
        data.items.forEach((d: ICategory) => {
          tmpProcessedData.push({ id: d.category, parent: 'All', size: d.value, percent: (d.value / total) * 100 });
        });
        tmpProcessedData.sort((a: IHierarchicalData, b: IHierarchicalData) => (a.percent ?? 0) - (b.percent ?? 0));
        setProcessedData(tmpProcessedData);
      }
    }
  }, [data]);

  return (
    <Card style={props.style} isLoading={isFetching} isError={isError}>
      <div className="flex">
        <CardTitle
          title="Warehouses by size"
          subtitleText="Percentage distribution of warehouse by size"
          tooltipText="Size specifies the amount of compute resources available per cluster in a warehouse"
        />
      </div>
      <div className="w-full h-[85%]">
        {processedData.length !== 0 && (
          <ParentSize>
            {(parent) => <QueriesByTypeTreemap data={processedData} width={parent.width} height={parent.height} />}
          </ParentSize>
        )}
        {processedData.length === 0 && !isFetching && !isError && <NoRecentActivity />}
      </div>
      <DetailsButton
        data={data !== undefined ? data.items : []}
        isTimeseriesArray={false}
        componentName="Warehouse size"
      />
    </Card>
  );
};

export default WarehouseSizeCard;
