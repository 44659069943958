import React, { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { useAppSelector } from 'app/hooks';
import { selectIsAdmin } from 'features/authentication/reducers/userSlice';
import { classNames } from 'utils/styleUtils';

const AppSwitcher = (): JSX.Element => {
  const isAdmin = useAppSelector(selectIsAdmin);

  const operateLink = process.env.REACT_APP_ENV === 'prod' ? 'https://app.dataops.live' : 'https://app.qa.dataops.live';
  const manageLink =
    process.env.REACT_APP_ENV === 'prod' ? 'https://manage.dataops.live' : 'https://manage.qa.dataops.live';

  const apps = [
    { name: 'Operate', href: operateLink, openInNewTab: false, hidden: false },
    { name: 'Manage', href: manageLink, openInNewTab: false, hidden: !isAdmin },
    { name: 'Learn', href: 'https://docs.dataops.live/', openInNewTab: true, hidden: false },
    { name: 'Engage', href: 'https://community.dataops.live/', openInNewTab: true, hidden: false },
  ];

  return (
    <Listbox>
      {({ open }) => (
        <div className="relative pr-5 text-sm text-gray-100 border-r border-secondary-text">
          <Listbox.Button
            className={classNames(
              open ? 'bg-gray-700' : '',
              'relative w-full cursor-pointer flex justify-center rounded-lg hover:bg-gray-700 p-1.5',
            )}
          >
            <img className="w-5 h-5 text-white" src="/dot-grid.svg" />
          </Listbox.Button>

          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute z-10 mt-2 max-h-60 w-32 overflow-auto rounded-md bg-dataops-dark text-sm shadow-lg border-[1px] border-dataops-primary-blue">
              {apps
                .filter((app) => !app.hidden)
                .map((app) => (
                  <Listbox.Option
                    key={app.name}
                    className={({ active }) =>
                      classNames(
                        active ? 'bg-dataops-secondary-blue text-white' : 'text-gray-100',
                        'relative cursor-default select-none py-2 pl-3 pr-9',
                      )
                    }
                    value={app}
                  >
                    <a
                      href={app.href}
                      target={app.openInNewTab ? '_blank' : '_self'}
                      rel="noreferrer"
                      className="block truncate font-normal"
                    >
                      {app.name}
                    </a>
                  </Listbox.Option>
                ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
};

export default AppSwitcher;
