import { observabilityApi } from './apis';
import { IPipelineRunsOverview } from 'features/pipelines/components/PipelineRunsOverviewCard';
import { IJobCount } from 'features/pipelines/components/JobsOverviewCard';

interface IPipelineMonitoringRequestParams {
  startDate: string;
  endDate: string;
}

const extendedApi = observabilityApi.injectEndpoints({
  endpoints: (builder) => ({
    getPipelineRuns: builder.query<IPipelineRunsOverview, IPipelineMonitoringRequestParams>({
      query: (params) => ({
        url: 'pipelines_router/get_pipeline_runs',
        params: { ...params },
      }),
      providesTags: ['UniObs'],
    }),
    getJobCount: builder.query<IJobCount, IPipelineMonitoringRequestParams>({
      query: (params) => ({
        url: 'pipelines_router/get_job_count',
        params: { ...params },
      }),
      providesTags: ['UniObs'],
    }),
  }),
});

export const { useGetPipelineRunsQuery, useGetJobCountQuery } = extendedApi;
