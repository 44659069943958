import React from 'react';
import { ITopDataProductUsage } from './TopDataProductUsageCard';
import { ITimeseriesElement } from 'utils/types';
import { range } from 'lodash';
import {
  AnimatedAxis, // any of these can be non-animated equivalents
  AnimatedGrid,
  AnimatedLineSeries,
  XYChart,
  Tooltip,
} from '@visx/xychart';
import { LegendItem, LegendLabel, LegendOrdinal } from '@visx/legend';
import { scaleOrdinal } from '@visx/scale';
import { defaultStyles } from '@visx/tooltip';
import { formatDate, formatNumberShort } from 'utils/formatUtils';

const legendGlyphSize = 12;
interface TopDataProductUsageChartProps {
  data: ITopDataProductUsage[];
  width: number;
  height: number;
}

const tooltipStyles = {
  ...defaultStyles,
  minWidth: 120,
  backgroundColor: '#27262c',
  color: 'white',
  fontSize: 12,
};

const TopDataProductUsageChart = (props: TopDataProductUsageChartProps): JSX.Element => {
  let maxValue = 0;
  props.data.forEach((d) => {
    d.timeseries.forEach((t) => {
      if (t.value > maxValue) {
        maxValue = t.value;
      }
    });
  });
  const log = Math.round(Math.log10(maxValue));
  let step = Math.pow(10, log);
  let maxTickValue = Math.round(maxValue / step) * step;
  let tickValues = range(0, maxTickValue, step);
  if (tickValues.length < 2) {
    step /= 2;
    maxTickValue = Math.round(maxValue / step) * step;
    tickValues = range(0, maxTickValue, step);
  }
  const colorScale = scaleOrdinal<string, string>({
    domain: props.data.map((d) => d.data_product),
    range: ['#0b7285', '#66d9e8', '#fcc418'],
  });

  return (
    <>
      <div className="text-sm">
        <LegendOrdinal scale={colorScale}>
          {(labels) => (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {labels.map((label, i) => (
                <LegendItem key={`legend-quantile-${i}`} margin="0 5px">
                  <svg width={legendGlyphSize} height={legendGlyphSize}>
                    <rect fill={label.value} width={legendGlyphSize} height={legendGlyphSize} />
                  </svg>
                  <LegendLabel align="left" margin="0 0 0 4px">
                    {label.text}
                  </LegendLabel>
                </LegendItem>
              ))}
            </div>
          )}
        </LegendOrdinal>
      </div>
      <XYChart width={props.width} height={props.height} xScale={{ type: 'band' }} yScale={{ type: 'linear' }}>
        <AnimatedAxis orientation="bottom" stroke="#87858d" tickStroke="#87858d" />
        <AnimatedAxis
          orientation="left"
          tickValues={tickValues}
          tickFormat={formatNumberShort}
          stroke="#87858d"
          tickStroke="#87858d"
          tickLabelProps={() => ({
            fontSize: 11,
            color: '#87858d',
          })}
        />
        <AnimatedGrid
          columns={true}
          numTicks={5}
          lineStyle={{ stroke: '#87858d', strokeWidth: '1px', strokeOpacity: 0.2 }}
        />
        <AnimatedLineSeries
          dataKey={props.data[0].data_product}
          data={props.data[0].timeseries}
          xAccessor={(d) => formatDate(d.date)}
          yAccessor={(d) => d.value}
        />
        <AnimatedLineSeries
          dataKey={props.data[1].data_product}
          data={props.data[1].timeseries}
          xAccessor={(d) => formatDate(d.date)}
          yAccessor={(d) => d.value}
        />
        <AnimatedLineSeries
          dataKey={props.data[2].data_product}
          data={props.data[2].timeseries}
          xAccessor={(d) => formatDate(d.date)}
          yAccessor={(d) => d.value}
        />
        <Tooltip<ITimeseriesElement>
          snapTooltipToDatumX
          snapTooltipToDatumY
          showVerticalCrosshair
          showDatumGlyph
          style={tooltipStyles}
          renderTooltip={({ tooltipData }) => (
            <div>
              <div>{tooltipData !== undefined ? tooltipData.nearestDatum?.key : ''}</div>
              <div>Date: {tooltipData !== undefined ? formatDate(tooltipData.nearestDatum?.datum.date ?? '') : ''}</div>
              <div>
                Value: ${tooltipData !== undefined ? formatNumberShort(tooltipData.nearestDatum?.datum.value ?? 0) : ''}
              </div>
            </div>
          )}
        />
      </XYChart>
    </>
  );
};

export default TopDataProductUsageChart;
