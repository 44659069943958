import React, { useEffect, useState, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  selectSelectedAccounts,
  selectSelectedWarehouses,
  selectShowDollars,
  selectTabs,
  setTabs,
  setShowError,
} from '../features/finobs/reducers/finobsSlice';
import { selectStartDate, selectEndDate } from 'app/sharedSlice';
import {
  useLazyGetAccountReportQuery,
  useLazyGetComputeReportQuery,
  useLazyGetStorageReportQuery,
  useLazyGetUsageReportQuery,
} from 'app/spendviewApis';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import TotalSpendByAccountTreemapCard from 'features/finobs/components/TotalSpendByAccountTreemapCard';
import TotalSpendPerAccount from 'features/finobs/components/TotalSpendPerAccount';
import TotalComputeAndStorageCostCard from 'features/finobs/components/TotalComputeAndStorageCostCard';
import TotalComputeAndStorageCostDonutCard from 'features/finobs/components/TotalComputeAndStorageCostDonutCard';
import ComputePerAccountCard from 'features/finobs/components/ComputePerAccountCard';
import StoragePerAccountCard from 'features/finobs/components/StoragePerAccountCard';
import TotalUsersOverTimeCard from 'features/finobs/components/TotalUsersOverTimeCard';
import UsersPerAccountCard from 'features/finobs/components/UsersPerAccountCard';
import CostPerWarehouseCard from 'features/finobs/components/CostPerWarehouseCard';
import UsagePerWarehouseHeatmapCard from 'features/finobs/components/UsagePerWarehouseHeatmapCard';
import UsagePerWarehouseTimeseriesCard from 'features/finobs/components/UsagePerWarehouseTimeseriesCard';
import WarehouseActivityCard from 'features/finobs/components/WarehouseActivityCard';
import CostTimeseriesCard from 'features/finobs/components/CostTimeseriesCard';
import WarehouseSizeCard from 'features/finobs/components/WarehouseSizeCard';
import StorageByTypeCard from 'features/finobs/components/StorageByTypeCard';
import StorageByTypeByDBCard from 'features/finobs/components/StorageByTypeByDBCard';
import StorageCostCard from 'features/finobs/components/StorageCostCard';
import StageCostCard from 'features/finobs/components/StageCostCard';
import FailsafeCostCard from 'features/finobs/components/FailsafeCostCard';
import UsagePerUserCard from 'features/finobs/components/UsagePerUserCard';
import UsagePerDatabaseCard from 'features/finobs/components/UsagePerDatabaseCard';
import QueriesByTypeChart from 'features/finobs/components/QueriesByTypeCard';
import UsageErrorCard from 'features/finobs/components/UsageErrorCard';
import WarehouseSpillingCard from 'features/finobs/components/WarehouseSpillingCard';
import WarehouseQueueingCard from 'features/finobs/components/WarehouseQueueingCard';
import ExportCSV from 'components/ExportCSV';
import { classNames } from 'utils/styleUtils';

export interface IAccountReport {
  account: string;
  credits_used_anually: number;
  credits_used_per_period: number;
  total_annual_cost: number;
  total_compute_cost_per_period: number;
  total_storage_cost_per_period: number;
  monthly_cost: number;
  monthly_increment: number;
  avg_daily_cost: number;
  number_of_users_per_period: number;
  total_number_of_warehouses: number;
  number_of_active_warehouses: number;
}

export interface IComputeReport {
  account: string;
  warehouse_name: string;
  status_per_period: string;
  credits_used_per_period: number;
  compute_cost_per_period: number;
  query_cost_per_period: number;
  numbers_of_users: number;
  spilling_to_local_storage: number;
  spilling_to_remote_storage: number;
  queuing: number;
  success_perc_of_queries: number;
  failed_perc_of_queries: number;
}

export interface IStorageReport {
  account: string;
  clone_storage_tb: number;
  time_travel_storage_tb: number;
  failsafe_storage_tb: number;
  total_storage_cost: number;
  monthly_increment: number;
  avg_daily_storage_cost: number;
  avg_monthly_storage_cost: number;
  avg_monthly_stage_cost: number;
  avg_monthly_failsafe_cost: number;
}

export interface IUsageReport {
  account: string;
  username: string;
  total_cost_per_period: number;
  total_compute_time_per_period: number;
  number_of_queries_run_per_period: number;
  success_perc_of_queries: number;
  failed_perc_of_queries: number;
  databases_used: string[];
}

export default function FinObsDetailsTab(): JSX.Element {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [componentName, setComponentName] = useState<string>();
  const [data, setData] = useState<any[]>([]);
  const [sortBy, setSortBy] = useState<string>();
  const [isAscendingOrder, setIsAscendingOrder] = useState<boolean>(true);
  const selectedAccounts = useAppSelector(selectSelectedAccounts);
  const selectedWarehouses = useAppSelector(selectSelectedWarehouses);
  const startDate = useAppSelector(selectStartDate);
  const endDate = useAppSelector(selectEndDate);
  const showDollars = useAppSelector(selectShowDollars);
  const tabs = useAppSelector(selectTabs);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [getAccountReport] = useLazyGetAccountReportQuery();
  const [getComputeReport] = useLazyGetComputeReportQuery();
  const [getStorageReport] = useLazyGetStorageReportQuery();
  const [getUsageReport] = useLazyGetUsageReportQuery();

  const { component, sourceTab } = useMemo(() => {
    switch (componentName) {
      case 'total-spend-by-account':
        return { component: <TotalSpendByAccountTreemapCard style="h-[25rem] w-1/2" />, sourceTab: 'Overview' };
      case 'total-spend-per-account':
        return { component: <TotalSpendPerAccount style="h-[25rem]" />, sourceTab: 'Overview' };
      case 'total-compute-and-storage':
        return {
          component: <TotalComputeAndStorageCostCard isDetailsView={true} style="h-[25rem]" />,
          sourceTab: 'Overview',
        };
      case 'compute-and-storage':
        return {
          component: <TotalComputeAndStorageCostDonutCard style="w-[40rem] h-[25rem]" />,
          sourceTab: 'Overview',
        };
      case 'compute-by-account':
        return {
          component: <ComputePerAccountCard isDetailsView={true} style="h-[20rem]" />,
          sourceTab: 'Overview',
        };
      case 'storage-by-account':
        return {
          component: <StoragePerAccountCard isDetailsView={true} style="h-[20rem]" />,
          sourceTab: 'Overview',
        };
      case 'users-over-time':
        return {
          component: <TotalUsersOverTimeCard isDetailsView={true} style="h-[20rem]" />,
          sourceTab: 'Overview',
        };
      case 'users-per-account':
        return {
          component: <UsersPerAccountCard isDetailsView={true} style="h-[20rem]" />,
          sourceTab: 'Overview',
        };
      case 'cost-per-warehouse':
        return {
          component: <CostPerWarehouseCard isDetailsView={true} style="h-[20rem]" />,
          sourceTab: 'Compute',
        };
      case 'warehouse-comparison':
        return {
          component: <UsagePerWarehouseHeatmapCard isDetailsView={true} style="h-[25rem]" />,
          sourceTab: 'Compute',
        };
      case 'warehouse-usage':
        return {
          component: <UsagePerWarehouseTimeseriesCard isDetailsView={true} style="h-[25rem]" />,
          sourceTab: 'Compute',
        };
      case 'warehouse-activity':
        return {
          component: <WarehouseActivityCard style="w-[30rem] h-[20rem]" />,
          sourceTab: 'Compute',
        };
      case 'cost-timeseries':
        return {
          component: <CostTimeseriesCard style="h-[25rem]" />,
          sourceTab: 'Compute',
        };
      case 'warehouse-size':
        return {
          component: <WarehouseSizeCard style="w-[50rem] h-[25rem]" />,
          sourceTab: 'Compute',
        };
      case 'storage-by-type':
        return {
          component: <StorageByTypeCard style="w-[50rem] h-[25rem]" />,
          sourceTab: 'Storage',
        };
      case 'storage-by-type-by-db':
        return {
          component: <StorageByTypeByDBCard isDetailsView={true} style="h-[25rem]" />,
          sourceTab: 'Storage',
        };
      case 'storage-cost':
        return {
          component: <StorageCostCard isDetailsView={true} style="h-[25rem]" />,
          sourceTab: 'Storage',
        };
      case 'stage-cost':
        return {
          component: <StageCostCard isDetailsView={true} style="h-[25rem]" />,
          sourceTab: 'Storage',
        };
      case 'failsafe-cost':
        return {
          component: <FailsafeCostCard isDetailsView={true} style="h-[25rem]" />,
          sourceTab: 'Storage',
        };
      case 'usage-per-user':
        return {
          component: <UsagePerUserCard isDetailsView={true} style="h-[25rem]" />,
          sourceTab: 'Usage',
        };
      case 'usage-per-db':
        return {
          component: <UsagePerDatabaseCard isDetailsView={true} style="h-[25rem]" />,
          sourceTab: 'Usage',
        };
      case 'queries-by-type':
        return {
          component: <QueriesByTypeChart style="w-[50rem] h-[25rem]" />,
          sourceTab: 'Usage',
        };
      case 'usage-error':
        return {
          component: <UsageErrorCard style="w-[30rem] h-[20rem]" />,
          sourceTab: 'Usage',
        };
      case 'warehouse-spilling':
        return {
          component: <WarehouseSpillingCard isDetailsView={true} style="h-[20rem]" />,
          sourceTab: 'Usage',
        };
      case 'warehouse-queueing':
        return {
          component: <WarehouseQueueingCard isDetailsView={true} style="h-[20rem]" />,
          sourceTab: 'Usage',
        };
      default:
        return { component: null, sourceTab: null };
    }
  }, [componentName]);

  useEffect(() => {
    const splitLocation = location.pathname.split('/');
    if (splitLocation.length > 3) {
      setComponentName(splitLocation[3]);

      if (tabs.length === 3 || (tabs.length === 4 && tabs[3].href !== location.pathname)) {
        let tabName = splitLocation[3].replaceAll('-', ' ');
        tabName = tabName.charAt(0).toUpperCase() + tabName.slice(1);
        dispatch(
          setTabs([
            { name: 'Overview', href: '/finobs' },
            { name: 'Compute', href: '/finobs/compute-costs' },
            { name: 'Storage', href: '/finobs/storage-costs' },
            { name: 'Usage', href: '/finobs/usage-costs' },
            {
              name: tabName,
              href: location.pathname,
            },
          ]),
        );
      }
    }
  }, [location]);

  useEffect(() => {
    dispatch(setShowError(false));
  });

  useEffect(() => {
    switch (sourceTab) {
      case 'Overview':
        setIsLoading(true);
        getAccountReport(
          {
            startDate,
            endDate,
            warehouses: selectedWarehouses,
            aggregate_accounts: true,
            aggregate_by_accounts: selectedAccounts,
            in_dollars: showDollars,
          },
          true,
        )
          .unwrap()
          .then((response) => {
            setData(response);
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            setIsLoading(false);
          });
        break;
      case 'Compute':
        setIsLoading(true);
        getComputeReport(
          {
            startDate,
            endDate,
            warehouses: selectedWarehouses,
            aggregate_accounts: true,
            aggregate_by_accounts: selectedAccounts,
            in_dollars: showDollars,
          },
          true,
        )
          .unwrap()
          .then((response) => {
            setData(response);
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            setIsLoading(false);
          });
        break;
      case 'Storage':
        setIsLoading(true);
        getStorageReport(
          {
            startDate,
            endDate,
            warehouses: selectedWarehouses,
            aggregate_accounts: true,
            aggregate_by_accounts: selectedAccounts,
            in_dollars: showDollars,
          },
          true,
        )
          .unwrap()
          .then((response) => {
            setData(response);
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            setIsLoading(false);
          });
        break;
      case 'Usage':
        setIsLoading(true);
        getUsageReport(
          {
            startDate,
            endDate,
            warehouses: selectedWarehouses,
            aggregate_accounts: true,
            aggregate_by_accounts: selectedAccounts,
            in_dollars: showDollars,
          },
          true,
        )
          .unwrap()
          .then((response) => {
            const tmpData = response.map((d) => ({ ...d, databases_used: d.databases_used.join(', ') }));
            setData(tmpData);
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            setIsLoading(false);
          });
        break;
    }
  }, [sourceTab, startDate, endDate]);

  const keys = useMemo(() => (data.length === 0 ? [] : Object.keys(data[0])), [data]);

  const sortByKey = (key: string): void => {
    const tmpData = data.slice();
    if (isAscendingOrder) {
      if (typeof data[0][key] === 'string') {
        tmpData.sort((a, b) => {
          if (a[key] > b[key]) {
            return 1;
          } else if (a[key] < b[key]) {
            return -1;
          }
          return 0;
        });
      } else {
        tmpData.sort((a, b) => b[key] - a[key]);
      }
    } else {
      if (typeof data[0][key] === 'string') {
        tmpData.sort((a, b) => {
          if (b[key] > a[key]) {
            return 1;
          } else if (b[key] < a[key]) {
            return -1;
          }
          return 0;
        });
      } else {
        tmpData.sort((a, b) => a[key] - b[key]);
      }
    }
    if (key === sortBy) {
      setIsAscendingOrder(!isAscendingOrder);
    } else {
      setSortBy(key);
      setIsAscendingOrder(true);
    }
    setData(tmpData);
  };

  return (
    <div className="w-full mt-[3.2rem] mx-auto py-6 px-dashboard-side bg-dashboard-background">
      <div>{component}</div>
      {data.length === 0 && isLoading && (
        <div className="w-full flex items-center justify-center">
          <div
            className="animate-spin inline-block w-20 h-20 mt-8 border-[3px] border-current border-t-transparent text-cyan-800 rounded-full"
            role="status"
            aria-label="loading"
          >
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
      {data.length !== 0 && (
        <div className="-my-2 -mx-6 lg:-mx-8 mt-4">
          <div className="flex justify-start pt-4 px-dashboard-side">
            <div className="text-xl text-gray-600 pr-4">{sourceTab} details</div>
            <ExportCSV data={data} filename={`${sourceTab as string}-details`} />
          </div>
          <div
            className={classNames(
              'w-full py-4 align-middle px-dashboard-side',
              isLoading ? 'animate-[pulse_1.5s_cubic-bezier(0.4,_0,_0.6,_0.1)_infinite]' : '',
            )}
          >
            <table className="rounded-md w-full divide-y divide-gray-300">
              <thead>
                <tr className="divide-x divide-gray-200">
                  {keys.map((key, index) => (
                    <th
                      key={index}
                      scope="col"
                      className="sticky top-[7.3rem] bg-white py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 group"
                    >
                      <div className="flex truncate">
                        {key}
                        <span
                          className={classNames(
                            key === sortBy ? 'visible' : 'invisible',
                            'ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible',
                          )}
                        >
                          {key === sortBy && isAscendingOrder ? (
                            <ChevronDownIcon className="h-5 w-5" aria-hidden="true" onClick={() => sortByKey(key)} />
                          ) : (
                            <ChevronUpIcon className="h-5 w-5" aria-hidden="true" onClick={() => sortByKey(key)} />
                          )}
                        </span>
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {data.map((row, index) => (
                  <tr
                    key={index}
                    className={classNames(index % 2 === 1 ? undefined : 'bg-gray-50', 'divide-x divide-gray-200')}
                  >
                    {keys.map((key, keyIndex) => (
                      <td key={keyIndex} className="whitespace-nowrap p-4 text-sm text-gray-500 truncate">
                        {row[key]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}
