import React, { useMemo } from 'react';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { Bar } from '@visx/shape';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { useTooltip, useTooltipInPortal, defaultStyles } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import { formatNumberLong, formatNumberShort, formatTickLabelCost } from 'utils/formatUtils';
import colors from 'utils/colors';
import { ICategoryWithPercent, IColorConfig } from 'utils/types';

const margin = {
  left: 40,
  right: 20,
  bottom: 0,
  top: 50,
};

interface WarehouseActivityBarChartProps {
  data: ICategoryWithPercent[];
  colors: IColorConfig;
  valueLabel: string;
  valueUnit?: string;
  width: number;
  height: number;
}

interface TooltipData {
  category: string;
  value: number;
  percent: number;
}

const tooltipStyles = {
  ...defaultStyles,
  backgroundColor: '#27262c',
  color: 'white',
  fontSize: 12,
};

const WarehouseActivityBarChart = (props: WarehouseActivityBarChartProps): JSX.Element => {
  const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } = useTooltip<TooltipData>();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });

  const xMax = props.width - margin.left - margin.right;
  const yMax = props.height - margin.top - margin.bottom;

  const colorScale = useMemo(
    () =>
      scaleOrdinal<string, string>({
        domain: props.data.map((d) => d.category),
        range: props.data.map((d) => props.colors[d.category]),
      }),
    [props.data, props.colors],
  );

  const xScale = useMemo(
    () =>
      scaleBand<string>({
        range: [0, xMax],
        round: true,
        domain: props.data.map((d) => d.category),
        padding: 0.4,
      }),
    [xMax, props.data],
  );
  const yScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [yMax, 0],
        round: true,
        domain: [0, Math.max(...props.data.map((d) => d.value))],
      }),
    [yMax, props.data],
  );

  return (
    <svg ref={containerRef} width={props.width} height={props.height}>
      <Group top={margin.top} left={margin.left}>
        {props.data.map((d) => {
          const barWidth = xScale.bandwidth();
          const barHeight = yMax - yScale(d.value) ?? 0;
          const barX = xScale(d.category);
          const barY = yMax - barHeight;
          return (
            <Bar
              key={d.category}
              x={barX}
              y={barY}
              width={barWidth}
              height={barHeight}
              fill={colorScale(d.category)}
              rx={3}
              onMouseLeave={() => {
                hideTooltip();
              }}
              onMouseMove={(event) => {
                const eventSvgCoords = localPoint(event);
                showTooltip({
                  tooltipData: d,
                  tooltipTop: eventSvgCoords?.y,
                  tooltipLeft: eventSvgCoords?.x,
                });
              }}
            />
          );
        })}
        <AxisLeft
          scale={yScale}
          stroke={colors.axisColor}
          tickStroke={colors.axisColor}
          numTicks={3}
          tickFormat={(value) =>
            props.valueUnit === '$' ? formatTickLabelCost(value as number) : formatNumberShort(value as number)
          }
          tickLabelProps={() => ({
            fill: colors.tickLabelColor,
            fontSize: 11,
            textAnchor: 'end',
            dy: '0.33em',
            dx: '-0.25em',
          })}
          hideZero
        />
        <AxisBottom top={yMax} scale={xScale} stroke={colors.axisColor} tickStroke={colors.axisColor} hideTicks />
        {tooltipOpen && tooltipData !== undefined && (
          <TooltipInPortal top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
            <div className="flex">
              <div>{tooltipData.category}</div>
            </div>
            <div className="flex">
              <div className="pr-2">{props.valueLabel}: </div>
              <div>{`${props.valueUnit ?? ''}${formatNumberLong(tooltipData.value)}`}</div>
            </div>
            <div className="flex">
              <div className="pr-2">Percent: </div>
              <div>{formatNumberLong(tooltipData.percent)}%</div>
            </div>
          </TooltipInPortal>
        )}
      </Group>
    </svg>
  );
};

export default WarehouseActivityBarChart;
