import React, { useMemo } from 'react';
import { ITimeseries, ITimeseriesElement } from 'utils/types';
import { AnimatedAxis, AnimatedGrid, AnimatedLineSeries, XYChart, Tooltip } from '@visx/xychart';
import { curveMonotoneX } from '@visx/curve';
import { LegendItem, LegendLabel, LegendOrdinal } from '@visx/legend';
import { scaleOrdinal } from '@visx/scale';
import { defaultStyles } from '@visx/tooltip';
import { formatTickLabelCost, formatNumberShort, truncateString } from 'utils/formatUtils';
import colors from 'utils/colors';
import { useAppSelector } from 'app/hooks';
import { selectStartDate, selectEndDate } from 'app/sharedSlice';
import { getTickLabelPrecision, getTickValues, formatTickDate, formatTickLabelDate } from 'utils/dateUtils';
import { NumberValue } from 'd3';

const legendGlyphSize = 12;
interface UsagePerWarehouseTimeseriesChartProps {
  data: ITimeseries[];
  useDollars: boolean;
  width: number;
  height: number;
}

const tooltipStyles = {
  ...defaultStyles,
  minWidth: 120,
  backgroundColor: '#27262c',
  color: 'white',
  fontSize: 12,
};

const UsagePerWarehouseTimeseriesChart = (props: UsagePerWarehouseTimeseriesChartProps): JSX.Element => {
  const startDate = useAppSelector(selectStartDate);
  const endDate = useAppSelector(selectEndDate);
  const start = new Date(startDate);
  const end = new Date(endDate);
  const differenceInHours = (end.getTime() - start.getTime()) / (1000 * 60 * 60);
  const tickLabelPrecision = useMemo(() => getTickLabelPrecision(differenceInHours), [props.data]);
  const allTickValues = props.data[0].timeseries.map((d) => d.date);
  const tickValues = useMemo(
    () => getTickValues(allTickValues, tickLabelPrecision).map((d) => new Date(d)),
    [props.data],
  );

  const colorScale = scaleOrdinal<string, string>({
    domain: props.data.map((d) => d.category),
    range: ['#0b7285', '#66d9e8', '#fcc418', '#ff8787', '#9c36b5', '#cc5de8', '#a61e4d'],
  });

  const tickFormatFunction = props.useDollars ? formatTickLabelCost : (d: NumberValue) => `${d as number}`;

  return (
    <>
      <div className="text-sm">
        <LegendOrdinal scale={colorScale}>
          {(labels) => (
            <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', overflow: 'hidden' }}>
              {labels.map((label, i) => (
                <LegendItem key={`legend-quantile-${i}`} margin="0 5px">
                  <svg width={legendGlyphSize} height={legendGlyphSize}>
                    <rect fill={label.value} width={legendGlyphSize} height={legendGlyphSize} />
                  </svg>
                  <LegendLabel className="overflow-hidden" align="left" margin="0 0 0 4px">
                    <div className="truncate">{label.text}</div>
                  </LegendLabel>
                </LegendItem>
              ))}
            </div>
          )}
        </LegendOrdinal>
      </div>
      <XYChart
        width={props.width}
        height={props.height}
        xScale={{ type: 'time' }}
        yScale={{ type: 'linear', nice: true }}
        margin={{ top: 20, bottom: 50, left: 40, right: 40 }}
      >
        <AnimatedAxis
          orientation="bottom"
          tickValues={tickValues}
          tickFormat={(d) => formatTickDate(d, tickLabelPrecision)}
          stroke={colors.axisColor}
          tickStroke={colors.axisColor}
        />
        <AnimatedAxis
          orientation="left"
          numTicks={5}
          tickFormat={tickFormatFunction}
          stroke={colors.axisColor}
          tickStroke={colors.axisColor}
          tickLabelProps={() => ({
            fontSize: 11,
            color: '#87858d',
          })}
        />
        <AnimatedGrid
          columns={false}
          numTicks={5}
          lineStyle={{ stroke: '#87858d', strokeWidth: '1px', strokeOpacity: 0.2 }}
        />
        <>
          {props.data.map((warehouseData, index) => (
            <AnimatedLineSeries
              curve={curveMonotoneX}
              key={index}
              dataKey={warehouseData.category}
              data={warehouseData.timeseries}
              xAccessor={(d) => new Date(d.date)}
              yAccessor={(d) => d.value}
            />
          ))}
        </>
        <Tooltip<ITimeseriesElement>
          snapTooltipToDatumX
          snapTooltipToDatumY
          showVerticalCrosshair
          style={tooltipStyles}
          renderTooltip={({ tooltipData }) => (
            <div>
              <div>{tooltipData !== undefined ? truncateString(tooltipData.nearestDatum?.key ?? '', 25) : ''}</div>
              <div>
                Date:{' '}
                {tooltipData !== undefined
                  ? formatTickLabelDate(
                      tooltipData.nearestDatum?.datum.date ?? '',
                      tickLabelPrecision === 'Monthly' ? 'Daily' : 'Hourly',
                    )
                  : ''}
              </div>
              <div>
                Value: {props.useDollars ? '$' : ''}
                {tooltipData !== undefined ? formatNumberShort(tooltipData.nearestDatum?.datum.value ?? 0) : ''}
              </div>
            </div>
          )}
        />
      </XYChart>
    </>
  );
};

export default UsagePerWarehouseTimeseriesChart;
