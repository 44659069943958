import React, { useEffect, useState } from 'react';
import { ChartPieIcon, CurrencyDollarIcon } from '@heroicons/react/24/outline';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import Card from 'components/Card';
import BusinessValueBubbleChart from './BusinessValueBubbleChart';
import BusinessValueTable from './BusinessValueTable';
import BusinessValueDetailsTable from './BusinessValueDetailsTable';
import Dropdown from 'components/Dropdown';
import { useAppSelector } from 'app/hooks';
import { selectStartDate, selectEndDate, selectSelectedDataProducts } from '../reducers/dataProductsSlice';
import { selectSelectedProjects, selectSelectedEnvironments } from 'features/layouts/reducers/appBarSlice';
import { useGetBusinessValueQuery } from 'app/dataProductsApis';
import { stringArrayFromOptionArray } from 'utils/formatUtils';
import { classNames } from 'utils/styleUtils';
import { costTypeOptions } from 'utils/constants';

// const data = [
//   { name: 'Weekly mob orders', queries: 20, value: 10, cost: 69000 },
//   { name: 'Weekly new orders', queries: 60, value: 3, cost: 30000 },
//   { name: 'Weekly new customers', queries: 40, value: 8, cost: 15000 },
//   { name: 'Weekly new tables', queries: 20, value: 4, cost: 43000 },
//   { name: 'Weekly mob customers', queries: 40, value: 2, cost: 85000 },
// ];

type ValueCostCategory = 'LowLow' | 'LowHigh' | 'HighLow' | 'HighHigh';

export interface IRawBusinessValueData {
  name: string;
  queries: number;
  value: number;
  cost: number;
}

export interface ICostOverBusinessValue {
  name: string;
  queries: number;
  value: number;
  cost: number;
  valueCostCategory: ValueCostCategory;
  color: string;
}

const BusinessValueCard = (props: { style: string }): JSX.Element => {
  const [chartPanelActive, setChartPanelActive] = useState(true);
  const [costType, setCostType] = useState(costTypeOptions[0]);
  const [processedData, setProcessedData] = useState<ICostOverBusinessValue[]>([]);
  const [costLimit, setCostLimit] = useState(0);
  const [valueLimit, setValueLimit] = useState(0);
  const selectedProjects = useAppSelector(selectSelectedProjects);
  const selectedEnvironments = useAppSelector(selectSelectedEnvironments);
  const selectedDataProducts = useAppSelector(selectSelectedDataProducts);
  const startDate = useAppSelector(selectStartDate);
  const endDate = useAppSelector(selectEndDate);

  const { data } = useGetBusinessValueQuery({
    startDate,
    endDate,
    projectId: selectedProjects,
    env: stringArrayFromOptionArray(selectedEnvironments),
    data_product: selectedDataProducts,
    cost_type:
      costType === 'Total cost'
        ? 'total_cost'
        : costType === 'Production cost'
        ? 'production_cost'
        : 'consumption_cost',
  });

  useEffect(() => {
    if (data !== undefined) {
      const maxCost = Math.max(...data.map((d: IRawBusinessValueData) => d.cost));
      const maxValue = Math.max(...data.map((d: IRawBusinessValueData) => d.value));
      setCostLimit(maxCost / 2);
      setValueLimit((maxValue + 1) / 2);
      const tmpCostLimit = maxCost / 2;
      const tmpValueLimit = (maxValue + 1) / 2;

      let tmpProcessedData: ICostOverBusinessValue[] = [];
      data.forEach((d: IRawBusinessValueData) => {
        let color = '#6bcba0';
        let valueCostCategory: ValueCostCategory = 'HighLow';
        if (d.cost > tmpCostLimit && d.value < tmpValueLimit) {
          color = '#ea333d';
          valueCostCategory = 'LowHigh';
        } else if (d.cost > tmpCostLimit && d.value >= tmpValueLimit) {
          color = '#f89b22';
          valueCostCategory = 'HighHigh';
        } else if (d.cost <= tmpCostLimit && d.value < tmpValueLimit) {
          color = '#f89b22';
          valueCostCategory = 'LowLow';
        }

        tmpProcessedData.push({ ...d, valueCostCategory, color });
      });

      tmpProcessedData = tmpProcessedData.filter(
        (value, index, self) => index === self.findIndex((t) => t.name === value.name),
      );

      setProcessedData(tmpProcessedData);
    }
  }, [data]);

  const handleChange = (target: string): void => {
    setCostType(target);
  };

  return (
    <Card style={`flex flex-col ${props.style}`}>
      <div className="w-full h-8 flex items-center">
        <div className="w-2/3 flex items-center">
          <div className="flex-grow text-primary-text">Cost over Business Value for Data Products</div>
          <div className="ml-6 hidden items-center rounded-lg bg-gray-100 p-0.5 sm:flex">
            <button
              type="button"
              onClick={() => setChartPanelActive(true)}
              className={classNames(
                chartPanelActive ? 'bg-white' : '',
                ' rounded-md p-1.5 text-gray-400 hover:bg-white hover:shadow-sm focus:outline-none focus:ring-2 focus:ring-inset focus:ring-dark-card-background',
              )}
            >
              <ChartPieIcon className="h-5 w-5" aria-hidden="true" />
              <span className="sr-only">Use list view</span>
            </button>
            <button
              type="button"
              onClick={() => setChartPanelActive(false)}
              className={classNames(
                chartPanelActive ? '' : 'bg-white',
                'ml-0.5 rounded-md p-1.5 text-gray-400 shadow-sm focus:outline-none focus:ring-2 focus:ring-inset focus:ring-dark-card-background',
              )}
            >
              <CurrencyDollarIcon className="h-5 w-5" aria-hidden="true" />
              <span className="sr-only">Use grid view</span>
            </button>
          </div>
        </div>
        <div className="w-1/3 flex justify-end">
          <Dropdown options={costTypeOptions} value={costType} onChange={handleChange} />
        </div>
      </div>
      <div className="flex-grow flex items-center">
        <div className="w-2/3 h-[95%]">
          {data !== undefined && (
            <div className="w-full h-full pr-7">
              <ParentSize>
                {(parent) => {
                  if (chartPanelActive) {
                    return (
                      <BusinessValueBubbleChart
                        width={parent.width}
                        height={parent.height}
                        data={processedData}
                        costLimit={costLimit}
                        valueLimit={valueLimit}
                      />
                    );
                  } else {
                    return (
                      <BusinessValueTable
                        width={parent.width}
                        height={parent.height}
                        data={processedData}
                        costLimit={costLimit}
                        valueLimit={valueLimit}
                      />
                    );
                  }
                }}
              </ParentSize>
            </div>
          )}
        </div>
        <div className="w-1/3 h-[95%]">
          <BusinessValueDetailsTable data={processedData} />
        </div>
      </div>
    </Card>
  );
};

export default BusinessValueCard;
