import React, { Fragment, useState, useEffect } from 'react';
import axios from 'axios';
import { Dialog, Transition } from '@headlessui/react';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { selectOpenContactUsDialog, setOpenContactUsDialog } from 'features/layouts/reducers/appBarSlice';

const ContactUsDialog = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const openContactUsDialog = useAppSelector(selectOpenContactUsDialog);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [company, setCompany] = useState<string>('');
  const [jobTitle, setJobTitle] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [country, setCountry] = useState<string>('');
  const [state, setState] = useState<string>('');
  const [linkedIn, setLinkedIn] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);

  useEffect(() => {
    axios
      .get('/restapi/top_bar/get_hubspot_register_to_dataops_lead')
      .then((response) => {
        setFirstName(response.data.first_name);
        setLastName(response.data.last_name);
        setCompany(response.data.company);
        setJobTitle(response.data.job_title);
        setEmail(response.data.email);
        setPhone(response.data.phone);
        setCountry(response.data.country);
        setState(response.data.state);
        setLinkedIn(response.data.linkedin);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [openContactUsDialog]);

  const handleSubmit = (e: React.FormEvent): void => {
    e.preventDefault();
    setLoading(true);
    setFailed(false);
    axios
      .post('/restapi/top_bar/send_hubspot_register_to_dataops_lead', {
        first_name: firstName,
        last_name: lastName,
        company,
        job_title: jobTitle,
        email,
        phone,
        country,
        state,
        linkedin: linkedIn,
        message,
      })
      .then(() => {
        setSuccess(true);
        setTimeout(() => {
          dispatch(setOpenContactUsDialog(false));
          setFirstName('');
          setLastName('');
          setCompany('');
          setJobTitle('');
          setEmail('');
          setPhone('');
          setCountry('');
          setState('');
          setLinkedIn('');
          setMessage('');
          setLoading(false);
          setSuccess(false);
          setFailed(false);
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
        setFailed(true);
      });
  };

  const onClose = (): void => {
    dispatch(setOpenContactUsDialog(false));
    setFirstName('');
    setLastName('');
    setCompany('');
    setJobTitle('');
    setEmail('');
    setPhone('');
    setCountry('');
    setState('');
    setLinkedIn('');
    setMessage('');
    setLoading(false);
    setSuccess(false);
    setFailed(false);
  };

  return (
    <Transition.Root show={openContactUsDialog} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => onClose()}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full justify-center text-center items-start sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="w-full max-w-md mt-[8rem] relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 text-left shadow-xl transition-all">
                <div className="w-full max-w-md">
                  <div>
                    <h2 className="mt-2 text-center text-2xl font-bold tracking-tight text-gray-900">Contact Us</h2>
                  </div>
                  <div className="mt-1 h-6 flex items-center justify-center">
                    {loading && !failed && !success && (
                      <div
                        className="animate-spin inline-block w-6 h-6 border-[2px] border-current border-t-transparent text-cyan-800 rounded-full"
                        role="status"
                        aria-label="loading"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    )}
                    {success && (
                      <div className="text-sm w-full rounded bg-green-50 border-2 border-green-500 flex items-center justify-center">
                        <a className="font-medium text-green-600 hover:text-green-500">Message sent</a>
                      </div>
                    )}
                    {failed && (
                      <div className="text-sm w-full rounded bg-red-50 border-2 border-red-500 flex items-center justify-center">
                        <a className="font-medium text-red-600 hover:text-red-500">Failed to send message</a>
                      </div>
                    )}
                  </div>
                  <form className="mt-1 space-y-4" onSubmit={handleSubmit}>
                    <input type="hidden" name="remember" defaultValue="true" />
                    <div className="-space-y-px rounded-md shadow-sm">
                      <div className="flex rounded-md shadow-sm">
                        <span className="w-[7rem] whitespace-nowrap inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-2 text-gray-500 sm:text-sm">
                          First name
                        </span>
                        <input
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                          placeholder="First name"
                          required
                          className="grow relative block appearance-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
                        />
                      </div>
                      <div className="flex rounded-md shadow-sm">
                        <span className="w-[7rem] whitespace-nowrap inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-2 text-gray-500 sm:text-sm">
                          Last name
                        </span>
                        <input
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                          placeholder="Last name"
                          required
                          className="grow relative block appearance-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
                        />
                      </div>
                      <div className="flex rounded-md shadow-sm">
                        <span className="w-[7rem] whitespace-nowrap inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-2 text-gray-500 sm:text-sm">
                          Company
                        </span>
                        <input
                          value={company}
                          onChange={(e) => setCompany(e.target.value)}
                          placeholder="Company"
                          required
                          className="grow relative block appearance-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
                        />
                      </div>
                      <div className="flex rounded-md shadow-sm">
                        <span className="w-[7rem] whitespace-nowrap inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-2 text-gray-500 sm:text-sm">
                          Job title
                        </span>
                        <input
                          value={jobTitle}
                          onChange={(e) => setJobTitle(e.target.value)}
                          placeholder="Job title"
                          className="grow relative block appearance-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
                        />
                      </div>
                      <div className="flex rounded-md shadow-sm">
                        <span className="w-[7rem] whitespace-nowrap inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-2 text-gray-500 sm:text-sm">
                          Email
                        </span>
                        <input
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Email"
                          required
                          className="grow relative block appearance-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
                        />
                      </div>
                      <div className="flex rounded-md shadow-sm">
                        <span className="w-[7rem] whitespace-nowrap inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-2 text-gray-500 sm:text-sm">
                          Phone
                        </span>
                        <input
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                          placeholder="Phone"
                          required
                          className="grow relative block appearance-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
                        />
                      </div>
                      <div className="flex rounded-md shadow-sm">
                        <span className="w-[7rem] whitespace-nowrap inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-2 text-gray-500 sm:text-sm">
                          Country
                        </span>
                        <input
                          value={country}
                          onChange={(e) => setCountry(e.target.value)}
                          placeholder="Country"
                          required
                          className="grow relative block appearance-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
                        />
                      </div>
                      <div className="flex rounded-md shadow-sm">
                        <span className="w-[7rem] whitespace-nowrap inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-2 text-gray-500 sm:text-sm">
                          State
                        </span>
                        <input
                          value={state}
                          onChange={(e) => setState(e.target.value)}
                          placeholder="State"
                          className="grow relative block appearance-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
                        />
                      </div>
                      <div className="flex rounded-md shadow-sm">
                        <span className="w-[7rem] whitespace-nowrap inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-2 text-gray-500 sm:text-sm">
                          LinkedIn
                        </span>
                        <input
                          value={linkedIn}
                          onChange={(e) => setLinkedIn(e.target.value)}
                          placeholder="LinkedIn"
                          className=" grow relative block appearance-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
                        />
                      </div>
                      <div>
                        <label className="sr-only">Message</label>
                        <textarea
                          rows={4}
                          id="message"
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                          placeholder="Message"
                          required
                          className="mt-2 block w-full rounded-md border-0 px-1 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-gray-400 placeholder:text-gray-400"
                        />
                      </div>
                    </div>

                    <div>
                      <button
                        type="submit"
                        className="group relative flex w-full justify-center rounded-md border border-transparent bg-dataops-primary-blue py-2 px-4 text-sm font-medium text-white hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                      >
                        Send
                      </button>
                    </div>
                  </form>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ContactUsDialog;
