import React, { useEffect, useState, useMemo } from 'react';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import { ITimeseries, ITimeseriesElement } from 'utils/types';
import Card from 'components/Card';
import CardTitle from 'components/CardTitle';
import UsagePerWarehouseTimeseriesChart from './UsagePerWarehouseTimeseriesChart';
import Dropdown from 'components/Dropdown';
import DetailsButton from './DetailsButton';
import NoRecentActivity from 'components/NoRecentActivity';
import LessThanXDays from 'components/LessThanXDays';
import { useAppSelector } from 'app/hooks';
import { selectSelectedAccounts, selectSelectedWarehouses, selectShowDollars } from '../reducers/finobsSlice';
import { selectStartDate, selectEndDate } from 'app/sharedSlice';
import { useGetStoragePerAccountQuery } from 'app/spendviewApis';
import { getNumberOfBins, adjustStorageDates, getTimeStepOptions, getNumberOfDays } from 'utils/dateUtils';

interface IStoragePerAccountCardProps {
  style: string;
  isDetailsView: boolean;
}

const StoragePerAccountCard = (props: IStoragePerAccountCardProps): JSX.Element => {
  const [processedData, setProcessedData] = useState<ITimeseries[]>([]);
  const startDate = useAppSelector(selectStartDate);
  const endDate = useAppSelector(selectEndDate);
  const showDollars = useAppSelector(selectShowDollars);
  const timeStepOptions = useMemo(() => getTimeStepOptions(startDate, endDate), [startDate, endDate]);
  const [timeStep, setTimeStep] = useState(timeStepOptions[0]);
  const selectedAccounts = useAppSelector(selectSelectedAccounts);
  const selectedWarehouses = useAppSelector(selectSelectedWarehouses);
  const bins = useMemo(() => getNumberOfBins(startDate, endDate, timeStep), [startDate, endDate, timeStep]);
  const { adjustedStartDate, adjustedEndDate } = useMemo(
    () => adjustStorageDates(startDate, endDate),
    [startDate, endDate],
  );

  const numberOfDays = getNumberOfDays(startDate, endDate);

  useEffect(() => {
    setTimeStep(timeStepOptions[0]);
  }, [timeStepOptions]);

  const { data, isFetching, isError } = useGetStoragePerAccountQuery(
    {
      startDate: adjustedStartDate,
      endDate: adjustedEndDate,
      bins,
      warehouses: selectedWarehouses,
      aggregate_accounts: true,
      aggregate_by_accounts: selectedAccounts,
      in_dollars: showDollars,
    },
    { skip: numberOfDays < 30 },
  );

  useEffect(() => {
    if (data !== undefined) {
      const tmpProcessedData = [...data];
      tmpProcessedData.sort(
        (a: ITimeseries, b: ITimeseries) =>
          Math.max(...b.timeseries.map((t: ITimeseriesElement) => t.value)) -
          Math.max(...a.timeseries.map((t: ITimeseriesElement) => t.value)),
      );
      setProcessedData(tmpProcessedData.slice(0, props.isDetailsView ? 8 : 4));
    }
  }, [data]);

  return (
    <Card style={props.style} isLoading={isFetching} isError={isError}>
      <div className="flex">
        <CardTitle
          title="Storage spend by accounts"
          subtitleText="Storage spend per account over the selected period"
          tooltipText="On top right, change the storage spend granularity"
        />
        <Dropdown options={timeStepOptions} value={timeStep} onChange={setTimeStep} />
      </div>
      <div className="w-full h-[80%]">
        {numberOfDays >= 30 && processedData.length !== 0 && (
          <ParentSize>
            {(parent) => (
              <UsagePerWarehouseTimeseriesChart
                data={processedData}
                useDollars={showDollars}
                width={parent.width}
                height={parent.height}
              />
            )}
          </ParentSize>
        )}
        {numberOfDays >= 30 && processedData.length === 0 && !isFetching && !isError && <NoRecentActivity />}
        {numberOfDays < 30 && <LessThanXDays days={30} />}
      </div>
      <DetailsButton data={processedData} isTimeseriesArray={true} componentName="Storage by account" />
    </Card>
  );
};

export default StoragePerAccountCard;
