import React, { useEffect, useState } from 'react';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import Card from 'components/Card';
import CardTitle from 'components/CardTitle';
import DetailsButton from './DetailsButton';
import NoRecentActivity from 'components/NoRecentActivity';
import LessThanXDays from 'components/LessThanXDays';
import UsagePerUserDonutChart, { IDonutChartData } from './UsagePerUserDonutChart';
import UsagePerUserDonutChartLegend from './UsagePerUserDonutChartLegend';
import { useAppSelector } from 'app/hooks';
import { selectSelectedAccounts, selectSelectedWarehouses, selectShowDollars } from '../reducers/finobsSlice';
import { selectStartDate, selectEndDate } from 'app/sharedSlice';
import { useGetCostTimeseriesQuery, useGetStorageSpendQuery } from 'app/spendviewApis';
import { ITimeseriesElement } from 'utils/types';
import { getNumberOfDays } from 'utils/dateUtils';

const TotalComputeAndStorageCostDonutCard = (props: { style: string }): JSX.Element => {
  const [processedData, setProcessedData] = useState<IDonutChartData[]>([]);
  const selectedAccounts = useAppSelector(selectSelectedAccounts);
  const selectedWarehouses = useAppSelector(selectSelectedWarehouses);
  const startDate = useAppSelector(selectStartDate);
  const endDate = useAppSelector(selectEndDate);
  const showDollars = useAppSelector(selectShowDollars);
  const numberOfDays = getNumberOfDays(startDate, endDate);

  const {
    data: computeData,
    isFetching: isComputeFetching,
    isError: isComputeError,
  } = useGetCostTimeseriesQuery(
    {
      startDate,
      endDate,
      bins: 1,
      warehouses: selectedWarehouses,
      aggregate_accounts: true,
      aggregate_by_accounts: selectedAccounts,
      in_dollars: showDollars,
    },
    { skip: numberOfDays < 30 },
  );

  const {
    data: storageData,
    isFetching: isStorageFetching,
    isError: isStorageError,
  } = useGetStorageSpendQuery(
    {
      startDate,
      endDate,
      bins: 1,
      forecast_bins: 1,
      warehouses: selectedWarehouses,
      aggregate_accounts: true,
      aggregate_by_accounts: selectedAccounts,
      in_dollars: showDollars,
    },
    { skip: numberOfDays < 30 },
  );

  useEffect(() => {
    if (computeData !== undefined && storageData !== undefined) {
      const tmpProcessedData: IDonutChartData[] = [];
      let total = 0;
      if (computeData.length !== 0) {
        total += computeData[0].value;
      }
      let storageTimeseries: ITimeseriesElement[] = [];
      if (storageData.length !== 0) {
        storageTimeseries = storageData[0].category === 'usage' ? storageData[0].timeseries : storageData[1].timeseries;
        total += storageTimeseries[0].value;
      }
      if (computeData.length !== 0) {
        tmpProcessedData.push({
          category: 'Compute cost',
          timeseries: computeData,
          percent: (computeData[0].value / total) * 100,
        });
      }
      if (storageData.length !== 0) {
        tmpProcessedData.push({
          category: 'Storage cost',
          timeseries: storageTimeseries ?? [],
          percent: (storageTimeseries[0].value / total) * 100,
        });
      }
      setProcessedData(tmpProcessedData);
    }
  }, [computeData, storageData]);

  return (
    <Card
      style={props.style}
      isLoading={isComputeFetching || isStorageFetching}
      isError={isComputeError || isStorageError}
    >
      <div className="flex">
        <CardTitle
          title="Compute and storage"
          subtitleText="Percentage distribution of compute and storage spend per period"
          tooltipText="On bottom right, click Show details to open an aggregate spend view"
        />
      </div>
      <div className="w-full h-[75%]">
        {numberOfDays >= 30 && processedData.length !== 0 && (
          <div className="flex w-full h-full">
            <div className="h-full w-[60%]">
              <ParentSize>
                {(parent) => (
                  <UsagePerUserDonutChart
                    data={processedData}
                    tooltipLabel="Cost type"
                    width={parent.width}
                    height={parent.height}
                  />
                )}
              </ParentSize>
            </div>
            <div className="h-full w-[40%]">
              <UsagePerUserDonutChartLegend data={processedData} />
            </div>
          </div>
        )}
        {numberOfDays >= 30 &&
          processedData.length === 0 &&
          !isComputeFetching &&
          !isStorageFetching &&
          !isComputeError &&
          !isStorageError && <NoRecentActivity />}
        {numberOfDays < 30 && <LessThanXDays days={30} />}
      </div>
      <DetailsButton data={processedData} isTimeseriesArray={true} componentName="Compute and storage" />
    </Card>
  );
};

export default TotalComputeAndStorageCostDonutCard;
