import React, { useEffect, useState } from 'react';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import Card from 'components/Card';
import Dropdown from 'components/Dropdown';
import TopDataProductUsageChart from './TopDataProductUsageChart';
import { useAppSelector } from 'app/hooks';
import { selectStartDate, selectEndDate, selectSelectedDataProducts } from '../reducers/dataProductsSlice';
import { selectSelectedProjects, selectSelectedEnvironments } from 'features/layouts/reducers/appBarSlice';
import { useGetTopDataProductUsageQuery } from 'app/dataProductsApis';
import { stringArrayFromOptionArray } from 'utils/formatUtils';
import { costTypeOptions } from 'utils/constants';
import { ITimeseriesElement } from 'utils/types';

export interface ITopDataProductUsage {
  data_product: string;
  timeseries: ITimeseriesElement[];
}

// const data = [
//   {
//     data_product: 'Data Product 1',
//     timeseries: [
//       { date: 'Mon', value: 50 },
//       { date: 'Tue', value: 55 },
//       { date: 'Wed', value: 60 },
//       { date: 'Thu', value: 55 },
//       { date: 'Fri', value: 60 },
//     ],
//   },
//   {
//     data_product: 'Data Product 2',
//     timeseries: [
//       { date: 'Mon', value: 30 },
//       { date: 'Tue', value: 25 },
//       { date: 'Wed', value: 30 },
//       { date: 'Thu', value: 45 },
//       { date: 'Fri', value: 30 },
//     ],
//   },
//   {
//     data_product: 'Data Product 3',
//     timeseries: [
//       { date: 'Mon', value: 45 },
//       { date: 'Tue', value: 50 },
//       { date: 'Wed', value: 20 },
//       { date: 'Thu', value: 32 },
//       { date: 'Fri', value: 25 },
//     ],
//   },
// ];

const TopDataProductUsageCard = (props: { style: string }): JSX.Element => {
  const [costType, setCostType] = useState(costTypeOptions[0]);
  const [processedData, setProcessedData] = useState<ITopDataProductUsage[]>([]);
  const selectedProjects = useAppSelector(selectSelectedProjects);
  const selectedEnvironments = useAppSelector(selectSelectedEnvironments);
  const selectedDataProducts = useAppSelector(selectSelectedDataProducts);
  const startDate = useAppSelector(selectStartDate);
  const endDate = useAppSelector(selectEndDate);

  const { data } = useGetTopDataProductUsageQuery({
    startDate,
    endDate,
    projectId: selectedProjects,
    env: stringArrayFromOptionArray(selectedEnvironments),
    data_product: selectedDataProducts,
    bins: 5,
    cost_type:
      costType === 'Total cost'
        ? 'total_cost'
        : costType === 'Production cost'
        ? 'production_cost'
        : 'consumption_cost',
  });

  useEffect(() => {
    if (data !== undefined) {
      const tmpProcessedData = [...data];
      tmpProcessedData.sort(
        (a: ITopDataProductUsage, b: ITopDataProductUsage) =>
          Math.max(...b.timeseries.map((t: ITimeseriesElement) => t.value)) -
          Math.max(...a.timeseries.map((t: ITimeseriesElement) => t.value)),
      );
      setProcessedData(tmpProcessedData.slice(0, 3));
    }
  }, [data]);

  const handleChange = (target: string): void => {
    setCostType(target);
  };

  return (
    <Card style={`${props.style}`}>
      <div className="flex">
        <div className="flex-grow text-primary-text">Top Data Product Usage</div>
        <Dropdown options={costTypeOptions} value={costType} onChange={handleChange} />
      </div>
      <div className="w-full h-5/6">
        {processedData.length !== 0 && (
          <ParentSize>
            {(parent) => <TopDataProductUsageChart data={processedData} width={parent.width} height={parent.height} />}
          </ParentSize>
        )}
      </div>
    </Card>
  );
};

export default TopDataProductUsageCard;
