import React from 'react';
import { LegendItem, LegendLabel, LegendOrdinal } from '@visx/legend';
import { scaleOrdinal } from '@visx/scale';
import { ICategoryWithPercent, IColorConfig } from 'utils/types';
import { truncateString } from 'utils/formatUtils';

const legendGlyphSize = 12;

interface UsagePerUserDonutChartLegendProps {
  data: ICategoryWithPercent[];
  colors: IColorConfig;
}

const WarehouseActivityPieChartLegend = (props: UsagePerUserDonutChartLegendProps): JSX.Element => {
  const colorScale = scaleOrdinal<string, string>({
    domain: props.data.map((d) => d.category),
    range: props.data.map((d) => props.colors[d.category]),
  });

  return (
    <div className="h-full pt-4 pl-4 text-sm flex items-end">
      <LegendOrdinal scale={colorScale}>
        {(labels) => (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {labels.map((label, i) => (
              <LegendItem key={`legend-quantile-${i}`} margin="0 5px">
                <svg width={legendGlyphSize} height={legendGlyphSize}>
                  <rect fill={label.value} width={legendGlyphSize} height={legendGlyphSize} />
                </svg>
                <LegendLabel align="left" margin="0 0 0 4px">
                  {truncateString(label.text, 25)}
                </LegendLabel>
              </LegendItem>
            ))}
          </div>
        )}
      </LegendOrdinal>
    </div>
  );
};

export default WarehouseActivityPieChartLegend;
