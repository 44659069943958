import React, { useEffect, useState, useMemo, useRef } from 'react';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import Card from 'components/Card';
import CardTitle from 'components/CardTitle';
import UsagePerWarehouseHeatmap from './UsagePerWarehouseHeatmap';
import DetailsButton from './DetailsButton';
import NoRecentActivity from 'components/NoRecentActivity';
import { useAppSelector } from 'app/hooks';
import { selectSelectedAccounts, selectSelectedWarehouses, selectShowDollars } from '../reducers/finobsSlice';
import { selectStartDate, selectEndDate, selectTimeframeOption } from 'app/sharedSlice';
import { useGetCostPerWarehouseQuery } from 'app/spendviewApis';
import { ITimeseries, ITimeseriesElement } from 'utils/types';
import { getNumberOfBinsHeatmap } from 'utils/dateUtils';
import { classNames } from 'utils/styleUtils';

// export interface IUsagePerWarehouse {
//   warehouse: string;
//   timeseries: ITimeseriesElement[];s
// }

// const data = [
//   {
//     warehouse: 'Warehouse 1',
//     timeseries: [
//       {
//         date: '2022-08-22T21:00:00',
//         value: 10,
//       },
//       {
//         date: '2022-08-24T06:36:00',
//         value: 12,
//       },
//       {
//         date: '2022-08-25T16:12:00',
//         value: 13,
//       },
//       {
//         date: '2022-08-27T01:48:00',
//         value: 10,
//       },
//       {
//         date: '2022-08-28T11:24:00',
//         value: 11,
//       },
//     ],
//   },
// ];

interface IUsagePerWarehouseHeatmapCardProps {
  style: string;
  isDetailsView: boolean;
}

const UsagePerWarehouseHeatmapCard = (props: IUsagePerWarehouseHeatmapCardProps): JSX.Element => {
  const [processedData, setProcessedData] = useState<ITimeseries[]>([]);
  const selectedAccounts = useAppSelector(selectSelectedAccounts);
  const selectedWarehouses = useAppSelector(selectSelectedWarehouses);
  const startDate = useAppSelector(selectStartDate);
  const endDate = useAppSelector(selectEndDate);
  const showDollars = useAppSelector(selectShowDollars);
  const timeframeOption = useAppSelector(selectTimeframeOption);
  const windowWidth = useRef(window.innerWidth);
  const bins = useMemo(
    () => getNumberOfBinsHeatmap(timeframeOption, windowWidth.current, props.isDetailsView),
    [props, timeframeOption, startDate, endDate],
  );

  const { data, isFetching, isError } = useGetCostPerWarehouseQuery({
    startDate,
    endDate,
    bins,
    warehouses: selectedWarehouses,
    aggregate_accounts: true,
    aggregate_by_accounts: selectedAccounts,
    in_dollars: showDollars,
  });

  useEffect(() => {
    if (data !== undefined) {
      const tmpProcessedData = [...data.items];
      tmpProcessedData.sort(
        (a: ITimeseries, b: ITimeseries) =>
          Math.max(...b.timeseries.map((t: ITimeseriesElement) => t.value)) -
          Math.max(...a.timeseries.map((t: ITimeseriesElement) => t.value)),
      );
      tmpProcessedData.sort(
        (a: ITimeseries, b: ITimeseries) =>
          Math.max(...a.timeseries.map((t: ITimeseriesElement) => t.value)) -
          Math.max(...b.timeseries.map((t: ITimeseriesElement) => t.value)),
      );
      if (props.isDetailsView) {
        setProcessedData(tmpProcessedData);
      } else {
        setProcessedData(tmpProcessedData.slice(0, 5));
      }
    }
  }, [data]);

  return (
    <Card style={props.style} isLoading={isFetching} isError={isError}>
      <div className="flex">
        <CardTitle
          title="Warehouse comparison"
          subtitleText="Changes in spend per warehouse over time"
          tooltipText="On bottom right, click Show details to open an aggregate compute and spend view"
        />
      </div>
      <div
        className={classNames(
          'w-full h-5/6 overflow-x-hidden',
          props.isDetailsView ? 'overflow-y-scroll' : 'overflow-y-hidden',
        )}
      >
        {processedData.length !== 0 && (
          <ParentSize>
            {(parent) => (
              <UsagePerWarehouseHeatmap
                data={processedData}
                width={parent.width}
                height={parent.height}
                isDetailsView={props.isDetailsView}
              />
            )}
          </ParentSize>
        )}
        {processedData.length === 0 && !isFetching && !isError && <NoRecentActivity />}
      </div>
      <DetailsButton data={processedData} isTimeseriesArray={true} componentName="Warehouse comparison" />
    </Card>
  );
};

export default UsagePerWarehouseHeatmapCard;
