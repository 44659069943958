import React, { useMemo } from 'react';
import { Group } from '@visx/group';
import { Treemap, treemapBinary, hierarchy, stratify } from '@visx/hierarchy';
import { useTooltip, useTooltipInPortal, defaultStyles } from '@visx/tooltip';
import { scaleOrdinal } from '@visx/scale';
import { localPoint } from '@visx/event';
import { IHierarchicalData } from './QueriesByTypeCard';
import { formatNumberLong, formatTickLabelByte } from 'utils/formatUtils';

const margin = {
  left: 20,
  right: 20,
  bottom: 20,
  top: 20,
};

interface UsagePerUserBarChartProps {
  data: IHierarchicalData[];
  isStorageData?: boolean;
  keys?: string[];
  showDollars?: boolean;
  width: number;
  height: number;
}

interface TooltipData {
  id: string;
  size: number | null;
  percent: number | null;
}

const tooltipStyles = {
  ...defaultStyles,
  backgroundColor: '#27262c',
  color: 'white',
  fontSize: 12,
};

const QueriesByTypeTreemap = (props: UsagePerUserBarChartProps): JSX.Element => {
  const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } = useTooltip<TooltipData>();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });

  const xMax = props.width - margin.left - margin.right;
  const yMax = props.height - margin.top - margin.bottom;

  const colorScale = useMemo(
    () =>
      scaleOrdinal<string>({
        domain: props.keys === undefined ? props.data.map((d) => d.id) : props.keys,
        range: ['#00436c', '#006995', '#16a7d7', '#5ed3ff'],
      }),
    [props.data],
  );

  const data = stratify<IHierarchicalData>()
    .id((d) => d.id)
    .parentId((d) => d.parent)(props.data)
    .sum((d) => d.size ?? 0);

  const root = hierarchy(data).sort((a, b) => (b.value ?? 0) - (a.value ?? 0));

  return props.width < 10 || props.height < 10 ? (
    <></>
  ) : (
    <svg ref={containerRef} width={props.width} height={props.height}>
      <Treemap top={margin.top} root={root} size={[xMax, yMax]} tile={treemapBinary} round>
        {(treemap) => (
          <Group>
            {treemap
              .descendants()
              .reverse()
              .map((node, i) => {
                const nodeWidth = node.x1 - node.x0;
                const nodeHeight = node.y1 - node.y0;
                return (
                  <Group
                    key={`node-${i as number}`}
                    top={(node.y0 as number) + margin.top}
                    left={(node.x0 as number) + margin.left}
                    onMouseLeave={() => {
                      hideTooltip();
                    }}
                    onMouseMove={(event) => {
                      const eventSvgCoords = localPoint(event);
                      showTooltip({
                        tooltipData: node.data.data,
                        tooltipTop: eventSvgCoords?.y,
                        tooltipLeft: eventSvgCoords?.x,
                      });
                    }}
                  >
                    {node.depth === 1 && (
                      <rect
                        width={nodeWidth}
                        height={nodeHeight}
                        strokeWidth={4}
                        fill={colorScale(node.data.id ?? '') as string}
                        rx={3}
                      />
                    )}
                    {node.depth > 2 && (
                      <rect
                        width={nodeWidth}
                        height={nodeHeight}
                        fill={colorScale(node.data.id ?? '') as string}
                        rx={3}
                      />
                    )}
                    {node.depth >= 1 && nodeWidth > (node.data.id?.length ?? 0) * 10 && nodeHeight > 35 && (
                      <text
                        x={nodeWidth / 2}
                        y={nodeHeight / 2}
                        fill="#ffffff"
                        fontSize={16}
                        textAnchor="middle"
                        pointerEvents="none"
                      >
                        {node.data.id}
                        <tspan
                          x={nodeWidth / 2}
                          y={nodeHeight / 2}
                          fill="#ffffff"
                          fontSize={11}
                          textAnchor="middle"
                          pointerEvents="none"
                          dy="1.2em"
                        >
                          {formatNumberLong(node.data.data.percent ?? 0)}%
                        </tspan>
                      </text>
                    )}
                  </Group>
                );
              })}
          </Group>
        )}
      </Treemap>
      {tooltipOpen && tooltipData !== undefined && (
        <TooltipInPortal top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
          <div>{tooltipData.id}</div>
          <div className="flex">
            <div className="pr-2">Percent: </div>
            <div>{formatNumberLong(tooltipData.percent ?? 0)}%</div>
          </div>
          <div className="flex">
            <div className="pr-2">Value: </div>
            {(props.isStorageData === undefined || !props.isStorageData) && (
              <div>{`${props.showDollars === true ? '$' : ''}${formatNumberLong(tooltipData.size ?? 0)}`}</div>
            )}
            {props.isStorageData === true && <div>{formatTickLabelByte(tooltipData.size ?? 0)}</div>}
          </div>
        </TooltipInPortal>
      )}
    </svg>
  );
};

export default QueriesByTypeTreemap;
