const colors = {
  dataopsDarkBlue: '#11567f',
  dataopsLightBlue: '#29b5e8',
  dataopsDarkGreen: '#4e9652',
  dataopsLightGreen: '#75bc75',
  dataopsRed: '#e64958',
  dataopsDarkRed: '#8b103f',
  dataopsOrange: '#ec6a46',
  dataopsPurple: '#5f4496',
  dataopsPrimaryDark: '#24323d',
  axisColor: '#9ca3af',
  tickLabelColor: '#4b5563',
};

export const dataopsColorScale = [
  colors.dataopsDarkBlue,
  colors.dataopsLightBlue,
  colors.dataopsDarkGreen,
  colors.dataopsLightGreen,
  colors.dataopsOrange,
  colors.dataopsRed,
  colors.dataopsDarkRed,
  colors.dataopsPurple,
  colors.dataopsPrimaryDark,
];

export default colors;
