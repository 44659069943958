import React, { useEffect, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import DropdownMultiselectAutocomplete from 'components/DropdownMultiselectAutocomplete';
import DollarOrCreditSwitch from './DollarOrCreditSwitch';
import ShowInactiveWarehousesSwitch from './ShowInactiveWarehousesSwitch';
import DateRangeSelector from 'components/DateRangeSelector';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  fetchAccounts,
  fetchWarehouses,
  selectAccounts,
  selectSelectedAccounts,
  selectPendingSelectedAccounts,
  selectPendingSelectedWarehouses,
  selectPendingAccountSelection,
  selectWarehouses,
  selectShowInactiveWarehouses,
  setSelectedWarehouses,
  setSelectedAccounts,
  setPendingSelectedAccounts,
  setPendingSelectedWarehouses,
  selectTabs,
} from '../reducers/finobsSlice';
import { selectStartDate, selectEndDate, fetchTimeframe } from 'app/sharedSlice';
import { classNames } from 'utils/styleUtils';

let accountTimeoutID: ReturnType<typeof setTimeout>;
let warehouseTimeoutID: ReturnType<typeof setTimeout>;

const FinObsBar = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const accounts = useAppSelector(selectAccounts);
  const tabs = useAppSelector(selectTabs);
  const selectedAccounts = useAppSelector(selectSelectedAccounts);
  const pendingSelectedAccounts = useAppSelector(selectPendingSelectedAccounts);
  const pendingAccountSelection = useAppSelector(selectPendingAccountSelection);
  const showInactiveWarehouses = useAppSelector(selectShowInactiveWarehouses);
  const warehouses = useAppSelector(selectWarehouses);
  const pendingSelectedWarehouses = useAppSelector(selectPendingSelectedWarehouses);
  const startDate = useAppSelector(selectStartDate);
  const endDate = useAppSelector(selectEndDate);

  useEffect(() => {
    dispatch(fetchTimeframe()).catch((err) => err);
    dispatch(fetchAccounts()).catch((err) => err);
  }, []);

  useEffect(() => {
    dispatch(fetchWarehouses({ startDate, endDate, selectedAccounts, showInactiveWarehouses })).catch((err) => err);
  }, [startDate, endDate, selectedAccounts, showInactiveWarehouses]);

  const accountOptions = useMemo(
    () => accounts.filter((account) => account.is_active).map((account) => account.account),
    [accounts],
  );

  const setAccounts = (e: any): void => {
    dispatch(setPendingSelectedAccounts(e));
    if (accountTimeoutID !== undefined) {
      clearTimeout(accountTimeoutID);
    }
    accountTimeoutID = setTimeout(() => {
      dispatch(setSelectedAccounts(e));
    }, 2500);
  };

  const setWarehouses = (e: any): void => {
    dispatch(setPendingSelectedWarehouses(e));
    if (warehouseTimeoutID !== undefined) {
      clearTimeout(warehouseTimeoutID);
    }
    warehouseTimeoutID = setTimeout(() => {
      dispatch(setSelectedWarehouses(e));
    }, 2500);
  };

  return (
    <div className="fixed left-16 right-0 z-20 pl-8 pr-8 bg-white">
      <div className="border-b border-gray-200 flex items-center">
        <nav className="-mb-px flex space-x-8 flex-grow" aria-label="Tabs">
          {tabs.map((tab) => (
            <NavLink
              key={tab.name}
              to={tab.href}
              end
              className={({ isActive }) =>
                classNames(
                  isActive
                    ? 'border-dataops-secondary-blue text-dataops-secondary-blue'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                  'whitespace-nowrap py-4 px-1 border-b-2 font-[600] text-sm',
                )
              }
            >
              {tab.name}
            </NavLink>
          ))}
        </nav>
        <DollarOrCreditSwitch />
        <DropdownMultiselectAutocomplete
          options={accountOptions}
          value={pendingSelectedAccounts}
          onChange={(e) => setAccounts(e)}
          label="Accounts"
        />
        <DropdownMultiselectAutocomplete
          options={warehouses}
          value={pendingSelectedWarehouses}
          onChange={(e) => setWarehouses(e)}
          label="Warehouses"
          disabled={pendingAccountSelection}
        />
        <ShowInactiveWarehousesSwitch />
        {/* <Dropdown options={timeframes} value={timeframe} onChange={handleChange} /> */}
        <DateRangeSelector />
      </div>
    </div>
  );
};

export default FinObsBar;
