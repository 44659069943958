import React, { useState, Fragment } from 'react';
import { Combobox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { classNames } from 'utils/styleUtils';

interface DropdownMultiselectAutocompleteProps {
  options: string[];
  value: string[];
  onChange: (value: string[]) => void;
  label: string;
  disabled?: boolean;
}

const DropdownMultiselectAutocomplete = (props: DropdownMultiselectAutocompleteProps): JSX.Element => {
  const [query, setQuery] = useState('');

  const filteredOptions =
    query === ''
      ? props.options
      : props.options.filter((option) =>
          option.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, '')),
        );

  return (
    <div className="h-8 flex items-center justify-end p-1">
      <Combobox value={props.value} onChange={props.onChange} multiple>
        {({ open }) => (
          <>
            <div className="relative mt-1">
              <div className="relative w-40 cursor-default overflow-hidden rounded-md bg-dark-card-background border shadow-none border-gray-300 text-left focus:outline-none sm:text-sx focus:border-gray-500">
                <Combobox.Button as="div" className="flex items-center pr-2 bg-dark-card-background">
                  <Combobox.Input
                    key={open ? 'open' : 'closed'}
                    className="w-full text-black bg-transparent border-none py-1 pl-3 pr-3 text-sm leading-5 focus:outline-none sm:text-xs"
                    displayValue={(options: string[]) => {
                      if (open) {
                        return '';
                      }
                      return (options ?? []).map((option: string) => option).join(', ');
                    }}
                    onChange={(event) => setQuery(event.target.value)}
                    placeholder={props.label}
                  />
                  <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </Combobox.Button>
              </div>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                afterLeave={() => setQuery('')}
              >
                <Combobox.Options className="absolute mt-1 max-h-60 min-w-full max-w-[30rem] overflow-auto rounded-md bg-white pt-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-xs">
                  {filteredOptions.length === 0 && query !== '' ? (
                    <div className="relative cursor-default select-none py-2 px-4 text-gray-700">Nothing found.</div>
                  ) : (
                    <>
                      {filteredOptions.map((option) => (
                        <Combobox.Option
                          key={option}
                          className={({ active }) =>
                            classNames(
                              active ? 'text-gray-900 bg-gray-100' : 'text-gray-900',
                              'relative cursor-default select-none py-1 pl-3 pr-9',
                            )
                          }
                          value={option}
                          disabled={props.disabled}
                        >
                          {({ selected, active }) => (
                            <>
                              <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                                {option}
                              </span>
                              {selected ? (
                                <span
                                  className={classNames(
                                    active ? 'text-gray-600 bg-gray-100' : 'text-gray-600',
                                    'absolute inset-y-0 right-0 flex items-center pr-2',
                                  )}
                                >
                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Combobox.Option>
                      ))}
                      {filteredOptions.length !== 0 && (
                        <div className="sticky bottom-0 w-full px-2 py-1 bg-white flex items-center justify-center">
                          <button
                            className={classNames(
                              'w-[8rem] text-xs text-center border border-gray-300 rounded-md',
                              props.disabled === true ? '' : 'hover:bg-gray-50',
                            )}
                            onClick={() => {
                              if (props.disabled !== true) props.onChange([]);
                            }}
                          >
                            Clear selection
                          </button>
                        </div>
                      )}
                    </>
                  )}
                </Combobox.Options>
              </Transition>
            </div>
          </>
        )}
      </Combobox>
    </div>
  );
};

export default DropdownMultiselectAutocomplete;
