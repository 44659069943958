import React from 'react';
import { Outlet } from 'react-router-dom';
import AppBar from 'features/layouts/components/AppBar';
import Sidebar from 'features/layouts/components/Sidebar';
import ContactUsDialog from 'features/layouts/components/ContactUsDialog';

export default function ObservabilityView(): JSX.Element {
  return (
    <>
      <div className="min-h-full">
        <div className="w-full fixed top-0 z-40">
          <AppBar />
        </div>

        <div className="flex h-full flex-col mt-[3.9rem]">
          <div className="flex min-h-0 flex-1">
            <Sidebar />

            <main className="min-w-0 flex-1 border-t border-gray-200 lg:flex ml-16">
              <Outlet />
            </main>
          </div>
        </div>
      </div>
      {/* <SupportButton /> */}
      <ContactUsDialog />
    </>
  );
}
