import React, { Fragment, useMemo } from 'react';
import { classNames } from 'utils/styleUtils';
import { ITimeseries } from 'utils/types';
import { formatDateLong } from 'utils/formatUtils';

interface DetailsDialogTableProps {
  data: any[];
  isTimeseriesArray: boolean;
}

const DetailsDialogTable = (props: DetailsDialogTableProps): JSX.Element => {
  if (props.data === undefined || props.data.length === 0) {
    return <></>;
  }

  if (props.isTimeseriesArray) {
    return (
      <div className="-my-2 -mx-6 lg:-mx-8 overflow-x-auto">
        <div className="inline-block min-w-full max-h-[60vh] py-2 align-middle sm:px-6 lg:px-8">
          <table className="min-w-full overflow-y-auto">
            <thead className="bg-white">
              <tr className="divide-x divide-gray-200">
                <th scope="col" className="py-3.5 pl-6 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
                  Date
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Value
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {props.data.map((row) => (
                <Fragment key={row.category}>
                  <tr className="sticky top-0 border-t border-gray-200">
                    <th
                      colSpan={5}
                      scope="colgroup"
                      className="bg-gray-100 py-2 pl-6 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                    >
                      {row.category}
                    </th>
                  </tr>
                  {(row as ITimeseries).timeseries.map((t, index) => (
                    <tr
                      key={index}
                      className={classNames(
                        index % 2 === 0 ? undefined : 'bg-gray-50',
                        'divide-x divide-gray-200',
                        index === 0 ? 'border-gray-300' : 'border-gray-200',
                      )}
                    >
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{formatDateLong(t.date)}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{t.value}</td>
                    </tr>
                  ))}
                </Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  } else {
    // console.log('data: ', props.data);
    const keys = useMemo(() => Object.keys(props.data[0]), [props.data]);
    return (
      <div className="-my-2 -mx-6 overflow-x-auto lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr className="divide-x divide-gray-200">
                {keys.map((key, index) => (
                  <th
                    key={index}
                    scope="col"
                    className="bg-gray-100 py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900"
                  >
                    {key}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {props.data.map((row, index) => (
                <tr
                  key={index}
                  className={classNames(index % 2 === 0 ? undefined : 'bg-gray-50', 'divide-x divide-gray-200')}
                >
                  {keys.map((key, keyIndex) => (
                    <td key={keyIndex} className="whitespace-nowrap p-4 text-sm text-gray-500">
                      {row[key]}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
};

export default DetailsDialogTable;
