import React from 'react';
import { ITotalCostOverTime } from './TotalCostOverTimeCard';
import {
  AnimatedAxis, // any of these can be non-animated equivalents
  AnimatedGrid,
  AnimatedLineSeries,
  XYChart,
  Tooltip,
} from '@visx/xychart';
import { LegendItem, LegendLabel, LegendOrdinal } from '@visx/legend';
import { scaleOrdinal } from '@visx/scale';
import { defaultStyles } from '@visx/tooltip';
import { formatDate, formatNumberShort } from 'utils/formatUtils';

const legendGlyphSize = 12;
interface TotalCostOverTimeChartProps {
  data: ITotalCostOverTime[];
  width: number;
  height: number;
}

const tooltipStyles = {
  ...defaultStyles,
  width: 180,
  backgroundColor: '#27262c',
  color: 'white',
  fontSize: 12,
};

const TotalCostOverTimeChart = (props: TotalCostOverTimeChartProps): JSX.Element => {
  const keys = ['total_cost', 'production_cost', 'consumption_cost'];

  const colorScale = scaleOrdinal<string, string>({
    domain: keys,
    range: ['#0b7285', '#66d9e8', '#fcc418'],
  });

  return (
    <>
      <div className="text-sm">
        <LegendOrdinal scale={colorScale}>
          {(labels) => (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {labels.map((label, i) => (
                <LegendItem key={`legend-quantile-${i}`} margin="0 5px">
                  <svg width={legendGlyphSize} height={legendGlyphSize}>
                    <rect fill={label.value} width={legendGlyphSize} height={legendGlyphSize} />
                  </svg>
                  <LegendLabel align="left" margin="0 0 0 4px">
                    {label.text === 'total_cost'
                      ? 'Total cost'
                      : label.text === 'production_cost'
                      ? 'Production cost'
                      : 'Consumption cost'}
                  </LegendLabel>
                </LegendItem>
              ))}
            </div>
          )}
        </LegendOrdinal>
      </div>
      <XYChart width={props.width} height={props.height} xScale={{ type: 'band' }} yScale={{ type: 'linear' }}>
        <AnimatedAxis orientation="bottom" stroke="#87858d" tickStroke="#87858d" />
        <AnimatedAxis
          orientation="left"
          // tickValues={costTickValues}
          numTicks={5}
          tickFormat={formatNumberShort}
          stroke="#87858d"
          tickStroke="#87858d"
          tickLabelProps={() => ({
            fontSize: 11,
            color: '#87858d',
          })}
        />
        <AnimatedGrid
          columns={true}
          numTicks={5}
          lineStyle={{ stroke: '#87858d', strokeWidth: '1px', strokeOpacity: 0.2 }}
        />
        <AnimatedLineSeries
          dataKey="Total cost"
          data={props.data}
          xAccessor={(d) => formatDate(d.date)}
          yAccessor={(d) => d.total_cost}
        />
        <AnimatedLineSeries
          dataKey="Production cost"
          data={props.data}
          xAccessor={(d) => formatDate(d.date)}
          yAccessor={(d) => d.production_cost}
        />
        <AnimatedLineSeries
          dataKey="Consumption cost"
          data={props.data}
          xAccessor={(d) => formatDate(d.date)}
          yAccessor={(d) => d.consumption_cost}
        />
        <Tooltip<ITotalCostOverTime>
          snapTooltipToDatumX
          snapTooltipToDatumY
          showVerticalCrosshair
          showSeriesGlyphs
          style={tooltipStyles}
          renderTooltip={({ tooltipData }) => (
            <div>
              <div>Date: {tooltipData !== undefined ? formatDate(tooltipData.nearestDatum?.datum.date ?? '') : ''}</div>
              <div>
                Total cost: $
                {tooltipData !== undefined ? tooltipData.nearestDatum?.datum.total_cost.toLocaleString() : ''}
              </div>
              <div>
                Production cost: $
                {tooltipData !== undefined ? tooltipData.nearestDatum?.datum.production_cost.toLocaleString() : ''}
              </div>
              <div>
                Consumption cost: $
                {tooltipData !== undefined ? tooltipData.nearestDatum?.datum.consumption_cost.toLocaleString() : ''}
              </div>
            </div>
          )}
        />
      </XYChart>
    </>
  );
};

export default TotalCostOverTimeChart;
