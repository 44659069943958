import { isRejectedWithValue } from '@reduxjs/toolkit';
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit';
import { setErrorMessage, setShowError } from 'features/finobs/reducers/finobsSlice';

export const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (isRejectedWithValue(action)) {
    if (
      action?.meta?.baseQueryMeta?.response?.status !== undefined &&
      action.meta.baseQueryMeta.response.status >= 500 &&
      action.meta.baseQueryMeta.response.status < 600
    ) {
      api.dispatch(
        setErrorMessage(
          'If you are seeing this message regularly please try with a bigger warehouse or with warehouse that is used only by Spendview',
        ),
      );
      api.dispatch(setShowError(true));
    } else {
      api.dispatch(setErrorMessage(action.payload.data));
      api.dispatch(setShowError(true));
    }
  }

  return next(action);
};
