import { observabilityApi } from './apis';
import { ICategoryResponse, ITimeseries, ITimeseriesElement, ITimeseriesResponse } from 'utils/types';
import { IAccountOverviewPeriod } from 'features/finobs/components/AccountOverviewPeriodCard';
import { IAccountOverviewMonthly } from 'features/finobs/components/AccountOverviewMonthlyCard';
import { IOverview } from 'features/finobs/components/OverviewCard';
import { IStorageOverview } from 'features/finobs/components/StorageOverviewCard';
import { IWarehouseActivity } from 'features/finobs/components/WarehouseActivityCard';
import { ICostPerWarehouseUnified } from 'features/finobs/components/CostPerWarehouseCard';
import { IWarehouseSpilling } from 'features/finobs/components/WarehouseSpillingCard';
import { IWarehouseQueueing } from 'features/finobs/components/WarehouseQueueingCard';
import { IStorageByType } from 'features/finobs/components/StorageByTypeCard';
import { IStorageByTypeByDB } from 'features/finobs/components/StorageByTypeByDBCard';
import { IAccountReport, IComputeReport, IStorageReport, IUsageReport } from 'views/FinObsDetailsTab';

interface IFinObsRequestParams {
  startDate: string;
  endDate: string;
  warehouses: string[];
  aggregate_accounts: boolean;
  aggregate_by_accounts: string[];
  in_dollars: boolean;
}

interface IFinObsRequestParamsWithBins {
  startDate: string;
  endDate: string;
  warehouses: string[];
  bins: number;
  aggregate_accounts: boolean;
  aggregate_by_accounts: string[];
  in_dollars: boolean;
}

interface IFinObsRequestParamsWithForecastBins {
  startDate: string;
  endDate: string;
  warehouses: string[];
  bins: number;
  forecast_bins: number;
  aggregate_accounts: boolean;
  aggregate_by_accounts: string[];
  in_dollars: boolean;
}

const extendedApi = observabilityApi.injectEndpoints({
  endpoints: (builder) => ({
    markSnowflakeAccountActive: builder.mutation({
      query: (account) => ({
        url: 'top_bar/mark_snowflake_account_active',
        method: 'POST',
        params: { account },
      }),
      invalidatesTags: ['FinObs'],
    }),
    markSnowflakeAccountInactive: builder.mutation({
      query: (account) => ({
        url: 'top_bar/mark_snowflake_account_inactive',
        method: 'POST',
        params: { account },
      }),
      invalidatesTags: ['FinObs'],
    }),
    addAccountToGroup: builder.mutation({
      query: (account) => ({
        url: 'top_bar/account_to_group',
        method: 'POST',
        params: { account },
      }),
      invalidatesTags: ['FinObs'],
    }),
    removeAccountFromGroup: builder.mutation({
      query: (account) => ({
        url: 'top_bar/account_to_group',
        method: 'DELETE',
        params: { account },
      }),
      invalidatesTags: ['FinObs'],
    }),
    getAccountOverviewPeriod: builder.query<IAccountOverviewPeriod, IFinObsRequestParams>({
      query: (params) => ({
        url: 'cost_router/account_overview_period',
        params: { ...params },
      }),
      providesTags: ['FinObs'],
    }),
    getAccountOverviewMonthly: builder.query<IAccountOverviewMonthly, IFinObsRequestParams>({
      query: (params) => ({
        url: 'cost_router/account_overview_monthly',
        params: { ...params },
      }),
      providesTags: ['FinObs'],
    }),
    getUsersPerAccount: builder.query<ITimeseries[], IFinObsRequestParamsWithBins>({
      query: (params) => ({
        url: 'cost_router/number_of_users_by_account',
        params: { ...params },
      }),
      providesTags: ['FinObs'],
    }),
    getTotalCostPerAccount: builder.query<ITimeseries[], IFinObsRequestParamsWithBins>({
      query: (params) => ({
        url: 'cost_router/total_spend_by_account',
        params: { ...params },
      }),
      providesTags: ['FinObs'],
    }),
    getComputePerAccount: builder.query<ITimeseries[], IFinObsRequestParamsWithBins>({
      query: (params) => ({
        url: 'cost_router/total_compute_spend_by_account',
        params: { ...params },
      }),
      providesTags: ['FinObs'],
    }),
    getStoragePerAccount: builder.query<ITimeseries[], IFinObsRequestParamsWithBins>({
      query: (params) => ({
        url: 'cost_router/total_storage_spend_by_account',
        params: { ...params },
      }),
      providesTags: ['FinObs'],
    }),
    getCostOverview: builder.query<IOverview, IFinObsRequestParams>({
      query: (params) => ({
        url: 'cost_router/compute_spend_overview',
        params: { ...params },
      }),
      providesTags: ['FinObs'],
    }),
    getCostPerWarehouse: builder.query<ITimeseriesResponse, IFinObsRequestParamsWithBins>({
      query: (params) => ({
        url: 'cost_router/cost_per_warehouse',
        params: { ...params },
      }),
      providesTags: ['FinObs'],
    }),
    getCostPerWarehouseUnified: builder.query<
      ICostPerWarehouseUnified[],
      {
        startDate: string;
        endDate: string;
        bins: number;
        warehouses: string[];
        cost_type: string;
        aggregate_accounts: boolean;
        aggregate_by_accounts: string[];
        in_dollars: boolean;
      }
    >({
      query: (params) => ({
        url: 'cost_router/cost_per_warehouse_unified',
        params: { ...params },
      }),
      providesTags: ['FinObs'],
    }),
    getAllWarehouses: builder.query<
      IWarehouseActivity,
      { startDate: string; endDate: string; aggregate_accounts: boolean; aggregate_by_accounts: string[] }
    >({
      query: (params) => ({
        url: 'cost_router/all_warehouses',
        params: { ...params },
      }),
      providesTags: ['FinObs'],
    }),
    getCostTimeseries: builder.query<ITimeseriesElement[], IFinObsRequestParamsWithBins>({
      query: (params) => ({
        url: 'cost_router/cost_time_series',
        params: { ...params },
      }),
      providesTags: ['FinObs'],
    }),
    getWarehousesBySize: builder.query<ICategoryResponse, IFinObsRequestParams>({
      query: (params) => ({
        url: 'cost_router/warehouses_by_size',
        params: { ...params },
      }),
      providesTags: ['FinObs'],
    }),
    getStorageOverview: builder.query<IStorageOverview, IFinObsRequestParams>({
      query: (params) => ({
        url: 'cost_router/united_storage_overview',
        params: { ...params },
      }),
      providesTags: ['FinObs'],
    }),
    getStorageByType: builder.query<IStorageByType, IFinObsRequestParams>({
      query: (params) => ({
        url: 'cost_router/storage_by_type',
        params: { ...params },
      }),
      providesTags: ['FinObs'],
    }),
    getStorageByTypeByDB: builder.query<IStorageByTypeByDB[], IFinObsRequestParams>({
      query: (params) => ({
        url: 'cost_router/storage_by_type_and_db',
        params: { ...params },
      }),
      providesTags: ['FinObs'],
    }),
    getStorageSpend: builder.query<ITimeseries[], IFinObsRequestParamsWithForecastBins>({
      query: (params) => ({
        url: 'cost_router/storage_spent',
        params: { ...params },
      }),
      providesTags: ['FinObs'],
    }),
    getStageSpend: builder.query<ITimeseries[], IFinObsRequestParamsWithForecastBins>({
      query: (params) => ({
        url: 'cost_router/stage_spent',
        params: { ...params },
      }),
      providesTags: ['FinObs'],
    }),
    getFailsafeSpend: builder.query<ITimeseries[], IFinObsRequestParamsWithForecastBins>({
      query: (params) => ({
        url: 'cost_router/failsafe_spent',
        params: { ...params },
      }),
      providesTags: ['FinObs'],
    }),
    getTotalTime: builder.query<
      ICategoryResponse,
      {
        startDate: string;
        endDate: string;
        aggregate_accounts: boolean;
        aggregate_by_accounts: string[];
        warehouses: string[];
        in_dollars: boolean;
      }
    >({
      query: (params) => ({
        url: 'cost_router/total_time',
        params: { ...params },
      }),
      providesTags: ['FinObs'],
    }),
    getCostPerUser: builder.query<ITimeseriesResponse, IFinObsRequestParams>({
      query: (params) => ({
        url: 'cost_router/cost_per_active_users',
        params: { ...params },
      }),
      providesTags: ['FinObs'],
    }),
    getCostPerDatabase: builder.query<ITimeseriesResponse, IFinObsRequestParams>({
      query: (params) => ({
        url: 'cost_router/cost_per_database_and_schema',
        params: { ...params },
      }),
      providesTags: ['FinObs'],
    }),
    getQueriesByType: builder.query<ICategoryResponse, IFinObsRequestParams>({
      query: (params) => ({
        url: 'cost_router/queries_by_type',
        params: { ...params },
      }),
      providesTags: ['FinObs'],
    }),
    getWarehouseSpilling: builder.query<IWarehouseSpilling[], IFinObsRequestParams>({
      query: (params) => ({
        url: 'cost_router/warehouse_spilled',
        params: { ...params },
      }),
      providesTags: ['FinObs'],
    }),
    getWarehouseQueueing: builder.query<IWarehouseQueueing[], IFinObsRequestParams>({
      query: (params) => ({
        url: 'cost_router/warehouse_queued',
        params: { ...params },
      }),
      providesTags: ['FinObs'],
    }),
    getAccountReport: builder.query<IAccountReport[], IFinObsRequestParams>({
      query: (params) => ({
        url: 'cost_router/report/account',
        params: { ...params },
      }),
      providesTags: ['FinObs'],
    }),
    getComputeReport: builder.query<IComputeReport[], IFinObsRequestParams>({
      query: (params) => ({
        url: 'cost_router/report/compute',
        params: { ...params },
      }),
      providesTags: ['FinObs'],
    }),
    getStorageReport: builder.query<IStorageReport[], IFinObsRequestParams>({
      query: (params) => ({
        url: 'cost_router/report/storage',
        params: { ...params },
      }),
      providesTags: ['FinObs'],
    }),
    getUsageReport: builder.query<IUsageReport[], IFinObsRequestParams>({
      query: (params) => ({
        url: 'cost_router/report/usage',
        params: { ...params },
      }),
      providesTags: ['FinObs'],
    }),
  }),
});

export const {
  useMarkSnowflakeAccountActiveMutation,
  useMarkSnowflakeAccountInactiveMutation,
  useAddAccountToGroupMutation,
  useRemoveAccountFromGroupMutation,
  useGetAccountOverviewPeriodQuery,
  useGetAccountOverviewMonthlyQuery,
  useGetUsersPerAccountQuery,
  useGetTotalCostPerAccountQuery,
  useGetComputePerAccountQuery,
  useGetStoragePerAccountQuery,
  useGetCostOverviewQuery,
  useGetCostPerWarehouseQuery,
  useGetCostPerWarehouseUnifiedQuery,
  useGetAllWarehousesQuery,
  useGetCostTimeseriesQuery,
  useGetWarehousesBySizeQuery,
  useGetStorageByTypeQuery,
  useGetStorageByTypeByDBQuery,
  useGetStorageSpendQuery,
  useGetStageSpendQuery,
  useGetFailsafeSpendQuery,
  useGetStorageOverviewQuery,
  useGetTotalTimeQuery,
  useGetCostPerUserQuery,
  useGetCostPerDatabaseQuery,
  useGetQueriesByTypeQuery,
  useGetWarehouseSpillingQuery,
  useGetWarehouseQueueingQuery,
  useLazyGetAccountReportQuery,
  useLazyGetComputeReportQuery,
  useLazyGetStorageReportQuery,
  useLazyGetUsageReportQuery,
} = extendedApi;
