import React from 'react';

interface ILessThanXDaysProps {
  days: number;
}

const LessThanXDays = (props: ILessThanXDaysProps): JSX.Element => {
  return (
    <div className="w-full h-full bg-gray-100 flex items-center justify-center rounded-md">
      <div className="text-gray-500">Only available for {props.days}+ days period</div>
    </div>
  );
};

export default LessThanXDays;
