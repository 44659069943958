import React from 'react';
import { ICostOverBusinessValue } from './BusinessValueCard';
import { classNames } from 'utils/styleUtils';
import { truncateString } from 'utils/formatUtils';

interface BusinessValueDetailsTableProps {
  data: ICostOverBusinessValue[];
}

const BusinessValueDetailsTable = (props: BusinessValueDetailsTableProps): JSX.Element => {
  return (
    <>
      <div className="mt-8 flex flex-col text-xs">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-2 xl:px-2">
            <table className="divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 xl:pl-4 xl:pr-1 text-left font-semibold text-secondary-text xl:pl-6 md:pl-0"
                  >
                    DATA PRODUCT
                  </th>
                  <th scope="col" className="py-2 xl:px-1 text-left font-semibold text-secondary-text">
                    QUERIES
                  </th>
                  <th scope="col" className="py-2 xl:px-1 text-left font-semibold text-secondary-text">
                    VALUE
                  </th>
                  <th scope="col" className="py-2 xl:px-1 text-left font-semibold text-secondary-text">
                    COST
                  </th>
                </tr>
              </thead>
              {props.data.length !== 0 && (
                <tbody className="divide-y divide-gray-200 text-xs">
                  {props.data
                    .sort((a, b) => b.cost - a.cost)
                    .slice(0, 8)
                    .map((product) => (
                      <tr key={product.name}>
                        <td className="py-2 xl:pl-1 xl:pr-1 font-medium text-gray-900 sm:pl-6 md:pl-0 flex">
                          <div
                            className={classNames(
                              'rounded-full w-3 h-3 p-1.5 mt-1 mr-1',
                              product.valueCostCategory === 'HighLow'
                                ? 'bg-[#6bcba0]'
                                : product.valueCostCategory === 'LowHigh'
                                ? 'bg-[#ea333d]'
                                : 'bg-[#f89b22]',
                            )}
                          />
                          <div className="whitespace-normal">{truncateString(product.name, 15)}</div>
                        </td>
                        <td className="whitespace-nowrap py-4 xl:px-1 text-right text-gray-500">{product.queries}</td>
                        <td className="whitespace-nowrap py-4 xl:px-1 text-right text-gray-500">{product.value}</td>
                        <td className="whitespace-nowrap py-4 xl:px-1 text-right text-gray-500">
                          ${product.cost.toLocaleString()}
                        </td>
                      </tr>
                    ))}
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default BusinessValueDetailsTable;
