import React from 'react';
import SimpleOverviewCard from 'components/SimpleOverviewCard';
import Card from 'components/Card';
import { useAppSelector } from 'app/hooks';
import { selectStartDate, selectEndDate } from 'app/sharedSlice';
import { useGetPipelineRunsQuery } from 'app/pipelineMonitoringApis';
import { classNames } from 'utils/styleUtils';

export interface IPipelineRunsOverview {
  pipeline_runs: number;
  pipeline_runs_delta: number;
}

const PipelineRunsOverviewCard = (props: { style: string }): JSX.Element => {
  const startDate = useAppSelector(selectStartDate);
  const endDate = useAppSelector(selectEndDate);

  const { data, isFetching } = useGetPipelineRunsQuery({
    startDate,
    endDate,
  });

  return (
    <Card style={classNames(props.style, 'p-0 rounded-md shadow-sm bg-dashboard-background flex space-x-1')}>
      <SimpleOverviewCard
        label="Pipeline runs"
        value={data?.pipeline_runs}
        change={data?.pipeline_runs_delta}
        isFetching={isFetching}
        tooltipText="Number of pipeline runs over the chosen period"
      />
    </Card>
  );
};

export default PipelineRunsOverviewCard;
