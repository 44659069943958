import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Outlet } from 'react-router-dom';
import FinObsBar from 'features/finobs/navigation/FinObsBar';
import DetailsDialogTable from 'features/finobs/components/DetailsDialogTable';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  selectShowError,
  selectErrorMessage,
  selectDetailsDialogOpen,
  selectDetailsDialogContent,
  selectDetailsDialogIsTimeseriesArray,
  setShowError,
  setDetailsDialogOpen,
} from 'features/finobs/reducers/finobsSlice';

export default function FinObsView(): JSX.Element {
  const dispatch = useAppDispatch();
  const showError = useAppSelector(selectShowError);
  const errorMessage = useAppSelector(selectErrorMessage);
  const detailsDialogOpen = useAppSelector(selectDetailsDialogOpen);
  const detailsDialogContent = useAppSelector(selectDetailsDialogContent);
  const detailsDialogIsTimeseriesArray = useAppSelector(selectDetailsDialogIsTimeseriesArray);

  return (
    <>
      <div className="h-full min-w-0 flex-1">
        <FinObsBar />
        {showError && (
          <>
            <div className="fixed top-[7.2rem] left-16 right-0 z-10 rounded-md bg-red-50 p-4">
              <div className="flex w-full">
                <div className="ml-3 w-full">
                  <div className="flex">
                    <h3 className="flex-grow text-sm font-medium text-red-800">Error while fetching data</h3>
                    <button
                      type="button"
                      onClick={() => dispatch(setShowError(false))}
                      className="h-5 w-5 mr-4 inline-flex items-center rounded-full text-red-800 hover:bg-red-100"
                    >
                      <XMarkIcon className="w-full" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="w-full text-sm text-red-800">{errorMessage}</div>
                </div>
              </div>
            </div>
            <div className="mt-[7.5rem]"></div>
          </>
        )}
        <Outlet />
      </div>
      <Transition.Root show={detailsDialogOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => dispatch(setDetailsDialogOpen(false))}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full justify-center text-center items-start sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="mt-[8rem] min-w-[30rem] relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 text-left shadow-xl transition-all sm:p-6 space-y-2">
                  <div className="flex">
                    <div className="text-lg text-gray-700 flex-grow">Raw Data</div>
                    <button
                      type="button"
                      onClick={() => dispatch(setDetailsDialogOpen(false))}
                      className="h-5 w-5 inline-flex items-center rounded-full border border-transparent bg-cyan-600 text-white shadow-sm hover:bg-cyan-700"
                    >
                      <XMarkIcon className="w-full" aria-hidden="true" />
                    </button>
                  </div>
                  <DetailsDialogTable data={detailsDialogContent} isTimeseriesArray={detailsDialogIsTimeseriesArray} />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
