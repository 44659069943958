import React from 'react';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import Card from 'components/Card';
import DataProductHealthDonutChart from './DataProductHealthDonutChart';
import DataQualityBar from './DataQualityBar';

interface IProductHealth {
  health: number;
  change: number;
  dataQuality: number;
  dataFreshness: number;
}

const data: IProductHealth = {
  health: 68,
  change: 6,
  dataQuality: 55.56,
  dataFreshness: 92.31,
};

const DataProductHealthCard = (props: { style: string }): JSX.Element => {
  return (
    <Card style={`h-46 space-x-2 ${props.style}`}>
      <>
        {data !== undefined && (
          <div className="h-full flex flex-col space-y-2">
            <div className="w-full text-base text-primary-text">Data Product Health</div>
            <div className="w-full flex-1 flex items-center">
              <div className="w-1/2 h-full">
                <div className="w-full text-3xl text-primary-text">{data.health}%</div>
                <div className="w-full text-xs text-secondary-text">
                  <span className="text-red-highlight">down {6}%</span> in the last 7 days
                </div>
              </div>
              <div className="w-1/2 h-full">
                <ParentSize>
                  {(parent) => {
                    return (
                      <DataProductHealthDonutChart
                        percentage={data.health}
                        width={parent.width}
                        height={parent.height}
                      />
                    );
                  }}
                </ParentSize>
              </div>
            </div>
            <div className="w-full flex-1 flex">
              <div className="w-1/2">
                <ParentSize>
                  {(parent) => (
                    <DataQualityBar
                      name="Quality"
                      percentage={data.dataQuality}
                      expectedPercentage={80}
                      colorScheme="yellow"
                      width={parent.width}
                      height={parent.height}
                    />
                  )}
                </ParentSize>
              </div>
              <div className="w-1/2 pl-2">
                <ParentSize>
                  {(parent) => (
                    <DataQualityBar
                      name="Freshness"
                      percentage={data.dataFreshness}
                      expectedPercentage={80}
                      colorScheme="green"
                      width={parent.width}
                      height={parent.height}
                    />
                  )}
                </ParentSize>
              </div>
            </div>
          </div>
        )}
      </>
    </Card>
  );
};

export default DataProductHealthCard;
