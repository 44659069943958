import React, { useLayoutEffect } from 'react';
import * as d3 from 'd3';
import { PieArcDatum } from 'd3';

interface ProductHealthDataType {
  percentage: number;
  color: string;
}

interface DataProductHealthDonutChartProps {
  percentage: number;
  width: number;
  height: number;
}

const DataProductHealthDonutChart = (props: DataProductHealthDonutChartProps): JSX.Element => {
  const d3Ref: React.RefObject<HTMLDivElement> = React.createRef();

  const data: ProductHealthDataType[] = [
    { percentage: 100, color: '#46be88' },
    { percentage: props.percentage, color: '#daf2e7' },
  ];

  useLayoutEffect(() => {
    const radius = Math.min(props.width / 2, props.height);

    d3.select(d3Ref.current).selectAll('*').remove();

    const svg = d3
      .select(d3Ref.current)
      .append('svg')
      .attr('width', radius * 2)
      .attr('height', radius)
      .append('g')
      .attr('transform', `translate(${radius}, ${radius})`);

    const arc = d3
      .arc<PieArcDatum<ProductHealthDataType>>()
      .innerRadius(0.8 * radius)
      .outerRadius(radius);

    const pie = d3
      .pie<ProductHealthDataType>()
      .startAngle(-0.5 * Math.PI)
      .endAngle(0.5 * Math.PI)
      .value(function (d) {
        return d.percentage;
      })
      .sort(null);

    svg
      .selectAll('path')
      .data(pie(data))
      .enter()
      .append('path')
      .attr('d', arc)
      .attr('fill', function (d: PieArcDatum<ProductHealthDataType>) {
        return d.data.color;
      });
  });

  return <div className="h-full flex-1 flex items-center justify-center" ref={d3Ref}></div>;
};

export default DataProductHealthDonutChart;
