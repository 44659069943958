import React, { useEffect, Fragment } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { NavLink } from 'react-router-dom';
import { Disclosure, Menu, Transition } from '@headlessui/react';
// import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
// import AppBarDropdown from './AppBarDrowpdown';
// import EnvironmentsDropdown from './EnvironmentsDropdown';
import { useLogoutMutation } from 'app/apis';
import { useAppDispatch } from 'app/hooks';
// import {
//   fetchProjects,
//   fetchEnvironments,
//   selectProjects,
//   selectEnvironments,
//   selectSelectedProjects,
//   selectSelectedEnvironments,
//   setSelectedProjects,
//   setSelectedEnvironments,
// } from '../reducers/appBarSlice';
import { fetchGroups } from 'features/authentication/reducers/userSlice';
import { setOpenContactUsDialog } from '../reducers/appBarSlice';
import AppSwitcher from './AppSwitcher';
import { classNames } from 'utils/styleUtils';

// const projects = [
//   { id: 1, name: 'Project 1' },
//   { id: 2, name: 'Project 2' },
//   { id: 3, name: 'Project 3' },
// ];

// const environments = [
//   { id: 1, name: 'dev' },
//   { id: 2, name: 'prod' },
//   { id: 3, name: 'qa' },
// ];

const AppBar = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const [logout] = useLogoutMutation();
  const { logout: auth0Logout, user } = useAuth0();

  // const projects = useAppSelector(selectProjects);
  // const selectedProjects = useAppSelector(selectSelectedProjects);
  // const environments = useAppSelector(selectEnvironments);
  // const selectedEnvironments = useAppSelector(selectSelectedEnvironments);

  // useEffect(() => {
  //   dispatch(fetchProjects()).catch((err) => err);
  // }, []);

  // useEffect(() => {
  //   dispatch(fetchEnvironments(selectedProjects)).catch((err) => err);
  // }, [selectedProjects]);

  useEffect(() => {
    dispatch(fetchGroups()).catch((err) => err);
  }, []);

  const signOut = (e: React.FormEvent): void => {
    auth0Logout({ logoutParams: { returnTo: window.location.origin } }).catch((e) => e);
    logout({}).catch((e) => e);
  };

  return (
    <Disclosure as="nav" className="bg-dataops-dark">
      {({ open }) => (
        <>
          <div className="w-full pl-[0.8rem] pr-2 sm:pr-4 lg:pr-8">
            <div className="w-full relative flex h-16 items-center">
              <div className="flex-grow flex items-center px-2 lg:px-0">
                <AppSwitcher />
                <div className="flex-shrink-0 pl-6">
                  <NavLink to="/finobs" className="block lg:hidden">
                    <img
                      className="block h-8 w-auto lg:hidden"
                      src="/dataops-logo-with-tagline.svg"
                      alt="DataOps.live"
                    />
                  </NavLink>
                  <NavLink to="/finobs" className="hidden lg:block">
                    <img
                      className="hidden h-8 w-auto lg:block"
                      src="/dataops-logo-with-tagline.svg"
                      alt="DataOps.live"
                    />
                  </NavLink>
                </div>
                <div className="hidden lg:ml-6 lg:block">
                  <div className="flex items-center space-x-3">
                    <div className="px-3 text-sm text-gray-100 font-semibold border-l border-r border-secondary-text">
                      Observe
                    </div>
                    <div className="px-3 text-sm text-gray-100 font-semibold border-secondary-text">
                      Spendview for Snowflake
                    </div>
                    {/* <AppBarDropdown
                      options={projects}
                      value={selectedProjects}
                      onChange={(e) => dispatch(setSelectedProjects(e))}
                      label="Projects"
                      useStaticLabel={true}
                    />
                    <EnvironmentsDropdown
                      options={environments}
                      value={selectedEnvironments}
                      onChange={(e) => dispatch(setSelectedEnvironments(e))}
                    /> */}
                  </div>
                </div>
              </div>
              <div className="flex flex-1 justify-center px-2 lg:ml-6 lg:justify-end">
                <a target="_blank" rel="noreferrer" href="https://www.dataops.live/freetrial">
                  <button
                    type="button"
                    className="rounded-md w-[10rem] bg-dataops-primary-blue hover:bg-hover-primary-blue px-2 py-1.5 text-sm font-semibold text-dataops-secondary-blue shadow-sm"
                  >
                    Try DataOps.live
                  </button>
                </a>
                <button
                  type="button"
                  onClick={() => {
                    dispatch(setOpenContactUsDialog(true));
                  }}
                  className="min-w-[10rem] ml-2 rounded-md bg-dataops-primary-blue hover:bg-hover-primary-blue px-2 py-1.5 text-sm font-semibold text-dataops-secondary-blue shadow-sm"
                >
                  Talk to Sales Team
                </button>
                {/* <div className="w-full max-w-lg lg:max-w-xs">
                  <label htmlFor="search" className="sr-only">
                    Search DataOps
                  </label>
                  <div className="relative">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </div>
                    <input
                      id="search"
                      name="search"
                      className="block w-full rounded-md border border-transparent bg-gray-700 py-2 pl-10 pr-3 leading-5 text-gray-300 placeholder-gray-400 focus:border-white focus:bg-white focus:text-gray-900 focus:outline-none focus:ring-white sm:text-sm"
                      placeholder="Search DataOps"
                      type="search"
                    />
                  </div>
                </div> */}
              </div>
              <div className="flex lg:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="hidden lg:ml-4 lg:block">
                <div className="flex items-center">
                  {/* <button
                    type="button"
                    className="flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                  >
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </button> */}

                  {/* Profile dropdown */}
                  <Menu as="div" className="relative ml-4 flex-shrink-0">
                    <div>
                      <Menu.Button className="flex rounded-full bg-gray-800 text-sm text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                        <span className="sr-only">Open user menu</span>
                        <div className="w-9 h-9 flex items-center justify-center rounded-full bg-dataops-primary-blue hover:bg-hover-primary-blue text-2xl font-medium text-gray-50">
                          {user?.name?.charAt(0).toUpperCase()}
                        </div>
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 min-w-44 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="mx-4 py-2 text-gray-700 border-b-[1px]">
                          <div className="">
                            <div className="text-sm">{user?.name}</div>
                            <div className="text-xs text-gray-600">{user?.email}</div>
                          </div>
                        </div>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              onClick={signOut}
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'w-full block px-4 py-2 text-left text-sm text-gray-700',
                              )}
                            >
                              Sign out
                            </button>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="lg:hidden">
            <div className="space-y-1 px-2 pt-2 pb-3">
              {/* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" */}
              <Disclosure.Button
                as="a"
                href="#"
                className="block rounded-md bg-gray-900 px-3 py-2 text-base font-medium text-white"
              >
                Dashboard
              </Disclosure.Button>
              <Disclosure.Button
                as="a"
                href="#"
                className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
              >
                Team
              </Disclosure.Button>
              <Disclosure.Button
                as="a"
                href="#"
                className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
              >
                Projects
              </Disclosure.Button>
            </div>
            <div className="border-t border-gray-700 pt-4 pb-3">
              <div className="flex items-center px-5">
                <div className="flex-shrink-0">
                  <div className="w-10 h-10 flex items-center justify-center rounded-full bg-cyan-600 font-medium text-2xl text-white">
                    {user?.name?.charAt(0).toUpperCase()}
                  </div>
                </div>
                <div className="ml-3">
                  <div className="text-base font-medium text-white">{user?.name}</div>
                  <div className="text-sm font-medium text-gray-400">{user?.email}</div>
                </div>
                {/* <button
                  type="button"
                  className="ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button> */}
              </div>
              <div className="mt-3 space-y-1 px-2">
                <Disclosure.Button
                  as="button"
                  onClick={signOut}
                  className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                >
                  Sign out
                </Disclosure.Button>
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default AppBar;
