import React, { useState } from 'react';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import Card from 'components/Card';
import Dropdown from 'components/Dropdown';
import DataProductAnalysisHeatmap from './DataProductAnalysisHeatmap';
import { useAppSelector } from 'app/hooks';
import { selectStartDate, selectEndDate, selectSelectedDataProducts } from '../reducers/dataProductsSlice';
import { selectSelectedProjects, selectSelectedEnvironments } from 'features/layouts/reducers/appBarSlice';
import { useGetDataProductAnalysisQuery } from 'app/dataProductsApis';
import { stringArrayFromOptionArray } from 'utils/formatUtils';
import { ITimeseriesElement } from 'utils/types';
import { costTypeOptions } from 'utils/constants';

export interface IDataProductAnalysis {
  data_product: string;
  timeseries: ITimeseriesElement[];
}

// const data = [
//   {
//     data_product: 'Data Product 1',
//     timeseries: [
//       { date: 'Mon', value: 50 },
//       { date: 'Tue', value: 55 },
//       { date: 'Wed', value: 60 },
//       { date: 'Thu', value: 55 },
//       { date: 'Fri', value: 60 },
//     ],
//   },
//   {
//     data_product: 'Data Product 2',
//     timeseries: [
//       { date: 'Mon', value: 30 },
//       { date: 'Tue', value: 25 },
//       { date: 'Wed', value: 30 },
//       { date: 'Thu', value: 45 },
//       { date: 'Fri', value: 30 },
//     ],
//   },
//   {
//     data_product: 'Data Product 3',
//     timeseries: [
//       { date: 'Mon', value: 45 },
//       { date: 'Tue', value: 50 },
//       { date: 'Wed', value: 20 },
//       { date: 'Thu', value: 32 },
//       { date: 'Fri', value: 25 },
//     ],
//   },
//   {
//     data_product: 'Data Product 4',
//     timeseries: [
//       { date: 'Mon', value: 42 },
//       { date: 'Tue', value: 36 },
//       { date: 'Wed', value: 52 },
//       { date: 'Thu', value: 46 },
//       { date: 'Fri', value: 40 },
//     ],
//   },
//   {
//     data_product: 'Data Product 5',
//     timeseries: [
//       { date: 'Mon', value: 55 },
//       { date: 'Tue', value: 56 },
//       { date: 'Wed', value: 49 },
//       { date: 'Thu', value: 38 },
//       { date: 'Fri', value: 50 },
//     ],
//   },
//   {
//     data_product: 'Data Product 6',
//     timeseries: [
//       { date: 'Mon', value: 45 },
//       { date: 'Tue', value: 46 },
//       { date: 'Wed', value: 59 },
//       { date: 'Thu', value: 50 },
//       { date: 'Fri', value: 54 },
//     ],
//   },
// ];

const DataProductAnalysisCard = (props: { style: string }): JSX.Element => {
  const [costType, setCostType] = useState(costTypeOptions[0]);
  const selectedProjects = useAppSelector(selectSelectedProjects);
  const selectedEnvironments = useAppSelector(selectSelectedEnvironments);
  const selectedDataProducts = useAppSelector(selectSelectedDataProducts);
  const startDate = useAppSelector(selectStartDate);
  const endDate = useAppSelector(selectEndDate);

  const { data } = useGetDataProductAnalysisQuery({
    startDate,
    endDate,
    projectId: selectedProjects,
    env: stringArrayFromOptionArray(selectedEnvironments),
    data_product: selectedDataProducts,
    bins: 5,
    cost_type:
      costType === 'Total cost'
        ? 'total_cost'
        : costType === 'Production cost'
        ? 'production_cost'
        : 'consumption_cost',
  });

  const handleChange = (target: string): void => {
    setCostType(target);
  };

  return (
    <Card style={`${props.style}`}>
      <div className="flex">
        <div className="flex-grow text-primary-text">Data Produduct Analysis</div>
        <Dropdown options={costTypeOptions} value={costType} onChange={handleChange} />
      </div>
      <div className="w-full h-5/6">
        {data !== undefined && data.length !== 0 && (
          <ParentSize>
            {(parent) => <DataProductAnalysisHeatmap data={data} width={parent.width} height={parent.height} />}
          </ParentSize>
        )}
      </div>
    </Card>
  );
};

export default DataProductAnalysisCard;
