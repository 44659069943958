import React, { useEffect } from 'react';
import AccountOverviewPeriodCard from 'features/finobs/components/AccountOverviewPeriodCard';
import AccountOverviewMonthlyCard from 'features/finobs/components/AccountOverviewMonthlyCard';
import TotalSpendPerAccount from 'features/finobs/components/TotalSpendPerAccount';
import TotalSpendByAccountTreemapCard from 'features/finobs/components/TotalSpendByAccountTreemapCard';
import TotalComputeAndStorageCostCard from 'features/finobs/components/TotalComputeAndStorageCostCard';
import TotalComputeAndStorageCostDonutCard from 'features/finobs/components/TotalComputeAndStorageCostDonutCard';
import UsersPerAccountCard from 'features/finobs/components/UsersPerAccountCard';
import ComputePerAccountCard from 'features/finobs/components/ComputePerAccountCard';
import StoragePerAccountCard from 'features/finobs/components/StoragePerAccountCard';
import TotalUsersOverTimeCard from 'features/finobs/components/TotalUsersOverTimeCard';
import { useAppDispatch } from 'app/hooks';
import { setShowError } from 'features/finobs/reducers/finobsSlice';

export default function FinObsAccountOverviewTab(): JSX.Element {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setShowError(false));
  });

  return (
    <div className="w-full mt-[3.2rem] mx-auto py-6 px-dashboard-side bg-dashboard-background grid grid-cols-6 auto-rows-[8rem] xl:auto-rows-[7rem] gap-4">
      <AccountOverviewPeriodCard style="col-span-3 row-span-2" />
      <AccountOverviewMonthlyCard style="col-span-3 row-span-2" />
      <TotalSpendPerAccount style="col-span-4 row-span-3" />
      <TotalSpendByAccountTreemapCard style="col-span-2 col-start-5 row-span-3" />
      <TotalComputeAndStorageCostCard isDetailsView={false} style="col-span-4 row-span-3" />
      <TotalComputeAndStorageCostDonutCard style="col-span-2 row-span-3" />
      <ComputePerAccountCard isDetailsView={false} style="col-span-3 row-span-3" />
      <StoragePerAccountCard isDetailsView={false} style="col-span-3 row-span-3" />
      <TotalUsersOverTimeCard isDetailsView={false} style="col-span-3 row-span-3" />
      <UsersPerAccountCard isDetailsView={false} style="col-span-3 col-start-4 row-span-3" />
    </div>
  );
}
