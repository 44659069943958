import React from 'react';
import axios from 'axios';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import { Auth0Provider } from '@auth0/auth0-react';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import './index.css';
import ObservabilityView from 'views/ObservabilityView';
import DataProductsView from 'views/DataProductsView';
import FinObsView from 'views/FinObsView';
import FinObsAccountOverviewTab from 'views/FinObsAccountOverviewTab';
import FinObsComputeTab from 'views/FinObsComputeTab';
import FinObsStorageCostsTab from 'views/FinObsStorageCostsTab';
import FinObsUsageCostsTab from 'views/FinObsUsageCostsTab';
import FinObsDetailsTab from 'views/FinObsDetailsTab';
import SnowflakeAccountsView from 'views/SnowflakeAccountsView';
import PipelinesView from 'views/PipelinesView';
import PipelineOverviewTab from 'views/PipelineOverviewTab';
import PipelineRunsTab from 'views/PipelineRunsTab';
import LineageView from 'views/LineageView';
import LoginView from 'views/LoginView';
import AuthCallbackView from 'views/AuthCallbackView';
import NotFoundView from 'views/NotFoundView';
import ErrorView from 'views/ErrorView';
import ProtectedRoute from 'features/authentication/components/ProtectedRoute';
import PublicRoute from 'features/authentication/components/PublicRoute';
import SnowflakeRequiredRoute from 'features/authentication/components/SnowflakeRequiredRoute';

axios.defaults.withCredentials = true;
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.xsrfCookieName = 'csrftoken';

Sentry.init({
  dsn: 'https://3955871e80044f6cab44e9daa214212d@o4505504716816384.ingest.sentry.io/4505509684903936',
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
      tracePropagationTargets: [
        'https://observability.dev.dataops.live/',
        'https://observability.qa.dataops.live/',
        'https://observability.dataops.live/',
      ],
    }),
  ],
  tracesSampleRate: 1.0,
});

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorView />,
    children: [
      {
        path: '/',
        element: (
          <ProtectedRoute>
            <ObservabilityView />
          </ProtectedRoute>
        ),
        children: [
          {
            path: '/',
            element: <Navigate replace to="/finobs" />,
          },
          {
            path: '/finobs',
            element: (
              <SnowflakeRequiredRoute>
                <FinObsView />
              </SnowflakeRequiredRoute>
            ),
            children: [
              {
                path: '/finobs',
                element: <FinObsAccountOverviewTab />,
              },
              {
                path: '/finobs/compute-costs',
                element: <FinObsComputeTab />,
              },
              {
                path: '/finobs/storage-costs',
                element: <FinObsStorageCostsTab />,
              },
              {
                path: '/finobs/usage-costs',
                element: <FinObsUsageCostsTab />,
              },
              {
                path: '/finobs/details/:component',
                element: <FinObsDetailsTab />,
              },
            ],
          },
          {
            path: '/data-products',
            element: <DataProductsView />,
          },
          {
            path: '/pipelines',
            element: <PipelinesView />,
            children: [
              {
                path: '/pipelines',
                element: <PipelineOverviewTab />,
              },
              {
                path: '/pipelines/pipeline-runs',
                element: <PipelineRunsTab />,
              },
            ],
          },
          {
            path: '/lineage',
            element: <LineageView />,
          },
          {
            path: '/snowflake-accounts',
            element: <SnowflakeAccountsView />,
          },
        ],
      },
      {
        path: '/login',
        element: (
          <PublicRoute>
            <LoginView />
          </PublicRoute>
        ),
      },
      {
        path: '/auth-callback',
        element: <AuthCallbackView />,
      },
      {
        path: '*',
        element: <NotFoundView />,
      },
    ],
  },
]);

const container = document.getElementById('root');

if (container != null) {
  const root = createRoot(container);

  root.render(
    <React.StrictMode>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
        useRefreshTokens
        cacheLocation="localstorage"
        authorizationParams={{
          redirect_uri: `${window.location.origin}/auth-callback`,
          audience: 'https://observability.dataops.live/rest',
        }}
      >
        <Provider store={store}>
          <RouterProvider router={router} />
        </Provider>
      </Auth0Provider>
    </React.StrictMode>,
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
