import React from 'react';
import { classNames } from 'utils/styleUtils';

export interface CardProps {
  style: string;
  children: JSX.Element | JSX.Element[];
  isLoading?: boolean;
  isError?: boolean;
}

const Card = (props: CardProps): JSX.Element => {
  return (
    <div
      className={classNames(
        'p-4 rounded-md shadow-sm bg-white relative',
        props.style,
        props.isLoading === true ? 'animate-[pulse_1.5s_cubic-bezier(0.4,_0,_0.6,_0.1)_infinite]' : '',
        props.isError === true ? 'border-[1px] border-red-300 opacity-75' : '',
      )}
    >
      {props.children}
    </div>
  );
};

export default Card;
