import React, { Fragment } from 'react';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { localPoint } from '@visx/event';
import { BarGroup } from '@visx/shape';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { useTooltip, useTooltipInPortal, defaultStyles } from '@visx/tooltip';
import { LegendOrdinal, LegendItem, LegendLabel } from '@visx/legend';
import { ICostPerWarehouseUnified } from './CostPerWarehouseCard';
import { formatTickLabelCost, formatTickLabelCredit, formatNumberLong, truncateString } from 'utils/formatUtils';
import colors from 'utils/colors';
import useShowDollars from '../hooks/useShowDollar';

const margin = {
  left: 45,
  right: 0,
  bottom: 50,
  top: 20,
};

const tooltipStyles = {
  ...defaultStyles,
  minWidth: 120,
  backgroundColor: '#27262c',
  color: 'white',
  fontSize: 12,
};

interface TooltipData {
  warehouse: string;
  uptimeCost?: number;
  queryValue?: number;
}

const legendGlyphSize = 12;

type IKey = 'cost' | 'queries_cost';
const keys: IKey[] = ['cost', 'queries_cost'];

interface CostPerWarehouseBarChartProps {
  data: ICostPerWarehouseUnified[];
  width: number;
  height: number;
  isDetailsView: boolean;
}

const CostPerWarehouseBarChart = (props: CostPerWarehouseBarChartProps): JSX.Element => {
  const width = props.isDetailsView ? Math.max(props.data.length * 200, props.width) : props.width;
  const showDollars = useShowDollars();
  const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } = useTooltip<TooltipData>();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });

  // scales
  const warehouseScale = scaleBand<string>({
    domain: props.data.map((d) => d.wh_name),
    padding: 0.4,
  });
  const costTypeScale = scaleBand<string>({
    domain: keys,
    padding: 0.1,
  });
  const costScale = scaleLinear<number>({
    domain: [0, Math.max(...props.data.map((d) => Math.max(d.cost, d.queries_cost)))],
    nice: true,
  });
  const colorScale = scaleOrdinal<string, string>({
    domain: keys,
    range: [colors.dataopsPurple, '#b3aef8'],
  });

  const xMax = width - margin.left - margin.right;
  const yMax = props.height - margin.top - margin.bottom;

  warehouseScale.rangeRound([0, xMax]);
  costTypeScale.rangeRound([0, warehouseScale.bandwidth()]);
  costScale.range([yMax, 0]);

  const truncateLength = Math.floor(xMax / props.data.length / 12);

  return (
    <>
      <div className="text-sm">
        <LegendOrdinal
          scale={colorScale}
          labelFormat={(label) => `${label === 'cost' ? 'Uptime Cost' : 'Query Value'}`}
        >
          {(labels) => (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {labels.map((label, i) => (
                <LegendItem key={`legend-quantile-${i}`} margin="0 5px">
                  <svg width={legendGlyphSize} height={legendGlyphSize}>
                    <rect fill={label.value} width={legendGlyphSize} height={legendGlyphSize} />
                  </svg>
                  <LegendLabel align="left" margin="0 0 0 4px">
                    {label.text}
                  </LegendLabel>
                </LegendItem>
              ))}
            </div>
          )}
        </LegendOrdinal>
      </div>
      <svg ref={containerRef} width={width} height={props.height}>
        <Group top={margin.top} left={margin.left}>
          <BarGroup
            data={props.data}
            keys={keys}
            height={yMax}
            x0={(d) => d.wh_name}
            x0Scale={warehouseScale}
            x1Scale={costTypeScale}
            yScale={costScale}
            color={colorScale}
          >
            {(barGroups) =>
              barGroups.map((barGroup) => (
                <Group key={`bar-group-${barGroup.index}-${barGroup.x0}`} left={barGroup.x0}>
                  {barGroup.bars.map((bar) => {
                    const cost = barGroup.bars[0].value;
                    const queryCost = barGroup.bars[1].value;
                    let costHighlightRatio = 0;
                    let queyCostHighlightRatio = 0;
                    if (queryCost < cost * 0.8 && cost !== 0) {
                      costHighlightRatio = (cost - queryCost) / cost;
                    } else if (queryCost > 1.5 * cost && queryCost !== 0) {
                      queyCostHighlightRatio = (queryCost - cost) / queryCost;
                    }

                    const highlightRatio = bar.key === 'cost' ? costHighlightRatio : queyCostHighlightRatio;

                    return (
                      <Fragment key={`bar-group-bar-${barGroup.index}-${bar.index}-${bar.value}-${bar.key}`}>
                        <rect
                          x={bar.x}
                          y={bar.y}
                          width={bar.width}
                          height={bar.height}
                          fill={bar.color}
                          rx={2}
                          onMouseLeave={() => {
                            hideTooltip();
                          }}
                          onMouseMove={(event) => {
                            const eventSvgCoords = localPoint(event);
                            showTooltip({
                              tooltipData: {
                                warehouse: props.data[barGroup.index].wh_name,
                                uptimeCost: props.data[barGroup.index].cost,
                                queryValue: props.data[barGroup.index].queries_cost,
                              },
                              tooltipTop: eventSvgCoords?.y,
                              tooltipLeft: eventSvgCoords?.x,
                            });
                          }}
                        />
                        <rect
                          x={bar.x}
                          y={bar.y}
                          width={bar.width}
                          height={bar.height * highlightRatio}
                          fill={colors.dataopsRed}
                          rx={2}
                          onMouseLeave={() => {
                            hideTooltip();
                          }}
                          onMouseMove={(event) => {
                            const eventSvgCoords = localPoint(event);
                            showTooltip({
                              tooltipData: {
                                warehouse: props.data[barGroup.index].wh_name,
                                uptimeCost: props.data[barGroup.index].cost,
                                queryValue: props.data[barGroup.index].queries_cost,
                              },
                              tooltipTop: eventSvgCoords?.y,
                              tooltipLeft: eventSvgCoords?.x,
                            });
                          }}
                        />
                      </Fragment>
                    );
                  })}
                </Group>
              ))
            }
          </BarGroup>
          <AxisLeft
            scale={costScale}
            stroke={colors.axisColor}
            numTicks={3}
            tickFormat={showDollars ? formatTickLabelCost : formatTickLabelCredit}
            tickStroke={colors.axisColor}
            tickLength={4}
            tickLabelProps={() => ({
              fill: colors.tickLabelColor,
              fontSize: 11,
              textAnchor: 'end',
              dy: '0.33em',
              dx: '-0.25em',
            })}
          />
          <AxisBottom
            top={yMax}
            scale={warehouseScale}
            stroke={colors.axisColor}
            numTicks={100}
            tickStroke={colors.axisColor}
            tickLabelProps={() => ({
              fill: colors.tickLabelColor,
              fontSize: 11,
              textAnchor: 'middle',
            })}
            tickComponent={({ formattedValue, ...tickProps }) => (
              <text
                {...tickProps}
                style={{ cursor: 'default' }}
                onMouseLeave={() => {
                  hideTooltip();
                }}
                onMouseMove={(event) => {
                  const eventSvgCoords = localPoint(event);
                  showTooltip({
                    tooltipData: {
                      warehouse: formattedValue as string,
                    },
                    tooltipTop: eventSvgCoords?.y,
                    tooltipLeft: eventSvgCoords?.x,
                  });
                }}
              >
                {truncateString(formattedValue as string, truncateLength)}
              </text>
            )}
            hideTicks
          />
          {tooltipOpen && tooltipData !== undefined && (
            <TooltipInPortal top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
              <div>{tooltipData.warehouse}</div>
              {tooltipData.uptimeCost !== undefined && (
                <div className="flex">
                  <div className="pr-1">Uptime Cost:</div>
                  <div>
                    {showDollars ? '$' : ''}
                    {formatNumberLong(tooltipData.uptimeCost)}
                  </div>
                </div>
              )}
              {tooltipData.queryValue !== undefined && (
                <div className="flex">
                  <div className="pr-1">Query Value:</div>
                  <div>
                    {showDollars ? '$' : ''}
                    {formatNumberLong(tooltipData.queryValue)}
                  </div>
                </div>
              )}
              {tooltipData.uptimeCost !== undefined && tooltipData.queryValue !== undefined && (
                <div className="flex">
                  <div className="pr-1">Utilization:</div>
                  <div>{`${formatNumberLong((tooltipData.queryValue / tooltipData.uptimeCost) * 100)}%`}</div>
                </div>
              )}
            </TooltipInPortal>
          )}
        </Group>
      </svg>
    </>
  );
};

export default CostPerWarehouseBarChart;
