import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import userReducer from 'features/authentication/reducers/userSlice';
import counterReducer from 'features/counter/counterSlice';
import appBarReducer from 'features/layouts/reducers/appBarSlice';
import finobsReducer from 'features/finobs/reducers/finobsSlice';
import dataProductsReducer from 'features/data-products/reducers/dataProductsSlice';
import sharedReducer from './sharedSlice';
import { observabilityApi } from './apis';
import { rtkQueryErrorLogger } from './requestErrorMiddleware';

export const store = configureStore({
  reducer: {
    user: userReducer,
    counter: counterReducer,
    appBar: appBarReducer,
    finobs: finobsReducer,
    dataProducts: dataProductsReducer,
    shared: sharedReducer,
    [observabilityApi.reducerPath]: observabilityApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(observabilityApi.middleware).concat(rtkQueryErrorLogger),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
