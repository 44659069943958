import { createApi, retry } from '@reduxjs/toolkit/query/react';
import queryString from 'query-string';
import type { BaseQueryFn } from '@reduxjs/toolkit/query';
import axios from 'axios';
import type { AxiosRequestConfig, AxiosError } from 'axios';

const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: '' },
  ): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig['method'];
      data?: AxiosRequestConfig['data'];
      params?: AxiosRequestConfig['params'];
    },
    unknown,
    unknown
  > =>
  async ({ url, method, data, params }) => {
    try {
      const result = await axios({
        url: baseUrl + url,
        method,
        data,
        params,
        paramsSerializer: {
          serialize: (params) => {
            return queryString.stringify(params);
          },
        },
      });
      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data ?? err.message,
        },
      };
    }
  };

export const observabilityApi = createApi({
  reducerPath: 'observabilityApi',
  baseQuery: retry(
    axiosBaseQuery({
      baseUrl: '/restapi/',
    }),
    {
      retryCondition: (e: any, __, { attempt }) => {
        if (e.originalStatus !== undefined && e.originalStatus >= 500 && e.originalStatus < 600 && attempt <= 3) {
          return true;
        }
        return false;
      },
    },
  ),
  keepUnusedDataFor: 600,
  tagTypes: ['FinObs', 'UniObs'],
  endpoints: (builder) => ({
    logout: builder.mutation({
      query: () => ({
        url: 'trial_users/',
        method: 'DELETE',
      }),
      invalidatesTags: ['FinObs', 'UniObs'],
    }),
  }),
});

export const { useLogoutMutation } = observabilityApi;
