import React, { useState, useMemo, useEffect } from 'react';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import Card from 'components/Card';
import CardTitle from 'components/CardTitle';
import CostTimeseriesChart from './CostTimeseriesChart';
import Dropdown from 'components/Dropdown';
import DetailsButton from './DetailsButton';
import NoRecentActivity from 'components/NoRecentActivity';
import { useAppSelector } from 'app/hooks';
import { selectSelectedAccounts, selectSelectedWarehouses, selectShowDollars } from '../reducers/finobsSlice';
import { selectStartDate, selectEndDate, selectTimeframeOption } from 'app/sharedSlice';
import { useGetCostTimeseriesQuery } from 'app/spendviewApis';
import { ITimeseries } from 'utils/types';
import { getNumberOfBins, adjustDates, getTimeStepOptions } from 'utils/dateUtils';

const CostTimeseriesCard = (props: { style: string }): JSX.Element => {
  const startDate = useAppSelector(selectStartDate);
  const endDate = useAppSelector(selectEndDate);
  const showDollars = useAppSelector(selectShowDollars);
  const timeframeOption = useAppSelector(selectTimeframeOption);
  const timeStepOptions = useMemo(() => getTimeStepOptions(startDate, endDate), [startDate, endDate]);
  const [timeStep, setTimeStep] = useState(timeframeOption === 'Last 7 days' ? 'Daily' : timeStepOptions[0]);
  const selectedAccounts = useAppSelector(selectSelectedAccounts);
  const selectedWarehouses = useAppSelector(selectSelectedWarehouses);
  const bins = useMemo(() => getNumberOfBins(startDate, endDate, timeStep), [startDate, endDate, timeStep]);
  const { adjustedStartDate, adjustedEndDate } = useMemo(
    () => adjustDates(endDate, bins, timeStep),
    [startDate, endDate, timeStep],
  );

  useEffect(() => {
    setTimeStep(timeframeOption === 'Last 7 days' ? 'Daily' : timeStepOptions[0]);
  }, [timeStepOptions, timeframeOption]);

  const { data, isFetching, isError } = useGetCostTimeseriesQuery({
    startDate: adjustedStartDate,
    endDate: adjustedEndDate,
    bins,
    warehouses: selectedWarehouses,
    aggregate_accounts: true,
    aggregate_by_accounts: selectedAccounts,
    in_dollars: showDollars,
  });

  let processedData: ITimeseries[] = [];
  if (data !== undefined) {
    processedData = [{ category: 'Cost', timeseries: data }];
  }

  return (
    <Card style={props.style} isLoading={isFetching} isError={isError}>
      <div className="flex">
        <CardTitle
          title="Total spend"
          subtitleText="Changes in accounts' total spend over time"
          tooltipText="On top right, select the granularity of the change in spend evolution"
        />
        <Dropdown options={timeStepOptions} value={timeStep} onChange={setTimeStep} />
      </div>
      <div className="w-full h-[90%]">
        {processedData.length !== 0 && (
          <ParentSize>
            {(parent) => <CostTimeseriesChart data={processedData} width={parent.width} height={parent.height} />}
          </ParentSize>
        )}
        {processedData.length === 0 && !isFetching && !isError && <NoRecentActivity />}
      </div>
      <DetailsButton data={processedData} isTimeseriesArray={true} componentName="Cost timeseries" />
    </Card>
  );
};

export default CostTimeseriesCard;
