import React, { useEffect, useState, useRef } from 'react';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import Card from 'components/Card';
import CardTitle from 'components/CardTitle';
import StorageByTypeByDBBarChart from './StorageByTypeByDBBarChart';
import DetailsButton from './DetailsButton';
import NoRecentActivity from 'components/NoRecentActivity';
import LessThanXDays from 'components/LessThanXDays';
import { useAppSelector } from 'app/hooks';
import { selectSelectedAccounts, selectSelectedWarehouses } from '../reducers/finobsSlice';
import { selectStartDate, selectEndDate } from 'app/sharedSlice';
import { useGetStorageByTypeByDBQuery } from 'app/spendviewApis';
import { classNames } from 'utils/styleUtils';
import { getNumberOfDays } from 'utils/dateUtils';

interface IStorageByTypeByDBCardProps {
  style: string;
  isDetailsView: boolean;
}
interface IStorageByTypeByDBCardProps {
  style: string;
  isDetailsView: boolean;
}
export interface IStorageByTypeByDB {
  db_name: string;
  active: number;
  time_travel: number;
  failsafe: number;
  clone: number;
}

const StorageByTypeByDBCard = (props: IStorageByTypeByDBCardProps): JSX.Element => {
  const [processedData, setProcessedData] = useState<IStorageByTypeByDB[]>([]);
  const selectedAccounts = useAppSelector(selectSelectedAccounts);
  const selectedWarehouses = useAppSelector(selectSelectedWarehouses);
  const startDate = useAppSelector(selectStartDate);
  const endDate = useAppSelector(selectEndDate);
  const numberOfDays = getNumberOfDays(startDate, endDate);
  const windowWidth = useRef(window.innerWidth);

  const { data, isFetching, isError } = useGetStorageByTypeByDBQuery(
    {
      startDate,
      endDate,
      warehouses: selectedWarehouses,
      aggregate_accounts: true,
      aggregate_by_accounts: selectedAccounts,
      in_dollars: true,
    },
    { skip: numberOfDays <= 1 },
  );

  useEffect(() => {
    if (data !== undefined) {
      const tmpProcessedData: IStorageByTypeByDB[] = [];
      data.forEach((d: IStorageByTypeByDB) => {
        tmpProcessedData.push(d);
      });
      tmpProcessedData.sort(
        (a: IStorageByTypeByDB, b: IStorageByTypeByDB) =>
          b.active + b.clone + b.time_travel + b.failsafe - (a.active + a.clone + a.time_travel + a.failsafe),
      );
      if (props.isDetailsView) {
        setProcessedData(tmpProcessedData.slice(0, 200));
      } else {
        setProcessedData(tmpProcessedData.slice(0, windowWidth.current > 1920 ? 4 : 3));
      }
    }
  }, [data]);

  return (
    <Card style={props.style} isLoading={isFetching} isError={isError}>
      <div className="flex">
        <CardTitle
          title="Storage by type by DB"
          subtitleText="Distribution of data storage by type and database"
          tooltipText="Distribution of data storage types by database across selected accounts"
        />
      </div>
      <div
        className={classNames(
          'w-full h-[75%] overflow-y-hidden',
          props.isDetailsView ? 'overflow-x-scroll' : 'overflow-x-hidden',
        )}
      >
        {numberOfDays > 1 && processedData.length !== 0 && (
          <ParentSize>
            {(parent) => (
              <StorageByTypeByDBBarChart
                data={processedData}
                width={parent.width}
                height={parent.height}
                isDetailsView={props.isDetailsView}
              />
            )}
          </ParentSize>
        )}
        {numberOfDays > 1 && processedData.length === 0 && !isFetching && !isError && <NoRecentActivity />}
        {numberOfDays <= 1 && !isFetching && !isError && <LessThanXDays days={1} />}
      </div>
      <DetailsButton data={processedData} isTimeseriesArray={false} componentName="Storage by type by DB" />
    </Card>
  );
};

export default StorageByTypeByDBCard;
