import React, { useMemo } from 'react';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { localPoint } from '@visx/event';
import { BarStack } from '@visx/shape';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { useTooltip, useTooltipInPortal, defaultStyles } from '@visx/tooltip';
import { LegendOrdinal, LegendItem, LegendLabel } from '@visx/legend';
import { ITotalComputeAndStorageCost } from './TotalComputeAndStorageCostCard';
import { formatTickLabelCost, formatTickLabelCredit, formatNumberLong } from 'utils/formatUtils';
import colors from 'utils/colors';
import { useAppSelector } from 'app/hooks';
import { selectStartDate, selectEndDate } from 'app/sharedSlice';
import { getTickLabelPrecisionHeatmap, formatTickLabelDate, getNumberOfDays } from 'utils/dateUtils';
import useShowDollars from '../hooks/useShowDollar';

const margin = {
  left: 45,
  right: 0,
  bottom: 30,
  top: 20,
};

const tooltipStyles = {
  ...defaultStyles,
  minWidth: 120,
  backgroundColor: '#27262c',
  color: 'white',
  fontSize: 12,
};

interface TooltipData {
  date: string;
  computeCost: number;
  storageCost: number;
}

const legendGlyphSize = 12;

type IKey = 'computeCost' | 'storageCost';

interface CostPerWarehouseBarChartProps {
  data: ITotalComputeAndStorageCost[];
  width: number;
  height: number;
}

const TotalComputeAndStorageCostBarChart = (props: CostPerWarehouseBarChartProps): JSX.Element => {
  const showDollars = useShowDollars();
  const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } = useTooltip<TooltipData>();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });
  const startDate = useAppSelector(selectStartDate);
  const endDate = useAppSelector(selectEndDate);
  const start = new Date(startDate);
  const end = new Date(endDate);
  const differenceInHours = (end.getTime() - start.getTime()) / (1000 * 60 * 60);
  const tickLabelPrecision = useMemo(() => getTickLabelPrecisionHeatmap(differenceInHours), [props.data]);
  const numberOfDays = useMemo(() => getNumberOfDays(startDate, endDate), [startDate, endDate]);
  const keys: IKey[] = numberOfDays >= 30 ? ['computeCost', 'storageCost'] : ['computeCost'];

  // scales
  const dateScale = scaleBand<string>({
    domain: props.data.map((d) => d.date),
    padding: 0.0,
  });
  const costScale = useMemo(
    () =>
      scaleLinear<number>({
        domain: [0, Math.max(...props.data.map((d) => d.computeCost + d.storageCost))],
        nice: true,
      }),
    [props.data],
  );
  const colorScale = scaleOrdinal<string, string>({
    domain: keys,
    range: [colors.dataopsPurple, '#b3aef8'],
  });

  const xMax = useMemo(() => props.width - margin.left - margin.right, [props]);
  const yMax = useMemo(() => props.height - margin.top - margin.bottom, [props]);

  dateScale.rangeRound([0, xMax]);
  costScale.range([yMax, 0]);

  return (
    <>
      <div className="text-sm">
        <LegendOrdinal
          scale={colorScale}
          labelFormat={(label) => `${label === 'computeCost' ? 'Compute cost' : 'Storage cost'}`}
        >
          {(labels) => (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {labels.map((label, i) => (
                <LegendItem key={`legend-quantile-${i}`} margin="0 5px">
                  <svg width={legendGlyphSize} height={legendGlyphSize}>
                    <rect fill={label.value} width={legendGlyphSize} height={legendGlyphSize} />
                  </svg>
                  <LegendLabel align="left" margin="0 0 0 4px">
                    {label.text}
                  </LegendLabel>
                </LegendItem>
              ))}
            </div>
          )}
        </LegendOrdinal>
      </div>
      <svg ref={containerRef} width={props.width} height={props.height}>
        <Group top={margin.top} left={margin.left}>
          <BarStack
            data={props.data}
            keys={keys}
            x={(d) => d.date}
            xScale={dateScale}
            yScale={costScale}
            color={colorScale}
          >
            {(barStacks) =>
              barStacks.map((barStack) =>
                barStack.bars.map((bar) => (
                  <rect
                    key={`bar-stack-${barStack.index}-${bar.index}-${bar.key}`}
                    x={bar.x}
                    y={bar.y}
                    height={bar.height}
                    width={bar.width}
                    fill={bar.color}
                    onMouseLeave={() => {
                      hideTooltip();
                    }}
                    onMouseMove={(event) => {
                      const eventSvgCoords = localPoint(event);
                      showTooltip({
                        tooltipData: {
                          date: bar.bar.data.date,
                          computeCost: bar.bar.data.computeCost,
                          storageCost: bar.bar.data.storageCost,
                        },
                        tooltipTop: eventSvgCoords?.y,
                        tooltipLeft: eventSvgCoords?.x,
                      });
                    }}
                  />
                )),
              )
            }
          </BarStack>
          <AxisLeft
            scale={costScale}
            stroke={colors.axisColor}
            numTicks={3}
            tickFormat={showDollars ? formatTickLabelCost : formatTickLabelCredit}
            tickStroke={colors.axisColor}
            tickLength={4}
            tickLabelProps={() => ({
              fill: colors.tickLabelColor,
              fontSize: 11,
              textAnchor: 'end',
              dy: '0.33em',
              dx: '-0.25em',
            })}
          />
          <AxisBottom
            top={yMax}
            scale={dateScale}
            stroke={colors.axisColor}
            tickFormat={(d) => formatTickLabelDate(d, tickLabelPrecision)}
            tickStroke={colors.axisColor}
            tickLabelProps={() => ({
              fill: colors.tickLabelColor,
              fontSize: 11,
              textAnchor: 'middle',
            })}
            hideTicks
          />
          {tooltipOpen && tooltipData !== undefined && (
            <TooltipInPortal top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
              <div className="flex">
                <div className="pr-2">Date: </div>
                <div>
                  {formatTickLabelDate(
                    tooltipData.date ?? '',
                    tickLabelPrecision === 'Monthly' || tickLabelPrecision === 'Daily' ? 'Daily' : 'Hourly',
                  )}
                </div>
              </div>
              <div className="flex">
                <div className="pr-2">{showDollars ? 'Compute cost: ' : 'Compute credits: '}</div>
                <div>
                  {showDollars ? '$' : ''}
                  {formatNumberLong(tooltipData.computeCost)}
                </div>
              </div>
              <div className="flex">
                <div className="pr-2">{showDollars ? 'Storage cost: ' : 'Storage credits: '} </div>
                <div>
                  {showDollars ? '$' : ''}
                  {formatNumberLong(tooltipData.storageCost)}
                </div>
              </div>
            </TooltipInPortal>
          )}
        </Group>
      </svg>
    </>
  );
};

export default TotalComputeAndStorageCostBarChart;
