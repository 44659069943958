import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import axios from 'axios';
import type { IRequestStatus } from 'utils/types';

interface IGroup {
  name: string;
  group_role: string;
}

export interface UserState {
  hasSnowflakeCredentials: boolean;
  groups: IGroup[];
  groupsStatus: IRequestStatus;
  isAdmin: boolean;
}

const initialState: UserState = {
  hasSnowflakeCredentials: false,
  groups: [],
  groupsStatus: 'idle',
  isAdmin: false,
};

export const fetchGroups = createAsyncThunk('groups', async () => {
  const response = await axios.get('/restapi/user_management/groups');
  return response.data;
});

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setHasSnowflakeCredentials: (state, action: PayloadAction<boolean>) => {
      state.hasSnowflakeCredentials = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGroups.pending, (state) => {
        state.groupsStatus = 'loading';
      })
      .addCase(fetchGroups.fulfilled, (state, action) => {
        state.groupsStatus = 'idle';
        state.groups = action.payload.items;
        action.payload.items.forEach((role: { name: string; group_role: string }) => {
          if (role.group_role === 'group_admin') {
            state.isAdmin = true;
          }
        });
      })
      .addCase(fetchGroups.rejected, (state) => {
        state.groupsStatus = 'failed';
      });
  },
});

export const { setHasSnowflakeCredentials } = userSlice.actions;

export const selectHasSnowflakeCredentials = (state: RootState): boolean => state.user.hasSnowflakeCredentials;
export const selectIsAdmin = (state: RootState): boolean => state.user.isAdmin;

export default userSlice.reducer;
